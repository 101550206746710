import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { generateRoute } from '../i18n/helpers'
import UploadBox from '../components/UploadBox'

const Pricing = ({ push }) => {
  const intl = useIntl()

  return (
    <div className="oval-glow">
      <Header />
      <div className="container">
        <div className="row">
          <h1 className="article__title center">
            <FormattedMessage id="use_api.upscale_product_photos" />
          </h1>
          <div className="article__content mb-5 text-center">
            <FormattedMessage id="use_api.connect_to_automated_api" />
          </div>
          <div className="mb-5 center">
            <b>Product upscaler</b> for:
            <span className="rounded-pill bg-gray-light opacity-25 px-3 text-white py-2 mx-2 semi-bold">
              Amazon
            </span>
            <span className="rounded-pill bg-gray-light opacity-25 px-3 text-white py-2 ml-2 semi-bold">
              Shopify
            </span>
            <span className="rounded-pill bg-gray-light opacity-25 px-3 text-white py-2 ml-2 semi-bold">
              WooCommerce
            </span>
            <span className="rounded-pill bg-gray-light opacity-25 px-3 text-white py-2 ml-2 semi-bold">
              PrestaShop
            </span>
          </div>
        </div>

        <div className="row">
          <div className="offset-md-2 col-md-3">
            <img
              src="/images/SmallShoes.webp"
              srcSet="/images/SmallShoes.webp 1x, /images/SmallShoes@2x.webp 2x"
              alt="Small shoes"
              className="w-100 mb-4"
            />
            <div className="mb-4">
              <span className="rounded-pill bg-gray-light opacity-50 px-3 text-white py-2 semi-bold">
                <FormattedMessage id="use_api.original" />
              </span>
            </div>
            <h2 className="mb-3 bold">
              <FormattedMessage id="use_api.benefits" />
            </h2>
            <ul className="pricing-plans__list">
              <li class="pricing-plans__list-item">
                <FormattedMessage id="use_api.benefit_1" />
              </li>
              <li class="pricing-plans__list-item">
                <FormattedMessage id="use_api.benefit_2" />
              </li>
              <li class="pricing-plans__list-item">
                <FormattedMessage id="use_api.benefit_3" />
              </li>
              <li class="pricing-plans__list-item">
                <FormattedMessage id="use_api.benefit_4" />
              </li>
              <li class="pricing-plans__list-item">
                <FormattedMessage id="use_api.benefit_5" />
              </li>
            </ul>
          </div>
          <div className="col-md-5">
            <div className="position-relative mb-4">
              <div className="position-absolute mt-3 ml-3 py-1 px-2 bg-gray-light text-white rounded-1 semi-bold opacity-50">
                2x
              </div>
              <div className="position-absolute top-50 left-50 -translate-50 text-white text-2xl">
                Upscaled <b>2x</b>
              </div>
              <img
                src="/images/BigShoes.webp"
                srcSet="/images/BigShoes.webp 1x, /images/BigShoes@2x.webp 2x"
                alt="Small shoes"
                className="w-100"
              />
            </div>

            <div className="mb-4">
              <span className="rounded-pill bg-gray-light opacity-50 px-3 text-white py-2 mr-2 semi-bold">
                <FormattedMessage id="use_api.upscaled" />
              </span>
              <span className="rounded-1 bg-green text-white p-2 mr-2 semi-bold">
                2x
              </span>
              <span className="rounded-1 bg-gray-lighter text-white p-2 mr-2 semi-bold">
                4x
              </span>
              <span className="rounded-1 bg-gray-lighter text-white p-2 semi-bold">
                8x
              </span>
            </div>
            <div
              className="gray-light"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: 'use_api.upscaler_is_wiledely_used'
                })
              }}
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <h2 className="article__title center">
              <FormattedMessage id="use_api.volume_pricing" />
            </h2>
          </div>
        </div>

        <div className="row gx-2">
          <div className="col">
            <div className="api-pricing__price-container">
              <div className="api-pricing__price-header">
                <b>5k</b> <FormattedMessage id="use_api.upscales" />
              </div>
              <div className="api-pricing__price-subheader api-pricing__price-subheader--with-margin-bottom">
                <FormattedMessage id="use_api.including_2x_4x" />
              </div>
              <div className="api-pricing__price-line" />
              <div className="api-pricing__price-header">$350</div>
              <div className="api-pricing__price-subheader">
                $0.07 <FormattedMessage id="use_api.per_upscale" />
              </div>
            </div>
          </div>
          <div className="col">
            <div className="api-pricing__price-container">
              <div className="api-pricing__price-header">
                <b>10k</b> <FormattedMessage id="use_api.upscales" />
              </div>
              <div className="api-pricing__price-subheader api-pricing__price-subheader--with-margin-bottom">
                <FormattedMessage id="use_api.including_2x_4x" />
              </div>
              <div className="api-pricing__price-line" />
              <div className="api-pricing__price-header">$600</div>
              <div className="api-pricing__price-subheader">
                $0.06 <FormattedMessage id="use_api.per_upscale" />
              </div>
            </div>
          </div>
          <div className="col">
            <div className="api-pricing__price-container">
              <div className="api-pricing__price-header">
                <b>20k</b> <FormattedMessage id="use_api.upscales" />
              </div>
              <div className="api-pricing__price-subheader api-pricing__price-subheader--with-margin-bottom">
                <FormattedMessage id="use_api.including_2x_4x" />
              </div>
              <div className="api-pricing__price-line" />
              <div className="api-pricing__price-header">$1,000</div>
              <div className="api-pricing__price-subheader">
                $0.05 <FormattedMessage id="use_api.per_upscale" />
              </div>
            </div>
          </div>
          <div className="col">
            <div className="api-pricing__price-container">
              <div className="api-pricing__price-header">
                <FormattedMessage id="use_api.custom_deal" />
              </div>
              <div className="api-pricing__price-subheader api-pricing__price-subheader--with-margin-bottom">
                <FormattedMessage id="use_api.including_2x_4x_8x" />
              </div>
              <div className="api-pricing__price-line" />
              <div className="api-pricing__price-header">
                <FormattedMessage id="use_api.from" /> $0.03
              </div>
              <div className="api-pricing__price-subheader">
                <FormattedMessage id="use_api.per_upscale" />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col text-center">
            <a
              className="button button--primary button--medium center mt-5"
              onClick={() => push(generateRoute(intl.locale, 'routes.contact'))}
            >
              <FormattedMessage id="use_api.ask_for_quote" />
            </a>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <h2 className="article__title center">
              <FormattedMessage id="use_api.try_upscaler_free" />
            </h2>
            <UploadBox />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default connect(null, { push })(Pricing)
