import React from 'react'

const Close = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    {...props}
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#FFF" transform="translate(-1159 -1160)">
        <path d="M1167.132 1167.425l7.424-7.425.707.707-7.424 7.425 7.424 7.424-.707.707-7.424-7.424-7.425 7.424-.707-.707 7.425-7.424-7.425-7.425.707-.707 7.425 7.425z"></path>
      </g>
    </g>
  </svg>
)

export default Close
