import React from 'react'

export const VismeLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
        fill="#F05E4E"
        d="M31.256 22.746h.034l5.377-2.752c-1.6-1.053-10.023-5.096-13.29-1.665-.221.221-.442.459-.646.697 3.437.51 6.449 1.834 8.524 3.72z"
      ></path>
      <path
        fill="#B23232"
        d="M28.72 20.963l4.68-2.565c-4.22-1.716-6.603-1.206-9.308.459-.17.102-.323.204-.477.322 1.89.357 3.608.969 5.105 1.784z"
      ></path>
      <path
        fill="#6CC294"
        d="M18.562 18.84c3.403-.187 6.568.441 9.171 1.63h.017s1.566-1.223 3.863-2.82c2.297-1.58 3.794-2.717 3.794-2.717-1.19-.816-4.1-1.563-5.921-1.818-5.411-.764-9.835 1.886-11.605 5.776l.68-.051z"
      ></path>
      <path
        fill="#4B9F71"
        d="M18.562 18.84c2.603-.137 5.07.186 7.248.883l2.127-6.71c-5.207-.17-9.716 2.378-11.247 5.911a.242.242 0 00-.034.102 22.65 22.65 0 011.906-.187z"
      ></path>
      <path
        fill="#F3DE3C"
        d="M18.562 18.84c2.059-.102 4.05.119 5.87.526l2.995-10.974c-2.331-.187-4.832.068-7.283.799-6.755 2.021-10.737 7.066-9.614 11.738 2.297-1.172 5.054-1.92 8.032-2.09z"
      ></path>
      <path
        fill="#CDAF31"
        d="M18.562 18.839a22.997 22.997 0 012.654.017l-3.675-8.681c-5.14 2.412-7.98 6.71-7.011 10.753 2.297-1.172 5.054-1.92 8.032-2.09z"
      ></path>
      <path
        fill="#19ABD6"
        d="M18.562 18.84c.34-.018.697-.018 1.038-.035a46.63 46.63 0 00-2.57-5.198 1032.15 1032.15 0 01-2.825-4.892c-2.569.95-5.7 2.599-7.52 4.4-4 3.974-4.34 8.816-1.447 12.417 2.28-3.67 7.334-6.37 13.324-6.693z"
      ></path>
      <path
        fill="#F8FAFB"
        d="M22.9 23.901l-3.334 2.328 1.208-3.55a4.71 4.71 0 00-1.208-.154c-2.383 0-4.305 1.682-4.305 3.738 0 2.072 1.922 3.737 4.305 3.737 2.382 0 4.305-1.682 4.305-3.738 0-.883-.375-1.715-.97-2.36z"
      ></path>
    </svg>
  )
}
