import React from 'react'

const CheckBlue = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    {...props}
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-1420 -514)">
        <g transform="translate(1420 514)">
          <g>
            <rect
              width="22"
              height="22"
              x="0"
              y="0"
              fill="#00A8FF"
              rx="3"
            ></rect>
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M18.5 6.41L8.5 16.41 3 10.91 4.41 9.5 8.5 13.58 17.09 5z"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default CheckBlue
