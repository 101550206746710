import React from 'react'

const Redo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="18"
    viewBox="0 0 21 18"
    {...props}
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#00A8FF" fillRule="nonzero" transform="translate(-888 -684)">
        <g transform="translate(888 683)">
          <g transform="translate(0 1)">
            <path d="M15.357 15.371a9 9 0 112.192-3.549l-.867-.582a8 8 0 10-2.025 3.417l.7.714z"></path>
            <path d="M14.3535534 8.64644661L13.6464466 9.35355339 17 12.7071068 20.3535534 9.35355339 19.6464466 8.64644661 17 11.2928932z"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Redo
