import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { FormattedMessage, injectIntl, useIntl } from 'react-intl'
import cx from 'classnames'
import axios from 'axios'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Message from '../components/Message'
import Checkbox from '../components/Checkbox'
import Ok from '../components/icons/Ok'
import Stripe from '../components/icons/Stripe'
import { generateRoute } from '../i18n/helpers'
import { validateEmail } from '../helpers'
import { sendMessage } from '../actions/auth'
import { LanguageName } from '../consts/languages'

const Contact = ({ pushUrl, sendMessageCb }) => {
  const [message, setMessage] = useState('')
  const [email, setEmail] = useState('')
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [page, setPage] = useState(null)
  const intl = useIntl()

  useEffect(() => {
    const fetchData = async () => {
      axios
        .get(`/api/v1/pages/impressum?locale=${intl.locale}`)
        .then((response) => {
          setPage(response.data)
        })
    }

    fetchData()
  }, [intl.locale])

  return (
    <div className="oval-glow">
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <Message
              center
              text={<FormattedMessage id="contact.contact_us_we_listen" />}
            />
            <div className="file-report">
              <Stripe className="file-report__stripe" />
              {!formSubmitted && (
                <div>
                  <div className="file-report__input-container">
                    {/* eslint-disable-next-line */}
                    <label
                      className="file-report__input-label"
                      htmlFor="content"
                    >
                      <FormattedMessage id="contact.message" />
                    </label>
                    <textarea
                      className="file-report__input"
                      value={message}
                      onChange={(event) => setMessage(event.target.value)}
                      placeholder={intl.formatMessage({
                        id: 'contact.i_would_like_to'
                      })}
                      id="content"
                    />
                  </div>

                  <div className="file-report__input-container">
                    {/* eslint-disable-next-line */}
                    <label className="file-report__input-label" htmlFor="email">
                      <FormattedMessage id="contact.email" />
                    </label>
                    <input
                      className="file-report__input"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                      placeholder={intl.formatMessage({
                        id: 'contact.enter_your_email'
                      })}
                      id="email"
                    />
                  </div>

                  <div className="file-report__terms">
                    <Checkbox
                      name="privacy-accepted"
                      checked={privacyPolicyAccepted}
                      onChange={(event) =>
                        setPrivacyPolicyAccepted(event.target.checked)
                      }
                      text={
                        <span
                          className="regular"
                          dangerouslySetInnerHTML={{
                            __html: intl.formatMessage({
                              id: 'report.agree_with_privacy'
                            })
                          }}
                        />
                      }
                    />
                  </div>
                  <div className="file-report__terms">
                    <Checkbox
                      name="terms-accepted"
                      checked={termsAccepted}
                      onChange={(event) =>
                        setTermsAccepted(event.target.checked)
                      }
                      text={
                        <span
                          className="regular"
                          dangerouslySetInnerHTML={{
                            __html: intl.formatMessage({
                              id: 'report.agree_with_terms'
                            })
                          }}
                        />
                      }
                    />
                  </div>

                  <div className="file-report__get-help">
                    <div
                      className={cx({
                        button: true,
                        'button--primary': true,
                        'button--large': true,
                        'button--disabled':
                          !message ||
                          !termsAccepted ||
                          !privacyPolicyAccepted ||
                          !validateEmail(email)
                      })}
                      onClick={() => {
                        if (
                          message &&
                          termsAccepted &&
                          privacyPolicyAccepted &&
                          validateEmail(email)
                        ) {
                          const languages = LanguageName
                          sendMessageCb(
                            message,
                            email,
                            termsAccepted,
                            languages[intl.locale],
                            () => setFormSubmitted(true)
                          )
                        }
                      }}
                      role="presentation"
                    >
                      <FormattedMessage id="contact.send_message" />
                    </div>
                  </div>

                  <div
                    className="file-report__upscale-another m-b-30"
                    onClick={() =>
                      pushUrl(generateRoute(intl.locale, 'routes.home'))
                    }
                    role="presentation"
                  >
                    <FormattedMessage id="contact.cancel" />
                  </div>
                </div>
              )}
              {formSubmitted && (
                <div>
                  <Ok className="file-report__ok" />
                  <div className="file-report__thank-you">
                    <FormattedMessage id="report.thank_you_for_support" />
                  </div>
                  <div className="file-report__let-us-get-back-to-you">
                    <FormattedMessage id="report.let_us_get_back_to_you" />
                  </div>
                  <div className="file-report__get-help">
                    <div
                      className="button button--primary button--large"
                      onClick={() =>
                        pushUrl(generateRoute(intl.locale, 'routes.home'))
                      }
                      role="presentation"
                    >
                      <FormattedMessage id="report.convert_another" />
                    </div>
                  </div>
                </div>
              )}
            </div>

            {page && (
              <div
                className="article__content"
                dangerouslySetInnerHTML={{
                  __html: page.content
                }}
              />
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  pushUrl: (args) => {
    dispatch(push(args))
  },
  sendMessageCb: (message, email, termsAccepted, language, callback) => {
    dispatch(sendMessage(message, email, termsAccepted, language, callback))
  }
})

Contact.propTypes = {
  location: PropTypes.shape({}).isRequired,
  pushUrl: PropTypes.func.isRequired,
  sendMessageCb: PropTypes.func.isRequired
}

export default connect(null, mapDispatchToProps)(injectIntl(Contact))
