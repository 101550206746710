import React, { useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { AppLanguage, LanguageName } from '../../consts/languages'
import { getMatchingRoute } from '../../i18n/helpers'
import Globe from '../../components/icons/Globe'

const LanguageSwitcher = withRouter((props) => {
  const { path, params } = props.match
  const { locale, messages } = useIntl()
  const [open, setOpen] = useState(false)

  return (
    <div
      className="language-switcher language-switcher--desktop"
      onClick={() => setOpen(!open)}
      role="presentation"
    >
      <Globe className="language-switcher__globe" />
      {LanguageName[locale]}
      <div className="language-switcher__arrow-down">
        <i className="left" />
        <i className="right" />
      </div>
      {open && (
        <div className="language-switcher__dropdown-container">
          {Object.keys(AppLanguage).map((lang) => (
            <Link
              to={getMatchingRoute(
                messages,
                path,
                params,
                locale,
                AppLanguage[lang]
              )}
              key={lang}
            >
              <div className="language-switcher__dropdown-option">
                {LanguageName[AppLanguage[lang]]}
              </div>
            </Link>
          ))}
        </div>
      )}
    </div>
  )
})

export default LanguageSwitcher
