import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { FormattedMessage, useIntl } from 'react-intl'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Message from '../components/Message'
import WarningSign from '../components/icons/WarningSign'
import Buoy from '../components/icons/Buoy'
import { generateRoute } from '../i18n/helpers'

const UploadFailed = ({ errors, isPaidUser, pushUrl, userAuthenticated }) => {
  const intl = useIntl()
  const limitReached = errors.includes('limit_exhausted')

  return (
    <div className="alert-glow">
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            {errors.map((error) => (
              <Message
                failure
                center
                text={
                  <FormattedMessage
                    id={`upload_failed.${error}_short`}
                    values={{ sizeInMB: isPaidUser ? 20 : 4, sizeInPx: 8000 }}
                  />
                }
                icon={<WarningSign />}
                key={`${error}-short-message`}
              />
            ))}

            <div className="file-report">
              <div>
                {errors.map((error) => (
                  <div
                    className="file-report__let-us-help-you"
                    key={`${error}-heading`}
                  >
                    <Buoy />
                    <FormattedMessage id={`upload_failed.${error}_heading`} />
                  </div>
                ))}

                {errors.map((error) => (
                  <div
                    className="file-report__wrong-format"
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: `upload_failed.${error}_long`
                      })
                    }}
                    key={`${error}-long-message`}
                  />
                ))}

                {!limitReached && (
                  <div className="file-report__get-help">
                    <div
                      className="button button--primary button--large"
                      onClick={() =>
                        pushUrl(generateRoute(intl.locale, 'routes.home'))
                      }
                      role="presentation"
                    >
                      <FormattedMessage id="upload_failed.upscale_another" />
                    </div>
                  </div>
                )}

                {limitReached && (
                  <div className="file-report__get-help">
                    <div
                      className="button button--primary button--large"
                      onClick={() =>
                        pushUrl(generateRoute(intl.locale, 'routes.pricing'))
                      }
                      role="presentation"
                    >
                      <FormattedMessage id="upload_failed.buy_upscales" />
                    </div>
                  </div>
                )}

                {!limitReached && (
                  <div
                    className="file-report__upscale-another"
                    onClick={() => {
                      pushUrl(generateRoute(intl.locale, 'routes.contact'))
                    }}
                    role="presentation"
                  >
                    <FormattedMessage id="upload_failed.get_help" />
                  </div>
                )}

                {limitReached && !userAuthenticated && (
                  <div
                    className="file-report__upscale-another"
                    onClick={() =>
                      pushUrl(generateRoute(intl.locale, 'routes.signup'))
                    }
                    role="presentation"
                  >
                    <FormattedMessage id="upload_failed.or_signup" />
                  </div>
                )}
                <img
                  src="/images/help-boy.png"
                  srcSet="/images/help-boy.png 1x, /images/help-boy-2x.png 2x"
                  className="file-report__help-boy"
                  alt="Help Boy"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

const mapStateToProps = (state) => ({
  errors: state.upload.errors.slice(0, 1),
  isPaidUser: ['standard', 'premium'].includes(state.user.userStatus),
  userAuthenticated: state.user.userAuthenticated
})

UploadFailed.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  isPaidUser: PropTypes.bool.isRequired,
  pushUrl: PropTypes.func.isRequired
}

export default connect(mapStateToProps, { pushUrl: push })(UploadFailed)
