import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { FormattedMessage, useIntl } from 'react-intl'
import cx from 'classnames'
import mime from 'mime-types'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Checkbox from '../components/Checkbox'
import BackBlue from '../components/icons/BackBlue'
import RadioButton from '../components/RadioButton'
import { fetchUpscaleRequest } from '../actions/upload'
import { scheduleDownload, checkProcessingStatus } from '../actions/download'
import { generateRoute } from '../i18n/helpers'
import { UpscaleRequestType } from '../proptypes'
import Placement from '../components/Placement'

const Export = ({
  isDownloading,
  leftBoxPlacement,
  pushUrl,
  rightBoxPlacement,
  scheduleDownloadCb,
  upscaleRequest,
  shouldDisplayAd,
  userLoaded,
  userStatus
}) => {
  const [include, setInclude] = useState(true)
  const [facebook, setFacebook] = useState(true)
  const [linkedin, setLinkedin] = useState(false)
  const [insta, setInsta] = useState(false)
  const [twitter, setTwitter] = useState(false)
  const [compression, setCompression] = useState(
    (upscaleRequest.scale || 2).toString()
  )
  const [vertical, setVertical] = useState('center')
  const [horizontal, setHorizontal] = useState('middle')
  const intl = useIntl()
  const isPaidUser = userLoaded && ['standard', 'premium'].includes(userStatus)
  const originalImageMeta = upscaleRequest.original_image_meta
  const extraLargeScaleAvailable =
    originalImageMeta.width <= 1000 && originalImageMeta.height <= 1000
  const format =
    upscaleRequest.request_type === 'free'
      ? 'JPEG'
      : mime.extension(upscaleRequest.content_type).toUpperCase()

  const compressionOptions = [
    {
      id: 1,
      text: (
        <>
          <span className="rectangle" />
          <span className="green green-frame bold m-r-5">2x</span>
          <FormattedMessage
            id="export.size_and_compression"
            values={{
              size: `${upscaleRequest.original_image_meta.width * 2}x${
                upscaleRequest.original_image_meta.height * 2
              }`,
              format
            }}
          />
        </>
      ),
      disabled: false,
      value: '2'
    },
    {
      id: 2,
      text: (
        <>
          <span className="rectangle rectangle--medium" />
          <span className="green green-frame bold m-r-5">4x</span>
          <FormattedMessage
            id="export.size_and_compression"
            values={{
              size: `${upscaleRequest.original_image_meta.width * 4}x${
                upscaleRequest.original_image_meta.height * 4
              }`,
              format
            }}
          />
        </>
      ),
      disabled: !isPaidUser,
      value: '4'
    },
    {
      id: 3,
      text: (
        <>
          <span className="rectangle rectangle--large" />
          <span className="green green-frame bold m-r-5">8x</span>
          <FormattedMessage
            id="export.size_and_compression"
            values={{
              size: `${upscaleRequest.original_image_meta.width * 8}x${
                upscaleRequest.original_image_meta.height * 8
              }`,
              format
            }}
          />
        </>
      ),
      disabled: !isPaidUser || !extraLargeScaleAvailable,
      value: '8'
    }
  ]

  const verticalOptions = [
    {
      id: 4,
      text: (
        <>
          <div className="rectangle">
            <div className="rectangle__frame rectangle__frame--top" />
          </div>
          <div className="rectangle">
            <div className="rectangle__frame rectangle__frame--small-top" />
          </div>
        </>
      ),
      value: 'top'
    },
    {
      id: 5,
      text: (
        <>
          <div className="rectangle">
            <div className="rectangle__frame rectangle__frame--center" />
          </div>
          <div className="rectangle">
            <div className="rectangle__frame rectangle__frame--small-center" />
          </div>
        </>
      ),
      value: 'center'
    },
    {
      id: 6,
      text: (
        <>
          <div className="rectangle">
            <div className="rectangle__frame rectangle__frame--bottom" />
          </div>
          <div className="rectangle">
            <div className="rectangle__frame rectangle__frame--small-bottom" />
          </div>
        </>
      ),
      value: 'bottom'
    }
  ]

  const horizontalOptions = [
    {
      id: 7,
      text: (
        <>
          <div className="rectangle rectangle--with-left-line">
            <div className="rectangle__frame rectangle__frame--left" />
          </div>
        </>
      ),
      value: 'left'
    },
    {
      id: 8,
      text: (
        <>
          <div className="rectangle">
            <div className="rectangle__frame rectangle__frame--middle" />
          </div>
        </>
      ),
      value: 'middle'
    },
    {
      id: 9,
      text: (
        <>
          <div className="rectangle">
            <div className="rectangle__frame rectangle__frame--right" />
          </div>
        </>
      ),
      value: 'right'
    }
  ]

  const sizes = [
    ...(facebook ? ['facebook'] : []),
    ...(linkedin ? ['linkedin'] : []),
    ...(insta ? ['instagram'] : []),
    ...(twitter ? ['twitter'] : [])
  ]

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-2" />
        <div className="col-md-8">
          <div className="image-preview__image-improved">
            <span className="semi-bold">
              <FormattedMessage id="export.get_ready_for_social_media" />
            </span>
          </div>
          <div className="export">
            {shouldDisplayAd && leftBoxPlacement !== '' && (
              <Placement
                className="export__shutterstock banner"
                html={leftBoxPlacement}
              />
            )}
            {shouldDisplayAd && rightBoxPlacement !== '' && (
              <Placement
                className="export__istock banner"
                html={rightBoxPlacement}
              />
            )}
            <div
              className="export__back"
              onClick={() =>
                pushUrl(
                  generateRoute(intl.locale, 'routes.preview', {
                    requestId: upscaleRequest.id
                  })
                )
              }
              role="presentation"
            >
              <BackBlue />
            </div>
            <div className="export__pane export__pane--with-border">
              <div className="row">
                <div className="col-md-6">
                  <div className="export__heading">
                    <FormattedMessage id="export.size_and_original_proportions" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="export__checkbox-container export__checkbox-container--include">
                    <Checkbox
                      name="white-balance"
                      checked={include}
                      onChange={(event) => setInclude(event.target.checked)}
                      text={<FormattedMessage id="export.include" />}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <RadioButton
                    name="compression-radio"
                    options={compressionOptions}
                    selectedOption={compression}
                    onChange={(event) => {
                      setCompression(event.target.value)
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="export__pane export__pane--less-top-padding">
              <div className="row">
                <div className="col-md-12">
                  <div className="export__heading">
                    <FormattedMessage id="export.cropping_for_social_media" />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="export__checkbox-container">
                    <Checkbox
                      name="white-balance 2"
                      checked={facebook}
                      onChange={(event) => setFacebook(event.target.checked)}
                      text="Facebook"
                    />
                    <Checkbox
                      name="white-balance 3"
                      checked={linkedin}
                      onChange={(event) => setLinkedin(event.target.checked)}
                      text="Linkedin"
                      disabled={!isPaidUser}
                    />
                    <Checkbox
                      name="white-balance 4"
                      checked={insta}
                      onChange={(event) => setInsta(event.target.checked)}
                      text="Instagram"
                      disabled={!isPaidUser}
                    />
                    <Checkbox
                      name="white-balance 5"
                      checked={twitter}
                      onChange={(event) => setTwitter(event.target.checked)}
                      text="Twitter"
                      disabled={!isPaidUser}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="row">
                    {verticalOptions.map((option) => (
                      <div key={option.id} className="col-md-4">
                        <label
                          className="radio-button-vertical"
                          htmlFor={option.id}
                        >
                          {option.text}
                          <input
                            type="radio"
                            checked={option.value === vertical}
                            name="vertical-radio"
                            onChange={(event) =>
                              setVertical(event.target.value)
                            }
                            value={option.value}
                            id={option.id}
                          />
                          <span className="radio-button-vertical__checkmark" />
                        </label>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="row">
                    {horizontalOptions.map((option) => (
                      <div className="col-md-4" key={option.id}>
                        <label
                          className="radio-button-vertical radio-button-vertical--with-top-padding"
                          htmlFor={option.id}
                        >
                          {option.text}
                          <input
                            type="radio"
                            checked={option.value === horizontal}
                            name="horizontal-radio"
                            onChange={(event) =>
                              setHorizontal(event.target.value)
                            }
                            value={option.value}
                            id={option.id}
                          />
                          <span className="radio-button-vertical__checkmark" />
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div style={{ textAlign: 'center' }}>
                <div
                  className={cx({
                    button: true,
                    'button--primary': true,
                    'button--large': true,
                    'button--disabled': isDownloading
                  })}
                  onClick={() => {
                    if (!isDownloading) {
                      scheduleDownloadCb(
                        upscaleRequest,
                        sizes,
                        vertical,
                        horizontal,
                        include,
                        parseInt(compression, 10)
                      )
                    }
                  }}
                  style={{ marginTop: 15 }}
                  role="presentation"
                >
                  {!isDownloading ? (
                    <FormattedMessage id="export.download_collection" />
                  ) : (
                    <FormattedMessage id="export.generating" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Export.propTypes = {
  isDownloading: PropTypes.bool.isRequired,
  location: PropTypes.shape({}).isRequired,
  pushUrl: PropTypes.func.isRequired,
  scheduleDownloadCb: PropTypes.func.isRequired,
  upscaleRequest: UpscaleRequestType.isRequired
}

/* The class should be refactored to use hooks */
class ExportView extends React.Component {
  constructor(props) {
    super(props)

    this.state = { intervalId: null }
  }

  componentDidMount() {
    const { fetchUpscaleRequestCb, match, upscaleRequest } = this.props
    const { requestId } = match.params

    if (!upscaleRequest) {
      fetchUpscaleRequestCb(requestId)
    }
  }

  /* eslint-disable-next-line */
  UNSAFE_componentWillReceiveProps(nextProps) {
    const undoneStatuses = ['created', 'processing']
    const doneStatuses = ['success', 'failure']
    const { checkProcessingStatusCb, upscaleRequest } = nextProps
    const { intervalId } = this.state
    const status = upscaleRequest && upscaleRequest.status
    const createInterval = undoneStatuses.includes(status) && !intervalId

    if (createInterval) {
      const newIntervalId = setInterval(
        () => checkProcessingStatusCb(upscaleRequest),
        4000
      )

      this.setState({ intervalId: newIntervalId })
    } else if (doneStatuses.includes(status) || !status) {
      clearInterval(intervalId)
      this.setState({ intervalId: null })
    }
  }

  componentWillUnmount() {
    const { intervalId } = this.state

    clearInterval(intervalId)
    this.setState({ intervalId: null })
  }

  render() {
    const {
      isDownloading,
      leftBoxPlacement,
      pushUrl,
      rightBoxPlacement,
      scheduleDownloadCb,
      upscaleRequest,
      userLoaded,
      userStatus
    } = this.props
    const shouldDisplayAd = userLoaded && userStatus === 'free'

    return (
      <div className="oval-glow">
        <Header />
        {upscaleRequest && (
          <Export
            isDownloading={isDownloading}
            leftBoxPlacement={leftBoxPlacement}
            pushUrl={pushUrl}
            rightBoxPlacement={rightBoxPlacement}
            scheduleDownloadCb={scheduleDownloadCb}
            shouldDisplayAd={shouldDisplayAd}
            upscaleRequest={upscaleRequest}
            userLoaded={userLoaded}
            userStatus={userStatus}
          />
        )}
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  isDownloading: state.editor.isDownloading,
  leftBoxPlacement: state.settings.placements.leftBox,
  rightBoxPlacement: state.settings.placements.rightBox,
  upscaleRequest: state.editor.currentRequest,
  userLoaded: state.user.userLoaded,
  userStatus: state.user.userStatus
})

const mapDispatchToProps = (dispatch) => ({
  checkProcessingStatusCb: (upscaleRequest) => {
    dispatch(checkProcessingStatus(upscaleRequest))
  },
  fetchUpscaleRequestCb: (requestId) => {
    dispatch(fetchUpscaleRequest(requestId))
  },
  pushUrl: (args) => {
    dispatch(push(args))
  },
  scheduleDownloadCb: (
    upscaleRequest,
    sizes,
    verticalAlignment,
    horizontalAlignment,
    includeOriginal,
    scale
  ) => {
    dispatch(
      scheduleDownload(
        upscaleRequest,
        sizes,
        verticalAlignment,
        horizontalAlignment,
        includeOriginal,
        scale
      )
    )
  }
})

ExportView.propTypes = {
  checkProcessingStatusCb: PropTypes.func.isRequired,
  fetchUpscaleRequestCb: PropTypes.func.isRequired,
  isDownloading: PropTypes.bool.isRequired,
  location: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      requestId: PropTypes.string
    })
  }).isRequired,
  pushUrl: PropTypes.func.isRequired,
  scheduleDownloadCb: PropTypes.func.isRequired,
  upscaleRequest: UpscaleRequestType
}

ExportView.defaultProps = {
  upscaleRequest: null
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportView)
