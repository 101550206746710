import React from 'react'

const Logo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#00A8FF" fillRule="nonzero" transform="translate(-315 -60)">
        <g transform="translate(315)">
          <g transform="translate(0 53) translate(0 7)">
            <path d="M9.5 18v-3H19V3H7v9.5H4V2a2 2 0 012-2h14a2 2 0 012 2v14a2 2 0 01-2 2H9.5z"></path>
            <path d="M2 10h8a2 2 0 012 2v8a2 2 0 01-2 2H2a2 2 0 01-2-2v-8a2 2 0 012-2zm1 3v6h6v-6H3z"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Logo
