import React from 'react'

const SocialLinkedin = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    {...props}
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g stroke="currentColor" transform="translate(-984 -997)">
        <g transform="translate(935 980)">
          <g transform="translate(49 17)">
            <path d="M21.194 20.927h-3.776v-6.04c0-1.517-.546-2.552-1.913-2.552-1.043 0-1.664.698-1.937 1.373-.1.24-.124.578-.124.915v6.304H9.668s.049-10.23 0-11.288h3.776v1.598c.501-.769 1.399-1.865 3.403-1.865 2.484 0 4.347 1.614 4.347 5.082v6.473zM5.69 8.097h-.025c-1.267 0-2.086-.867-2.086-1.95 0-1.107.845-1.95 2.136-1.95 1.292 0 2.087.843 2.112 1.95 0 1.083-.82 1.95-2.137 1.95z"></path>
            <path d="M3.80171978 20.9272402L7.57764289 20.9272402 7.57764289 9.63881461 3.80171978 9.63881461z"></path>
            <path d="M23.152.5H1.847C1.1.5.5 1.082.5 1.79V23.21c0 .709.6 1.291 1.347 1.291h21.305c.748 0 1.348-.582 1.348-1.291V1.79C24.5 1.08 23.9.5 23.152.5z"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default SocialLinkedin
