import React, { useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import cx from 'classnames'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Message from '../components/Message'
import Stripe from '../components/icons/Stripe'
import { generateRoute } from '../i18n/helpers'
import { fetchUser, purchasedCredits } from '../actions/auth'

const CheckoutThankYou = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const userLoaded = useSelector((state) => state.user.userLoaded)

  useEffect(() => {
    if (userLoaded) {
      dispatch(purchasedCredits())
    }
  }, [userLoaded])

  return (
    <div className="oval-glow">
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-md-2" />
          <div className="col-md-8">
            <div className="article">
              <Message
                center
                text={<FormattedMessage id="checkout.thank_you" />}
              />

              <div className="file-report">
                <Stripe className="file-report__stripe" />
                <div>
                  <div
                    className="file-report__envelope"
                    style={{
                      marginBottom: 40
                    }}
                  >
                    <div className="icon icon--ok" />
                  </div>
                  <div
                    style={{
                      color: '#00B557',
                      fontSize: 18,
                      fontWeight: 600,
                      marginBottom: 20,
                      textAlign: 'center'
                    }}
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: 'checkout.done_thank_you'
                      })
                    }}
                  />
                  <div
                    style={{
                      color: '#25282C',
                      fontSize: 18,
                      marginBottom: 40,
                      textAlign: 'center'
                    }}
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: 'checkout.go_upscale'
                      })
                    }}
                  />

                  <div className="file-report__get-help">
                    <div
                      className={cx({
                        button: true,
                        'button--primary': true,
                        'button--large': true
                      })}
                      onClick={() => {
                        dispatch(
                          push(generateRoute(intl.locale, 'routes.home'))
                        )
                        dispatch(fetchUser())
                      }}
                      role="presentation"
                    >
                      <FormattedMessage id="checkout.upscale_now" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default CheckoutThankYou
