import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Header from '../components/Header'
import Footer from '../components/Footer'
import BulkUpload from '../components/BulkUpload'

const BulkUploadPreview = ({ match }) => {
  const { bulkUploadId } = match.params
  const [isFetching, setIsFetching] = useState(true)
  const [bulkUpload, setBulkUpload] = useState(bulkUpload)

  useEffect(() => {
    axios.get(`/api/v1/bulk_uploads/${bulkUploadId}`).then((response) => {
      setBulkUpload(response.data)
      setIsFetching(false)
    })
  }, [bulkUploadId])

  return (
    <div className="oval-glow">
      <Header />
      {!isFetching && bulkUpload && <BulkUpload bulkUpload={bulkUpload} />}
      <Footer />
    </div>
  )
}

export default BulkUploadPreview
