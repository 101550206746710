import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import axios from 'axios'
import { Route, StaticRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { CookiesProvider } from 'react-cookie'
import configureStore, { history } from './store'
import { AppRoute, AppLanguage } from './consts/languages'
import { LocalizedRouter, LocalizedSwitch, appStrings } from './i18n'
import Preview from './views/Preview'
import Export from './views/Export'
import Help from './views/Help'
import TermsOfService from './views/TermsOfService'
import PrivacyPolicy from './views/PrivacyPolicy'
import ThankYou from './views/ThankYou'
import Home from './views/Home'
import FileReport from './views/FileReport'
import UploadFailed from './views/UploadFailed'
import Process from './views/Process'
import Login from './views/Login'
import Relogin from './views/Relogin'
import Signup from './views/Signup'
import Contact from './views/Contact'
import ScrollToTop from './components/ScrollToTop'
import Pricing from './views/Pricing'
import Checkout from './views/Checkout'
import CheckoutThankYou from './views/CheckoutThankYou'
import Account from './views/Account'
import Receipts from './views/Receipts'
import Promo from './views/Promo'
import BulkUploadPreview from './views/BulkUploadPreview'
import Upgrade from './views/Upgrade'
import Api from './views/Api'

const disableSentry = true

if (typeof window !== 'undefined') {
  const csrfToken = document.querySelector('meta[name=csrf-token]').content

  if (
    !disableSentry &&
    ['staging', 'production'].includes(process.env.NODE_ENV)
  ) {
    // Sentry.init({
    //   dsn: 'https://0e4e66f208e1435f855927de4f483f4e@o1270720.ingest.sentry.io/4504373259927552'
    // })
    // axios.interceptors.response.use(
    //   (response) => response,
    //   (error) => {
    //     // handle the response error
    //     if (error.response.status === 500) {
    //       Sentry.captureException(error)
    //     }
    //     return Promise.reject(error)
    //   }
    // )
  }

  axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
}

const availableLanguages = Object.keys(AppLanguage).map(
  (key) => AppLanguage[key]
)
// const userLanguages = window.navigator.languages
// const defaultLanguage =
//   userLanguages.find((lang) => availableLanguages.includes(lang)) || 'en'
const defaultLanguage = 'en'
const baseLocale = 'en'

const App = ({
  maintenanceMode,
  path,
  placements,
  statusMessage,
  statusMessageColor
}) => (
  <CookiesProvider>
    <Provider
      store={configureStore({
        settings: {
          maintenanceMode,
          placements,
          statusMessage,
          statusMessageColor
        }
      })}
    >
      <LocalizedRouter
        RouterComponent={
          typeof window !== 'undefined' ? ConnectedRouter : StaticRouter
        }
        languages={AppLanguage}
        appStrings={appStrings}
        history={history}
        defaultLanguage={defaultLanguage}
        path={path}
      >
        <ScrollToTop />
        <LocalizedSwitch baseLocale={baseLocale}>
          <Route path={AppRoute.Preview} component={Preview} />
          <Route path={AppRoute.Export} component={Export} />
          <Route path={AppRoute.ThankYou} component={ThankYou} />
          <Route path={AppRoute.Process} component={Process} />
          <Route path={AppRoute.Report} component={FileReport} />
          <Route path={AppRoute.UploadFailed} component={UploadFailed} />
          <Route path={AppRoute.Help} component={Help} />
          <Route path={AppRoute.TermsOfService} component={TermsOfService} />
          <Route path={AppRoute.PrivacyPolicy} component={PrivacyPolicy} />
          <Route path={AppRoute.Login} component={Login} />
          <Route path={AppRoute.Relogin} component={Relogin} />
          <Route path={AppRoute.Signup} component={Signup} />
          <Route path={AppRoute.Contact} component={Contact} />
          <Route path={AppRoute.Pricing} component={Pricing} />
          <Route path={AppRoute.Upgrade} component={Upgrade} />
          <Route
            path={AppRoute.CheckoutThankYou}
            component={CheckoutThankYou}
          />
          <Route path={AppRoute.Checkout} component={Checkout} />
          <Route path={AppRoute.Account} component={Account} />
          <Route path={AppRoute.Receipts} component={Receipts} />
          <Route
            path={AppRoute.BulkUploadPreview}
            component={BulkUploadPreview}
          />
          <Route
            path={AppRoute.Promo}
            component={(params) => <Promo {...params} />}
          />
          <Route path={AppRoute.Api} component={Api} />
          <Route exact path={AppRoute.Home} component={() => <Home />} />
        </LocalizedSwitch>
      </LocalizedRouter>
    </Provider>
  </CookiesProvider>
)

App.defaultProps = {
  maintenanceMode: false
}

App.propTypes = {
  maintenanceMode: PropTypes.bool,
  path: PropTypes.string.isRequired
}

export default App
