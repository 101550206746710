import * as Sentry from '@sentry/react'
import { getRootUrl } from '../helpers'

export const pushEmailToCrisp = (email) => {
  $crisp.push(['set', 'user:email', [email]])
}

export const pushVariableToCrisp = (name, value) => {
  $crisp.push(['set', 'session:data', [[[name, value]]]])
}

const getProductName = (paddleData) => paddleData.product.name

const getPrice = (paddleData) =>
  parseFloat(paddleData.checkout.prices.customer.total)

const getAdminUpscaleUrl = (upscaleRequest) => {
  const rootUrl = getRootUrl()
  const upscalePath = '/admin/upscale_requests/'

  return `${rootUrl}${upscalePath}${upscaleRequest.id}`
}

const hideCrispChat = () => {
  try {
    $crisp.push(["do", "chat:hide"])
  } catch (error) {
    Sentry.captureException(error)
  }
}

const showCrispChat = () => {
  try {
    $crisp.push(["do", "chat:show"])
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const pushTransactionEvent = (paddleData) => {
  try {
    const eventData = [
      'product_bought',
      {
        name: getProductName(paddleData),
        price: getPrice(paddleData)
      }
    ]

    $crisp.push(['set', 'session:event', [[eventData]]])
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const pushUpscaleEvent = (upscaleRequest, status = 'success') => {
  try {
    const eventData = [
      `upscale_${status}`,
      {
        admin_url: getAdminUpscaleUrl(upscaleRequest),
        content_type: upscaleRequest.content_type,
        filename: upscaleRequest.file_name,
        image_resized: upscaleRequest.image_resized,
        model: upscaleRequest.model_type,
        nsfw: upscaleRequest.nsfw,
        request_type: upscaleRequest.request_type,
        scale: upscaleRequest.scale,
        status: upscaleRequest.status,
        status_message: '',
        url: upscaleRequest.url
      }
    ]

    $crisp.push(['set', 'session:event', [[eventData]]])
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const pushUploadFailedEvent = (reason) => {
  try {
    const eventData = ['upload_failed', { reason }]

    $crisp.push(['set', 'session:event', [[eventData]]])
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const pushAdminUserUrl = (user) => {
  const rootUrl = getRootUrl()
  const userPath = '/admin/users/'

  pushVariableToCrisp('admin-user-url', `${rootUrl}${userPath}${user.id}`)
}

export const pushAdminUpscalesUrl = (user) => {
  const rootUrl = getRootUrl()
  const upscalesPath =
    '/admin/upscale_requests?commit=Filter&order=id_desc&q%5Buser_id_equals%5D='

  pushVariableToCrisp(
    'admin-upscales-url',
    `${rootUrl}${upscalesPath}${user.id}`
  )
}

export const pushAdminFreeUserUpscalesUrl = (user) => {
  const rootUrl = getRootUrl()
  const upscalesPath =
    '/admin/upscale_requests?commit=Filter&order=id_desc&q%5Bfingerprint_contains%5D='

  pushVariableToCrisp(
    'admin-upscales-url',
    `${rootUrl}${upscalesPath}${user.fingerprint}`
  )
}

export const pushRegisteredUser = (user) => {
  pushEmailToCrisp(user.email)
  pushVariableToCrisp('user-registered', true)
  pushVariableToCrisp('user-type', user.user_type)
  pushVariableToCrisp('remaining-upscales', user.limits.remaining_upscales)
  pushVariableToCrisp('total-upscales', user.limits.upscales)
  pushAdminUserUrl(user)
  pushAdminUpscalesUrl(user)

  if (['standard', 'premium'].includes(user.user_type)) {
    showCrispChat()
  } else {
    hideCrispChat()
  }
}

export const pushFreeUser = (user) => {
  pushVariableToCrisp('user-registered', false)
  pushVariableToCrisp('user-type', 'free')
  pushVariableToCrisp('remaining-upscales', user.limits.remaining_upscales)
  pushVariableToCrisp('total-upscales', user.limits.upscales)
  pushAdminFreeUserUpscalesUrl(user)
  hideCrispChat()
}
