import React from 'react'

const Hamburger = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="13"
    viewBox="0 0 18 13"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#00A8FF" transform="translate(-272 -26)">
        <g transform="translate(272 26)">
          <path d="M0 0H18V1H0z"></path>
          <path d="M0 6H18V7H0z"></path>
          <path d="M0 12H18V13H0z"></path>
        </g>
      </g>
    </g>
  </svg>
)

export default Hamburger
