import React, { useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { AppLanguage, LanguageName } from '../../consts/languages'
import { getMatchingRoute } from '../../i18n/helpers'
import Globe from '../../components/icons/Globe'

const LanguageSwitcherMobile = withRouter((props) => {
  const { path, params } = props.match
  const { locale, messages } = useIntl()

  return (
    <div
      className="language-switcher language-switcher--mobile"
      role="presentation"
    >
      <Globe className="language-switcher__globe" />
      {Object.keys(AppLanguage).map((lang) => (
        <Link
          to={getMatchingRoute(
            messages,
            path,
            params,
            locale,
            AppLanguage[lang]
          )}
          key={lang}
          className="language-switcher__menu-item"
        >
          {LanguageName[AppLanguage[lang]]}
        </Link>
      ))}
    </div>
  )
})

export default LanguageSwitcherMobile
