import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { generateRoute } from '../i18n/helpers'

const UserMenu = ({ logOut, push }) => {
  const intl = useIntl()
  const [open, setOpen] = useState(false)
  const userAvatar = useSelector((state) => state.user.userAvatar)

  return (
    <div
      className="user-menu"
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <div className="user-menu__avatar-container">
        <img className="user-menu__user-image" src={userAvatar} />
      </div>
      <div className="user-menu__arrow-icon" />
      {open && (
        <div className="user-menu__menu-container">
          <div className="user-menu__menu-item user-menu__menu-item--right">
            <div className="user-menu__avatar-container user-menu__avatar-container--blue">
              <img className="user-menu__user-image" src={userAvatar} />
            </div>
            <div className="user-menu__arrow-icon" />
          </div>
          <div
            className="user-menu__menu-item"
            onClick={() => push(generateRoute(intl.locale, 'routes.account'))}
            role="presentation"
          >
            <FormattedMessage id="header.account_history" />
          </div>
          <div
            className="user-menu__menu-item"
            onClick={() => push(generateRoute(intl.locale, 'routes.receipts'))}
            role="presentation"
          >
            <FormattedMessage id="header.receipts" />
          </div>
          <div
            className="user-menu__menu-item"
            onClick={() => push(generateRoute(intl.locale, 'routes.contact'))}
            role="presentation"
          >
            <FormattedMessage id="header.contact_us" />
          </div>
          <div className="user-menu__menu-item" onClick={logOut}>
            <FormattedMessage id="header.logout" />
          </div>
        </div>
      )}
    </div>
  )
}

export default UserMenu
