import React from 'react'

const CartoonizeLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="20"
    viewBox="0 0 18 20"
    {...props}
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-882 -877)">
        <g transform="translate(594 853)">
          <g transform="translate(263 4)">
            <g transform="translate(25.001 20)">
              <path
                fill="#EF4723"
                d="M8.718.33c-3.374-.549-5.246-.475-6.464.694C.19 3.538.743 7.872 3.059 10.218c.648.648 1.38 1.204 2.175 1.654a8.474 8.474 0 011.088-5.513c.73-1.288 3.218-1.868 3.967-.239.275.861.281 1.786.019 2.65-.29 1.432 1.592.25 2.657-.295.445-.23-.232-7.49-4.247-8.144z"
              ></path>
              <path
                fill="#F7941D"
                d="M16.797 15.7c-2.062 1.478-9.303 3.893-11.637 4.23-2.69.388-4.37-.873-4.805-2.98-.558-2.713-.447-10.125.257-12.636a7.103 7.103 0 011.644-3.3C.14 4.919 2.913 9.8 6.426 11.895c2.2 1.31 5.972 1.884 8.947 1.901 1.951.01 2.85.882 1.424 1.905z"
              ></path>
              <path
                fill="#F7941D"
                d="M6.308.031c.445.054 1.893.216 2.404.3a22.293 22.293 0 018.153 3c3.87 2.557-3.3 5.857-4.556 6.34-1.11.428-2.215.531-2.032-.756.12-.552.189-1.114.206-1.68C10.449 4.76 10.033.87 6.308.032z"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default CartoonizeLogo
