import React from 'react'

const RightArrow = ({ className, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31"
    height="18"
    viewBox="0 0 31 18"
    className={className}
    onClick={onClick}
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#FFF" transform="translate(-64 -24)">
        <g transform="translate(64 24)">
          <path d="M0 8.515H30V9.515H0z"></path>
          <g transform="rotate(45 10.621 27.4)">
            <path d="M0 0H12V1H0z"></path>
            <path d="M11 0H12V12H11z"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default RightArrow
