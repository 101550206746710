import React from 'react'

const Picture = (props) => {
  return (
    <div className="blue-picture" {...props}>
      <div className="blue-picture__inner-frame">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="60%"
          viewBox="0 0 100 42"
        >
          <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
            <g fill="#00A8FF" transform="translate(-745 -546)">
              <g transform="translate(735 490)">
                <path
                  d="M2 51.117C8.278 49.04 16.611 48 27 48s18.722 1.04 25 3.117c7.481 2.078 15.815 3.117 25 3.117s17.519-1.039 25-3.117V148H2V51.117z"
                  transform="translate(8 8)"
                ></path>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </div>
  )
}

export default Picture
