import React from 'react'
import cx from 'classnames'
import { FormattedMessage, useIntl } from 'react-intl'
import { generateRoute } from '../i18n/helpers'
import RightArrow from './icons/RightArrow'
import LanguageSwitcherMobile from '../i18n/components/LanguageSwitcherMobile'
import { useSelector } from 'react-redux'
import Placement from './Placement'

const MobileMenu = ({
  mobileMenuPlacement,
  closeMenu,
  logOut,
  open,
  pushUrl
}) => {
  const intl = useIntl()
  const userLoaded = useSelector((state) => state.user.userLoaded)
  const userAuthenticated = useSelector((state) => state.user.userAuthenticated)
  const userStatus = useSelector((state) => state.user.userStatus)
  const shouldDisplayAd = userLoaded && userStatus === 'free'

  return (
    <div
      className={cx({
        'mobile-menu': true,
        'mobile-menu--open': open
      })}
    >
      <div className="mobile-menu__shadow" onClick={closeMenu} />
      <div className="mobile-menu__content">
        <RightArrow className="mobile-menu__close-button" onClick={closeMenu} />
        <div className="mobile-menu__pane">
          {userLoaded && !userAuthenticated && (
            <div
              className="mobile-menu__menu-item"
              onClick={() =>
                pushUrl(generateRoute(intl.locale, 'routes.login'))
              }
            >
              <FormattedMessage id="header.login" />
            </div>
          )}
          <div
            className="mobile-menu__menu-item"
            onClick={() =>
              pushUrl(generateRoute(intl.locale, 'routes.pricing'))
            }
          >
            <FormattedMessage id="header.pricing" />
          </div>
          {userLoaded && userAuthenticated && (
            <>
              <div
                className="mobile-menu__menu-item"
                onClick={() =>
                  pushUrl(generateRoute(intl.locale, 'routes.account'))
                }
                role="presentation"
              >
                <FormattedMessage id="header.account_history" />
              </div>
              <div
                className="mobile-menu__menu-item"
                onClick={() =>
                  pushUrl(generateRoute(intl.locale, 'routes.receipts'))
                }
                role="presentation"
              >
                <FormattedMessage id="header.receipts" />
              </div>
              <div className="mobile-menu__menu-item" onClick={logOut}>
                <FormattedMessage id="header.logout" />
              </div>
            </>
          )}
          <a
            className="mobile-menu__menu-item"
            href={intl.formatMessage({ id: 'header.converter_url' })}
            target="_blank"
            rel="noreferrer"
          >
            <FormattedMessage id="header.converter" />
          </a>
        </div>
        {shouldDisplayAd && mobileMenuPlacement !== '' && (
          <div className="mobile-menu__pane">
            <Placement
              className="placement-mobile-menu"
              html={mobileMenuPlacement}
            />
          </div>
        )}
        <div className="mobile-menu__pane">
          <LanguageSwitcherMobile />
        </div>
        <div className="mobile-menu__pane">
          <div
            className="mobile-menu__menu-item"
            onClick={() =>
              pushUrl(generateRoute(intl.locale, 'routes.privacy'))
            }
            role="presentation"
          >
            <FormattedMessage id="footer.privacy_policy" />
          </div>
          <div
            className="mobile-menu__menu-item"
            onClick={() => pushUrl(generateRoute(intl.locale, 'routes.terms'))}
            role="presentation"
          >
            <FormattedMessage id="footer.terms_of_use" />
          </div>
          <div
            className="mobile-menu__menu-item"
            onClick={() =>
              pushUrl(generateRoute(intl.locale, 'routes.contact'))
            }
            role="presentation"
          >
            <FormattedMessage id="footer.contact" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobileMenu
