import React from 'react'

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="9"
      viewBox="0 0 14 9"
      {...props}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g stroke="#00B557" transform="translate(-826 -874)">
          <path d="M827 878.160398L831.034049 882.194448 839.084155 874.144342"></path>
        </g>
      </g>
    </svg>
  )
}

export default Icon
