import React from 'react'
import cx from 'classnames'

const Pagination = ({ currentPage, setPage, totalPages }) => (
  <div className="pagination">
    {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNumber) => (
      <div
        className={cx({
          pagination__item: true,
          'pagination__item--active': pageNumber === currentPage
        })}
        onClick={() => setPage(pageNumber)}
        key={pageNumber}
      >
        {pageNumber}
      </div>
    ))}
  </div>
)

export default Pagination
