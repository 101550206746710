import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { FormattedMessage } from 'react-intl'
import ProcessingAutoExtended from './ProcessingAutoExtended'
import ZeroesAndOnes from './ZeroesAndOnes'
import Picture from './icons/Picture'
import LoadingSpider from './icons/LoadingSpider'
import Placement from './Placement'

const ProcessingSingle = ({
  cancelUploading,
  fileName,
  isPaidUser,
  processingFilePlacement,
  progress
}) => {
  return (
    <div
      className={cx({
        uploader__dropzone: true,
        'uploader__dropzone--is-uploading': true
      })}
    >
      <ProcessingAutoExtended cancelUploading={cancelUploading} />
      {isPaidUser && <LoadingSpider className="uploader__loading-spider" />}
      <div className="uploader__zeroes-and-ones">
        <ZeroesAndOnes />
      </div>
      <div style={{ width: '100%', padding: 30, zIndex: 8 }}>
        {isPaidUser && (
          <>
            <div className="uploader__info-text uploader__info-text--file-is-being-processed">
              <FormattedMessage
                id="process.file_is_being_processed"
                values={{ file: fileName }}
              />
            </div>
            <div
              className="uploader__floating-image"
              style={{
                width: 140,
                height: 140,
                left: `calc(25% + ${progress / 4}% - 70px)`,
                top: 'calc(50% - 70px)',
                transition: 'all 2s linear',
                transform: `scale(${1 - progress / 100})`
              }}
            >
              <Picture />
            </div>
          </>
        )}

        {!isPaidUser && processingFilePlacement !== '' && (
          <div className="uploader__shutterstock-video">
            <Placement
              className="placement-processing-file"
              html={processingFilePlacement}
            />
          </div>
        )}

        <div className="uploader__cancel-container">
          <div
            className="button button--secondary uploader__cancel-button"
            onClick={cancelUploading}
            role="presentation"
          >
            <FormattedMessage id="uploader.cancel" />
          </div>
        </div>
        <div className="uploader__progress-bar-container">
          <div
            className="uploader__progress-bar"
            style={{ width: `${progress}%` }}
          />
          <div
            className="uploader__progress"
            style={{ left: `calc(${progress}% - 20px)` }}
          >
            {`${progress}%`}
          </div>
        </div>
      </div>
    </div>
  )
}

ProcessingSingle.propTypes = {
  cancelUploading: PropTypes.func.isRequired,
  fileName: PropTypes.string.isRequired,
  isPaidUser: PropTypes.bool.isRequired,
  processingFilePlacement: PropTypes.string,
  progress: PropTypes.number.isRequired
}

export default ProcessingSingle
