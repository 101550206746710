import React from 'react'

const ConverterLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <defs>
      <path id="path-1" d="M0 0h9v9H0V0zm9 9h9v9H9V9z"></path>
    </defs>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-1345 -66)">
        <g transform="translate(1345 66)">
          <g transform="translate(1 1)">
            <mask id="mask-converter-logo" fill="#fff">
              <use xlinkHref="#path-1"></use>
            </mask>
            <rect
              width="17"
              height="17"
              x="0.5"
              y="0.5"
              stroke="#00A8FF"
              mask="url(#mask-converter-logo)"
              rx="2"
            ></rect>
          </g>
          <rect
            width="7"
            height="7"
            x="0.5"
            y="12.5"
            stroke="#00A8FF"
            rx="3.5"
          ></rect>
          <rect
            width="7"
            height="7"
            x="12.5"
            y="0.5"
            stroke="#00A8FF"
            rx="3.5"
          ></rect>
        </g>
      </g>
    </g>
  </svg>
)

export default ConverterLogo
