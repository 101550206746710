import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import axios from 'axios'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { generateRoute } from '../i18n/helpers'

const TermsOfService = ({ pushUrl }) => {
  const intl = useIntl()
  const [page, setPage] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      axios
        .get(`/api/v1/pages/terms?locale=${intl.locale}`)
        .then((response) => {
          setPage(response.data)
        })
    }

    fetchData()
  }, [intl.locale])

  return (
    <>
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-md-2" />
          <div className="col-md-8">
            <div className="article">
              <h1 className="article__title center">
                <FormattedMessage id="terms.title" />
              </h1>
              {page && (
                <div
                  className="article__content"
                  dangerouslySetInnerHTML={{
                    __html: page.content
                  }}
                />
              )}
              <div className="center">
                <div
                  className="button button--secondary button--large article__contact-us"
                  onClick={() =>
                    pushUrl(generateRoute(intl.locale, 'routes.contact'))
                  }
                  role="presentation"
                >
                  <FormattedMessage id="terms.contact_us_for_more" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  pushUrl: (args) => {
    dispatch(push(args))
  }
})

TermsOfService.propTypes = {
  location: PropTypes.shape({}).isRequired,
  pushUrl: PropTypes.func.isRequired
}

export default connect(null, mapDispatchToProps)(TermsOfService)
