import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import CheckBlue from './icons/CheckBlue'

const Checkbox = ({ name, text, checked, onChange, disabled }) => (
  <label
    className={cx({
      checkbox: true,
      'checkbox--disabled': disabled
    })}
    htmlFor={name}
  >
    {text}
    <input
      id={name}
      type="checkbox"
      checked={checked}
      onChange={onChange}
      disabled={disabled}
    />
    {checked && <CheckBlue className="checkmark" />}
    {!checked && <span className="checkmark" />}
  </label>
)

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
}

Checkbox.defaultProps = {
  disabled: false,
  text: null
}

export default Checkbox
