import React from 'react'

const Crown = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="15"
    viewBox="0 0 18 15"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g stroke="#00A8FF" transform="translate(-1348 -69)">
        <g transform="translate(315)">
          <g transform="translate(1033 69)">
            <path
              fillRule="nonzero"
              d="M2.417 11.5h13.166l1.661-9.137-4.865 4.422L9 .992 5.62 6.785.757 2.363 2.417 11.5z"
            ></path>
            <path d="M2 14.5h14"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Crown
