import React from 'react'
import { useIntl } from 'react-intl'
import Switch from './Switch'
import { useSelector } from 'react-redux'
import { generateRoute } from '../i18n/helpers'

const UploadSwitches = ({ model, push, setModel, scale, setScale }) => {
  const intl = useIntl()
  const userLoaded = useSelector((state) => state.user.userLoaded)
  const userStatus = useSelector((state) => state.user.userStatus)
  const isPaidUser = userLoaded && ['standard', 'premium'].includes(userStatus)

  return (
    <div className="uploader__switches-container">
      <Switch
        disabled={!isPaidUser}
        inputName="resolution"
        name={
          <span
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: 'image_preview.upscale_resolution'
              })
            }}
          />
        }
        onChange={(value) => {
          setScale(parseInt(value, 10))
        }}
        onDisabledClick={() => {
          push(generateRoute(intl.locale, 'routes.pricing'))
        }}
        options={[
          {
            disabled: false,
            id: 1,
            name: '2x',
            value: 2
          },
          {
            disabled: !isPaidUser,
            id: 2,
            name: '4x',
            value: 4
          },
          {
            disabled: !isPaidUser,
            id: 3,
            name: '8x',
            value: 8
          }
        ]}
        value={scale}
      />
      <Switch
        disabled={!isPaidUser}
        inputName="smoothing"
        name={
          <span
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: 'image_preview.ai_model'
              })
            }}
          />
        }
        onChange={(value) => setModel(value)}
        onDisabledClick={() => {
          push(generateRoute(intl.locale, 'routes.pricing'))
        }}
        options={[
          {
            disabled: false,
            id: 'detailpro',
            name: intl.formatMessage({ id: 'image_preview.detail_pro' }),
            value: 'detailpro'
          },
          {
            disabled: !isPaidUser,
            id: 'deblock',
            name: intl.formatMessage({ id: 'image_preview.artifact_removal' }),
            value: 'deblock'
          },
          {
            disabled: !isPaidUser,
            id: 'ultra',
            name: intl.formatMessage({ id: 'image_preview.ironed_out' }),
            value: 'ultra'
          },
          {
            disabled: !isPaidUser,
            id: 'facepro',
            name: intl.formatMessage({ id: 'image_preview.face_pro' }),
            value: 'facepro'
          }
        ]}
        value={model}
        smoothing
      />
    </div>
  )
}

export default UploadSwitches
