import React, { useState } from 'react'
import cx from 'classnames'
import { connect } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import queryString from 'query-string'
import Checkbox from '../components/Checkbox'
import { validateEmail, snakeCase } from '../helpers'
import { generateRoute } from '../i18n/helpers'
import { signUp, validateLoginCode } from '../actions/auth'

const LoginFormHorizontal = ({ errors, location, serviceName, signUpCb }) => {
  const intl = useIntl()
  const queryParams = queryString.parse(location.search)
  const [email, setEmail] = useState(queryParams.email || '')
  const [termsAndPrivacyAccepted, setTermsAndPrivacyAccepted] = useState(false)
  const formIsValid = termsAndPrivacyAccepted && validateEmail(email)

  return (
    <div className="promo-login-horizontal">
      <div style={{ maxWidth: 400 }}>
        <div className="promo-login-horizontal__input">
          {/* eslint-disable-next-line */}
          <label className="file-report__input-label" htmlFor="Email">
            E-mail
          </label>
          <input
            className={cx({
              'file-report__input': true,
              'file-report__input--errors': errors.length > 0
            })}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            placeholder={intl.formatMessage({
              id: 'report.enter_your_email'
            })}
            id="Email"
          />
          {errors.map((error) => (
            <div className="file-report__error" key={error}>
              <FormattedMessage id={`checkout.errors.${snakeCase(error)}`} />
            </div>
          ))}
        </div>
        <div className="promo-login-horizontal__checkbox">
          <Checkbox
            name="login-form-privacy-accepted"
            checked={termsAndPrivacyAccepted}
            onChange={(event) =>
              setTermsAndPrivacyAccepted(event.target.checked)
            }
            text={
              <span
                className="regular"
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'report.agree_with_privacy_and_terms'
                  })
                }}
              />
            }
          />
        </div>
      </div>

      <div>
        <div
          className={cx({
            button: true,
            'button--primary': true,
            'button--large': true,
            'button--disabled': !formIsValid
          })}
          onClick={() => {
            if (formIsValid) {
              signUpCb(
                email,
                true,
                true,
                intl.locale,
                generateRoute(intl.locale, 'routes.login'),
                `sharper-bigger-promo ${serviceName}`
              )
            }
          }}
          role="presentation"
        >
          <FormattedMessage id="report.activate_now" />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  errors: state.user.errors,
  userEmail: state.user.userEmail
})

const mapDispatchToProps = (dispatch) => ({
  signUpCb: (
    email,
    termsAccepted,
    privacyAccepted,
    locale,
    afterSignUpUrl,
    referrer
  ) => {
    dispatch(
      signUp(
        email,
        termsAccepted,
        privacyAccepted,
        locale,
        afterSignUpUrl,
        referrer
      )
    )
  },
  validateLoginCodeCb: (email, loginCode) => {
    dispatch(validateLoginCode(email, loginCode))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginFormHorizontal)
