import React from 'react'

const SocialInstagram = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    {...props}
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g stroke="currentColor" transform="translate(-1017 -997)">
        <g transform="translate(935 980)">
          <g transform="translate(82 17)">
            <path d="M7 .5A6.5 6.5 0 00.5 7v11A6.5 6.5 0 007 24.5h11a6.5 6.5 0 006.5-6.5V7A6.5 6.5 0 0018 .5H7z"></path>
            <circle cx="12.5" cy="12.5" r="6"></circle>
            <circle cx="18.5" cy="5.5" r="1"></circle>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default SocialInstagram
