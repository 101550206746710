import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import DiscountCanva from './DiscountCanva'
import Canva from './icons/Canva'

const CanvaPromo = ({ className }) => {
  const intl = useIntl()

  return (
    <div className={className}>
      <div className="banner__logo-container">
        <Canva style={{ maxHeight: 36, maxWidth: 100 }} />
      </div>
      <DiscountCanva />
      <div
        className="banner__text"
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({
            id: 'thank_you.canva_text'
          })
        }}
      />
      <div className="banner__button-container">
        <a
          className="button button--secondary button--medium"
          href="https://partner.canva.com/c/2912860/1104939/10068?subId1=sp&subId2=upscaler&subId3=thank-you-page&adcampaigngroup=Pro_DE&u=https%3A%2F%2Fwww.canva.com%2Faffiliates%2FIDENIO45"
          target="_blank"
          rel="noreferrer"
        >
          <FormattedMessage id="thank_you.canva_cta" />
        </a>
      </div>
    </div>
  )
}

CanvaPromo.propTypes = {
  className: PropTypes.string
}

CanvaPromo.defaultProps = {
  className: 'banner'
}

export default CanvaPromo
