import React, { useState } from 'react'
import cx from 'classnames'

const Dropdown = ({ defaultText, onChange, options, style = {}, value }) => {
  const [open, setOpen] = useState(false)
  const activeOption = options.find((option) => option.value === value)

  return (
    <div
      className={cx({
        dropdown: true,
        'dropdown--open': open
      })}
      onClick={() => setOpen(!open)}
      style={style}
    >
      {(activeOption && activeOption.name) || defaultText}
      <div className="dropdown__arrow-icon icon icon--arrow-icon" />
      {open && (
        <div className="dropdown__items-container">
          {options.map((option) => (
            <div
              key={`dropdown-item-${option.id}`}
              className="dropdown__item"
              onClick={() => onChange(option)}
              role="presentation"
            >
              {option.name}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default Dropdown
