import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { FormattedMessage, useIntl } from 'react-intl'
import { goBack } from 'connected-react-router'
import { useDispatch } from 'react-redux'
import Options from './ImagePreview/Options'
import Comparison from './ImagePreview/Comparison'
import ComparisonFullScreen from './ImagePreview/ComparisonFullScreen'
import SocialFacebook from './icons/SocialFacebook'
import SocialLinkedin from './icons/SocialLinkedin'
import SocialInstagram from './icons/SocialInstagram'
import BackBlue from './icons/BackBlue'
import Cogwheel from './icons/Cogwheel'
import Flag from './icons/Flag'
import Redo from './icons/Redo'
import BlinkArrow from './icons/BlinkArrow'
import { generateRoute } from '../i18n/helpers'
import { getFileType } from '../helpers'
import { UpscaleRequestType } from '../proptypes'
import Placement from './Placement'

export const downloadImage = (imageUrl, filename, callback) => {
  const link = document.createElement('a')
  link.download = filename
  link.href = imageUrl

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)

  callback()
}

const ImagePreview = ({
  mobileUpscalePlacement,
  push,
  remainingUpscales,
  scheduleEnhancing,
  shouldDisplayAd,
  startDownload,
  upscaleRequest
}) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [fullScreen, toggleFullScreenMode] = useState(false)
  const intl = useIntl()
  const dispatch = useDispatch()
  const onGoBack = () => dispatch(goBack())

  const handleUserKeyPress = (event) => {
    const { keyCode } = event

    // Esc key ccode
    if (keyCode === 27) {
      toggleFullScreenMode(false)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress)

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress)
    }
  }, [handleUserKeyPress])

  return (
    <>
      <div className="container">
        <div className="image-preview__image-improved">
          <span className="semi-bold">
            <FormattedMessage id="image_preview.image_improved" /> →
          </span>
          &nbsp;
          <span
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage(
                {
                  id: 'image_preview.upscaled_from_to'
                },
                {
                  from: `${upscaleRequest.original_image_meta.width}x${upscaleRequest.original_image_meta.height}`,
                  to: `<span class="semi-bold">${upscaleRequest.upscaled_image_meta.width}x${upscaleRequest.upscaled_image_meta.height}</span>`
                }
              )
            }}
          />
        </div>
        <div className="image-preview">
          <div className="image-preview__ai-did-the-magic">
            <FormattedMessage id="image_preview.artificial_inteligence_did_the_magic" />
          </div>
          {remainingUpscales > 0 && (
            <div
              className="file-report__upscale-another image-preview__upscale-another"
              onClick={() => push(generateRoute(intl.locale, 'routes.home'))}
              role="presentation"
            >
              <Redo />
              <FormattedMessage id="image_preview.upscale_another" />
            </div>
          )}
          {remainingUpscales === 0 && (
            <div
              className="file-report__upscale-another image-preview__upscale-another"
              onClick={() => push(generateRoute(intl.locale, 'routes.pricing'))}
              role="presentation"
            >
              <div className="icon icon--arrow-s m-r-5" />
              <FormattedMessage id="image_preview.get_more" />
            </div>
          )}
          <div
            className="image-preview__oval"
            onClick={onGoBack}
            role="presentation"
          >
            <BackBlue />
          </div>
          <div className="image-preview__image-details">
            <div className="m-b-5">
              <b className="green green-frame">{upscaleRequest.scale}x</b>{' '}
              <b className="green">
                <FormattedMessage id="image_preview.upscaled" />
              </b>
            </div>

            <div className="semi-bold m-b-5">
              {`${upscaleRequest.upscaled_image_meta.width}x${upscaleRequest.upscaled_image_meta.height} px`}
            </div>

            <div>
              {' '}
              <FormattedMessage
                id="image_preview.format"
                values={{
                  format: getFileType(
                    upscaleRequest.upscaled_image
                  ).toUpperCase()
                }}
              />{' '}
              • {upscaleRequest.file_size}
            </div>
          </div>
          <div
            className={cx({
              'image-preview__oval': true,
              'image-preview__oval--settings': true,
              'image-preview__oval--active': menuOpen
            })}
            onClick={() => setMenuOpen(!menuOpen)}
            role="presentation"
          >
            <Cogwheel />
          </div>
          {!menuOpen && (
            <div className="image-preview__good-job">
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'image_preview.good_job_upscale_more'
                  })
                }}
              />
              <div>
                <span className="four-times white-frame">4x</span>
                <span className="four-times white-frame">8x</span>
              </div>
            </div>
          )}
          {!menuOpen && <BlinkArrow className="image-preview__arrow" />}
          {menuOpen && (
            <Options
              scheduleEnhancing={(compression, scale, model) => {
                scheduleEnhancing(upscaleRequest, compression, scale, model)
              }}
              upscaleRequest={upscaleRequest}
            />
          )}

          {shouldDisplayAd && !menuOpen && (
            <div className="image-preview__buy-more-upscales">
              <div className="image-preview__buy-more-upscales__title">
                <FormattedMessage id="image_preview.buy_more_2x_4x_facepro_upscales" />
              </div>
              <div className="image-preview__buy-more-upscales__subtitle">
                <FormattedMessage id="image_preview.get_full_upscaler_power_for_the_best_price" />
              </div>
              <div>
                <div
                  className="button button--secondary button--medium"
                  onClick={() =>
                    push(generateRoute(intl.locale, 'routes.pricing'))
                  }
                >
                  <FormattedMessage id="image_preview.buy_more" />
                </div>
              </div>
            </div>
          )}

          <div className="mobile-preview">
            <img
              src={upscaleRequest.upscaled_image}
              className="mobile-preview__image"
              alt="Upscaled"
            />
            <div className="mobile-preview__label">Upscaled</div>
          </div>

          {!fullScreen && (
            <Comparison
              upscaleRequest={upscaleRequest}
              toggleFullScreenMode={toggleFullScreenMode}
            />
          )}
          {fullScreen && (
            <ComparisonFullScreen
              upscaleRequest={upscaleRequest}
              toggleFullScreenMode={toggleFullScreenMode}
            />
          )}
          <div
            className="image-preview__flag"
            onClick={() => {
              push(
                generateRoute(intl.locale, 'routes.report', {
                  requestId: upscaleRequest.id
                })
              )
            }}
            role="presentation"
          >
            <Flag />
          </div>
          <a
            className="image-preview__by-stock-photos"
            target="_blank"
            href="https://www.stockphotos.com/"
            rel="noreferrer"
          >
            by Stockphotos.com
          </a>
        </div>

        {shouldDisplayAd && (
          <div
            className="image-preview__converted-thanks-to"
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: 'image_preview.converted_thanks_to'
              })
            }}
          />
        )}

        <div className="image-preview__buttons-container">
          <div
            className="button button--primary button--large image-preview__button image-preview__button--download"
            onClick={() => {
              startDownload(upscaleRequest)
            }}
            role="presentation"
          >
            <FormattedMessage id="image_preview.download_image" />
          </div>

          <div className="image-preview__or">
            <FormattedMessage id="image_preview.or" />
          </div>

          <div
            className="button button--secondary button--large  image-preview__button image-preview__button--social-media"
            onClick={() => {
              push(
                generateRoute(intl.locale, 'routes.export', {
                  requestId: upscaleRequest.id
                })
              )
            }}
            role="presentation"
          >
            <FormattedMessage id="image_preview.export_for" />
            <SocialFacebook style={{ marginLeft: 15 }} />
            <SocialLinkedin />
            <SocialInstagram />
          </div>
        </div>

        <div className="image-preview__upscale-another-mobile">
          <div
            className="file-report__upscale-another"
            onClick={() => push(generateRoute(intl.locale, 'routes.home'))}
            role="presentation"
          >
            <Redo />
            <FormattedMessage id="image_preview.upscale_another" />
          </div>
        </div>
      </div>

      {shouldDisplayAd && mobileUpscalePlacement !== '' && (
        <div className="image-preview__shutterstock-container">
          <Placement
            className="placement-mobile-upscale"
            html={mobileUpscalePlacement}
          />
        </div>
      )}

      <div className="container">
        <div className="upscale-more-on-desktop">
          <div className="upscale-more-on-desktop__scale">4x</div>
          <div className="upscale-more-on-desktop__scale upscale-more-on-desktop__scale--8x">
            8x
          </div>
          <div className="upscale-more-on-desktop__pane">
            <div
              className="upscale-more-on-desktop__text"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: 'image_preview.upscale_more_on_desktop'
                })
              }}
            />
          </div>
          <div className="upscale-more-on-desktop__link">
            upscaler.stockphotos.com
          </div>
        </div>
      </div>
    </>
  )
}

ImagePreview.propTypes = {
  mobileUpscalePlacement: PropTypes.string,
  push: PropTypes.func.isRequired,
  scheduleEnhancing: PropTypes.func.isRequired,
  startDownload: PropTypes.func.isRequired,
  upscaleRequest: UpscaleRequestType.isRequired
}

export default ImagePreview
