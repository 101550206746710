import axios from 'axios'
import { push } from 'connected-react-router'
import { generateRoute, getLanguage } from '../i18n/helpers'
import Consts from '../consts'

const getProcessPath = (upscaleRequest) =>
  generateRoute(getLanguage(), 'routes.process', {
    requestId: upscaleRequest.id
  })

const getReportPath = (upscaleRequest) =>
  generateRoute(getLanguage(), 'routes.report', {
    requestId: upscaleRequest.id
  })

const getPreviewPath = (upscaleRequest) =>
  generateRoute(getLanguage(), 'routes.preview', {
    requestId: upscaleRequest.id
  })

export const downloadImage = (imageUrl, callback) => {
  const img = new Image()

  img.onload = () => {
    const a = document.createElement('a')
    a.href = imageUrl
    document.body.appendChild(a)

    a.click()
    document.body.removeChild(a)

    callback()
  }

  img.src = imageUrl
}

export const checkProcessingStatus = (upscaleRequest) => (dispatch) => {
  dispatch({ type: Consts.UPSCALE_REQUEST_FETCH_START })

  axios
    .get(`/api/v1/upscale_requests/${upscaleRequest.id}/status`)
    .then((response) => {
      dispatch({
        type: Consts.DOWNLOAD_CHECK_STATUS_DONE,
        upscaleRequest: response.data
      })

      if (response.data.status === 'success') {
        dispatch(push(getPreviewPath(upscaleRequest)))
      }

      if (response.data.status === 'failure') {
        dispatch(push(getReportPath(upscaleRequest)))
      }
    })
    .catch(() => {
      dispatch(push(getReportPath(upscaleRequest)))
    })
}

export const scheduleEnhancing = (
  upscaleRequest,
  compression = 95,
  scale = 2,
  model = 'deblock'
) => (dispatch) => {
  dispatch({ type: Consts.UPSCALE_REQUEST_SCHEDULE_ENHANCING_START })
  dispatch(push(getProcessPath(upscaleRequest)))

  axios
    .post(`/api/v1/upscale_requests/${upscaleRequest.id}/schedule_enhancing`, {
      upscale_request: { compression, scale, model_type: model }
    })
    .then((response) => {
      dispatch({
        type: Consts.UPSCALE_REQUEST_SCHEDULE_ENHANCING_DONE,
        request: response.data
      })
    })
    .catch(() => {
      dispatch({ type: Consts.UPSCALE_REQUEST_SCHEDULE_ENHANCING_ERROR })
      dispatch(push(getReportPath(upscaleRequest)))
    })
}

export const report = (upscaleRequest, email, callback) => (dispatch) => {
  dispatch({ type: Consts.UPSCALE_REQUEST_REPORT_START })

  axios
    .post(`/api/v1/upscale_requests/${upscaleRequest.id}/report`, {
      upscale_request: { email }
    })
    .then((response) => {
      dispatch({
        type: Consts.UPSCALE_REQUEST_REPORT_DONE,
        request: response.data
      })
      callback()
    })
    .catch(() => {
      dispatch({ type: Consts.UPSCALE_REQUEST_REPORT_ERROR })
    })
}
