import axios from 'axios'
import { push } from 'connected-react-router'
import * as Sentry from '@sentry/react'
import { generateRoute, getLanguage } from '../i18n/helpers'
import Consts from '../consts'

const scrollTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
}

export const getThankYouPath = (upscaleRequest) =>
  generateRoute(getLanguage(), 'routes.thank_you', {
    requestId: upscaleRequest.id
  })

export const downloadFile = (file, callback) => {
  const link = document.createElement('a')
  link.download = true
  link.href = file

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)

  if (typeof callback === 'function') {
    callback()
  }
}

export const startImageDownload = (upscaleRequest) => (dispatch, getState) => {
  const { userLoaded, userStatus } = getState().user
  const isPaidUser = userLoaded && ['standard', 'premium'].includes(userStatus)

  dispatch({
    type: Consts.DOWNLOAD_READY,
    downloadUrl: upscaleRequest.download_image_link
  })

  if (isPaidUser) {
    downloadFile(upscaleRequest.download_image_link, () => {
      dispatch(push(getThankYouPath(upscaleRequest)))
    })
  } else {
    dispatch(push(getThankYouPath(upscaleRequest)))
  }
}

export const startPackageDownload = (upscaleRequest) => (
  dispatch,
  getState
) => {
  const { userLoaded, userStatus } = getState().user
  const isPaidUser = userLoaded && ['standard', 'premium'].includes(userStatus)
  const downloadUrl = `/api/v1/upscale_requests/${upscaleRequest.id}.zip`

  dispatch({
    type: Consts.DOWNLOAD_READY,
    downloadUrl
  })

  if (isPaidUser) {
    downloadFile(downloadUrl, () => {
      dispatch(push(getThankYouPath(upscaleRequest)))
    })
  } else {
    dispatch(push(getThankYouPath(upscaleRequest)))
  }
}

export const scheduleDownload = (
  upscaleRequest,
  sizes,
  verticalAlignment,
  horizontalAlignment,
  includeOriginal,
  scale
) => (dispatch) => {
  dispatch({ type: Consts.DOWNLOAD_SCHEDULE_START })

  axios
    .post(`/api/v1/upscale_requests/${upscaleRequest.id}/schedule_download`, {
      download: {
        sizes,
        vertical_alignment: verticalAlignment,
        horizontal_alignment: horizontalAlignment,
        include_original: includeOriginal,
        scale
      }
    })
    .then((response) => {
      dispatch({
        type: Consts.DOWNLOAD_SCHEDULE_DONE,
        request: response.data
      })
    })
    .catch(() => {
      dispatch({ type: Consts.DOWNLOAD_SCHEDULE_ERROR })
    })
}

export const checkProcessingStatus = (upscaleRequest) => (dispatch) => {
  dispatch({ type: Consts.UPSCALE_REQUEST_FETCH_START })

  axios
    .get(`/api/v1/upscale_requests/${upscaleRequest.id}/status`)
    .then((response) => {
      dispatch({
        type: Consts.DOWNLOAD_CHECK_STATUS_DONE,
        upscaleRequest: response.data
      })

      if (response.data.status === 'success') {
        dispatch(startPackageDownload(upscaleRequest))
        scrollTop()
      }
    })
    .catch((error) => {
      Sentry.captureException(error)
      dispatch({
        type: Consts.UPSCALE_REQUEST_FETCH_ERROR,
        errors: ['could_not_fetch_request']
      })
      dispatch(push(generateRoute(getLanguage(), 'routes.home')))
    })
}
