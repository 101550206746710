import React from 'react'
import { FormattedMessage } from 'react-intl'

const ModelType = ({ modelName }) => {
  if (modelName === 'sr') {
    return <FormattedMessage id="image_preview.high_fidelity" />
  } else if (modelName === 'ultra') {
    return <FormattedMessage id="image_preview.ironed_out" />
  } else if (modelName === 'deblock') {
    return <FormattedMessage id="image_preview.artifact_removal" />
  } else if (modelName === 'detailpro') {
    return <FormattedMessage id="image_preview.detail_pro" />
  } else if (modelName === 'facepro') {
    return <FormattedMessage id="image_preview.face_pro" />
  }

  return null
}

export default ModelType
