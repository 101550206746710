import React from 'react'

const Copy = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="20"
    viewBox="0 0 17 20"
    {...props}
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-1175 -725)">
        <g transform="translate(1005 358)">
          <g transform="translate(170 367)">
            <path
              fillRule="nonzero"
              stroke="#00A8FF"
              d="M15 2.5h-4.03l-.12-.33A2.51 2.51 0 008.5.5a2.51 2.51 0 00-2.35 1.67l-.12.33H2A1.5 1.5 0 00.5 4v14A1.5 1.5 0 002 19.5h13a1.5 1.5 0 001.5-1.5V4A1.5 1.5 0 0015 2.5z"
            ></path>
            <path
              fill="#00A8FF"
              fillRule="nonzero"
              d="M8.5 2a1 1 0 110 2 1 1 0 010-2"
            ></path>
            <path fill="#00A8FF" d="M8 8H9V15H8z"></path>
            <path
              fill="#00A8FF"
              d="M8 8H9V15H8z"
              transform="rotate(90 8.5 11.5)"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Copy
