import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import Shutterstock from './icons/ShutterstockColor'
import DiscountRed from './DiscountRed'

const ShutterstockPromo = ({ className }) => {
  const intl = useIntl()

  return (
    <div className={className}>
      <div className="banner__logo-container">
        <Shutterstock />
      </div>
      <DiscountRed />
      <div
        className="banner__text"
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({
            id: 'thank_you.shutterstock_text'
          })
        }}
      />
      <div className="banner__button-container">
        <a
          className="button button--secondary button--medium"
          href="https://shutterstock.7eer.net/c/2691128/861128/1305?subId1=sp&subId2=upscaler&subId3=download-page&u=https%3A%2F%2Fwww.shutterstock.com%2Fdiscover%2F10-free-stock-images"
          target="_blank"
          rel="noreferrer"
        >
          <FormattedMessage id="thank_you.redeem_now" />
        </a>
      </div>
    </div>
  )
}

ShutterstockPromo.propTypes = {
  className: PropTypes.string
}

ShutterstockPromo.defaultProps = {
  className: 'banner'
}

export default ShutterstockPromo
