import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { FormattedMessage, useIntl } from 'react-intl'
import { withCookies } from 'react-cookie'
import Turnstile from 'react-turnstile'
import Uploader from '../components/Uploader'
import TermsDialog from '../components/TermsDialog'
import UploadDisabled from '../components/UploadDisabled'
import {
  cancelUploading,
  checkBulkUploadProcessingStatus,
  checkProcessingStatus,
  cleanupUploadState,
  startBulkUpload,
  uploadFile
} from '../actions/upload'
import { UnprocessedUpscaleRequestType } from '../proptypes'
import { generateRoute } from '../i18n/helpers'
import { TURNSTILE_SITE_KEY } from '../consts/turnstile'

const UploadBox = ({
  bulkUpload,
  bulkUploadFiles,
  cancelUploadingCb,
  checkBulkUploadProcessingStatusCb,
  checkProcessingStatusCb,
  cleanupUploadStateCb,
  cookies,
  errors,
  fileName,
  isBulkProcessing,
  isBulkUploading,
  isUploading,
  maintenanceMode,
  processingFilePlacement,
  progress,
  pushUrl,
  remainingUpscales,
  startBulkUploadCb,
  uploadFileCb,
  uploadType,
  upscaleRequest,
  userLoaded,
  userStatus
}) => {
  const intl = useIntl()
  const [files, setFiles] = useState(null)
  const [scale, setScale] = useState(2)
  const [model, setModel] = useState('detailpro')
  const [displayTermsDialog, setDisplayTermsDialog] = useState(false)
  const [turnstileKey, setTurnstileKey] = useState(Date.now())
  const [turnstileToken, setTurnstileToken] = useState('')
  const termsOfServiceCookieName = 'terms-of-service-accepted'
  const shouldDisplayTerms =
    displayTermsDialog && !cookies.cookies[termsOfServiceCookieName]
  const isPaidUser = userLoaded && ['standard', 'premium'].includes(userStatus)

  useEffect(() => {
    if (isPaidUser) {
      const urlParams = new URLSearchParams(window.location.search)
      const modelFromUrl = urlParams.get('model')
      const scaleFromUrl = parseInt(urlParams.get('scale'), 10)
      const initialScale =
        scaleFromUrl || parseInt(cookies.cookies['initialScale'], 10) || 2
      const initialModel =
        modelFromUrl || cookies.cookies['initialModel'] || 'detailpro'

      setScale(initialScale)
      setModel(initialModel)
    }
  }, [userLoaded, userStatus])

  useEffect(() => {
    let interval

    if (upscaleRequest && isUploading && uploadType === 'single') {
      interval = setInterval(() => {
        checkProcessingStatusCb(upscaleRequest)
      }, 2000)
    }

    return () => clearInterval(interval)
  }, [upscaleRequest, isUploading, uploadType])

  useEffect(() => {
    let interval

    if (isBulkProcessing) {
      interval = setInterval(() => {
        checkBulkUploadProcessingStatusCb(bulkUpload)
      }, 10000)
    }

    return () => clearInterval(interval)
  }, [isBulkProcessing])

  useEffect(() => {
    const shouldRedirectToBulkUpload =
      bulkUpload &&
      bulkUpload.status === 'success' &&
      bulkUploadFiles.length > 0

    if (shouldRedirectToBulkUpload) {
      pushUrl(
        generateRoute(intl.locale, 'routes.bulk_upload_preview', {
          bulkUploadId: bulkUpload.id
        })
      )
      cleanupUploadStateCb()
    }
  }, [bulkUpload, bulkUploadFiles])

  return (
    <>
      {errors.map((error) => (
        <div className="message message--failure message--center" key={error}>
          <FormattedMessage id={`upload_failed.${error}`} />
        </div>
      ))}
      {!maintenanceMode && !shouldDisplayTerms && (
        <Uploader
          bulkUpload={bulkUpload}
          bulkUploadFiles={bulkUploadFiles}
          cancelUploading={cancelUploadingCb}
          disabled={!turnstileToken}
          fileName={fileName}
          isPaidUser={isPaidUser}
          isBulkUploading={isBulkUploading}
          isBulkProcessing={isBulkProcessing}
          isUploading={isUploading}
          model={model}
          processingFilePlacement={processingFilePlacement}
          progress={progress}
          push={pushUrl}
          remainingUpscales={remainingUpscales}
          scale={scale}
          setScale={(s) => {
            const maxAge = 60 * 60 * 24 * 30

            cookies.set('initialScale', s, { path: '/', maxAge })
            setScale(s)
          }}
          setModel={(m) => {
            const maxAge = 60 * 60 * 24 * 30

            cookies.set('initialModel', m, { path: '/', maxAge })
            setModel(m)
          }}
          startBulkUpload={(acceptedFiles) =>
            startBulkUploadCb(acceptedFiles, model, scale)
          }
          uploadFile={(acceptedFiles) => {
            setFiles(acceptedFiles)

            if (!cookies.cookies[termsOfServiceCookieName]) {
              setDisplayTermsDialog(true)
            } else {
              uploadFileCb(
                acceptedFiles,
                true,
                true,
                scale,
                model,
                isPaidUser,
                turnstileToken
              )
              setTurnstileKey(Date.now())
            }
          }}
          uploadType={uploadType}
        />
      )}
      {!maintenanceMode && shouldDisplayTerms && (
        <TermsDialog
          acceptTerms={(terms, privacy) => {
            const maxAge = 60 * 60 * 24 * 30 // 30 days

            cookies.set(termsOfServiceCookieName, true, {
              path: '/',
              maxAge
            })
            uploadFileCb(
              files,
              terms,
              privacy,
              scale,
              model,
              isPaidUser,
              turnstileToken
            )
            setTurnstileKey(Date.now())
            setDisplayTermsDialog(false)
            setFiles(null)
          }}
        />
      )}
      {maintenanceMode && <UploadDisabled />}
      <div className="uploader__turnstile">
        <Turnstile
          key={turnstileKey}
          onVerify={(token) => {
            setTurnstileToken(token)
          }}
          sitekey={TURNSTILE_SITE_KEY}
          theme="light"
        />
      </div>
    </>
  )
}

UploadBox.defaultProps = {
  fileName: '',
  upscaleRequest: null
}

UploadBox.propTypes = {
  cancelUploadingCb: PropTypes.func.isRequired,
  checkProcessingStatusCb: PropTypes.func.isRequired,
  cookies: PropTypes.shape({
    cookies: PropTypes.shape({}).isRequired,
    set: PropTypes.func.isRequired
  }).isRequired,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  fileName: PropTypes.string,
  isUploading: PropTypes.bool.isRequired,
  maintenanceMode: PropTypes.bool.isRequired,
  processingFilePlacement: PropTypes.string,
  progress: PropTypes.number.isRequired,
  pushUrl: PropTypes.func.isRequired,
  uploadFileCb: PropTypes.func.isRequired,
  upscaleRequest: UnprocessedUpscaleRequestType
}

const mapStateToProps = (state) => ({
  bulkUpload: state.upload.bulkUpload,
  bulkUploadFiles: state.upload.bulkUploadFiles,
  errors: state.editor.errors,
  fileName: state.upload.fileName,
  isBulkProcessing: state.upload.isBulkProcessing,
  isBulkUploading: state.upload.isBulkUploading,
  isUploading: state.upload.isUploading,
  maintenanceMode: state.settings.maintenanceMode,
  processingFilePlacement: state.settings.placements.processingFile,
  progress: state.upload.progress,
  remainingUpscales: state.user.userRemainingUpscales,
  upscaleRequest: state.upload.currentFile,
  uploadType: state.upload.uploadType,
  userLoaded: state.user.userLoaded,
  userStatus: state.user.userStatus
})

const mapDispatchToProps = (dispatch) => ({
  cancelUploadingCb: () => {
    dispatch(cancelUploading())
  },
  checkBulkUploadProcessingStatusCb: (bulkUpload) => {
    dispatch(checkBulkUploadProcessingStatus(bulkUpload))
  },
  checkProcessingStatusCb: (upscaleRequest) => {
    dispatch(checkProcessingStatus(upscaleRequest))
  },
  cleanupUploadStateCb: () => {
    dispatch(cleanupUploadState())
  },
  pushUrl: (...args) => {
    dispatch(push(...args))
  },
  startBulkUploadCb: (acceptedFiles, model, scale) => {
    dispatch(startBulkUpload(acceptedFiles, model, scale))
  },
  uploadFileCb: (
    acceptedFiles,
    terms,
    privacy,
    scale,
    model,
    isPaidUser,
    turnstileToken
  ) => {
    dispatch(
      uploadFile(
        acceptedFiles,
        terms,
        privacy,
        scale,
        model,
        isPaidUser,
        turnstileToken
      )
    )
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(UploadBox))
