import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { goBack, push } from 'connected-react-router'
import cx from 'classnames'
import { generateRoute } from '../i18n/helpers'
import { downloadFile } from '../actions/download'
import ModelType from './ModelType'
import BackBlue from './icons/BackBlue'
import { countOccurrences, getFileType } from '../helpers'

const BulkUpload = ({ bulkUpload }) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const upscaleRequests = bulkUpload.upscale_requests
  const remainingUpscales = parseInt(
    useSelector((state) => state.user.userRemainingUpscales)
  )
  const pushUrl = (url) => dispatch(push(url))
  const downloadDisabled =
    upscaleRequests.filter((u) => u.status === 'success').length === 0
  const extractImageSizeFromMeta = (meta) => `${meta.width}x${meta.height} px`
  const counts = countOccurrences(
    upscaleRequests.map((u) => getFileType(u.upscaled_image))
  )
  const onGoBack = () => dispatch(goBack())

  return (
    <div className="container">
      <div className="uploader">
        <div className="image-preview__image-improved">
          <FormattedMessage
            id="bulk_upload.n_images_improved_by"
            values={{
              n: <span className="semi-bold">{upscaleRequests.length}</span>,
              scale: <span className="semi-bold">{bulkUpload.scale}x</span>,
              modelType: (
                <span className="semi-bold">
                  <ModelType modelName={bulkUpload.model_type} />
                </span>
              )
            }}
          />
        </div>
        <div className="bulk-upload bulk-upload--processed">
          <div
            className="image-preview__oval"
            onClick={onGoBack}
            role="presentation"
          >
            <BackBlue />
          </div>
          <div className="bulk-upload__file-list-row bulk-upload__file-list-row--processed bulk-upload__file-list-row--header">
            <span />
            <span>
              <FormattedMessage id="bulk_upload.image" />
            </span>
            <span>
              <FormattedMessage id="bulk_upload.original" />
            </span>
            <span>
              <FormattedMessage
                id="bulk_upload.upscaled_n_times"
                values={{ n: bulkUpload.scale }}
              />
            </span>
            <span />
          </div>

          <div className="bulk-upload__file-list-container">
            {upscaleRequests.map((upscaleRequest) => (
              <div
                className="bulk-upload__file-list-row bulk-upload__file-list-row--processed"
                key={upscaleRequest.id}
              >
                <span className="center">
                  {upscaleRequest.status === 'success' && (
                    <img
                      src={upscaleRequest.thumbnail_url}
                      style={{
                        height: 'auto',
                        maxHeight: 50,
                        maxWidth: 50
                      }}
                    />
                  )}
                </span>
                <span
                  className={cx({
                    green: upscaleRequest.status === 'success',
                    red: upscaleRequest.status === 'failure',
                    'semi-bold': upscaleRequest.status === 'failure'
                  })}
                >
                  {upscaleRequest.file_name}
                </span>
                <span
                  className={cx({
                    red: upscaleRequest.status === 'failure'
                  })}
                >
                  {upscaleRequest.status === 'success' &&
                    extractImageSizeFromMeta(
                      upscaleRequest.original_image_meta
                    )}
                  {upscaleRequest.status == 'failure' && 'Processing failed'}
                </span>
                <span
                  className={cx({
                    red: upscaleRequest.status === 'failure'
                  })}
                >
                  {upscaleRequest.status === 'success' &&
                    extractImageSizeFromMeta(
                      upscaleRequest.upscaled_image_meta
                    )}
                  {upscaleRequest.status == 'failure' && 'Not upscaled'}
                </span>
                <span>
                  {!['processing', 'success'].includes(upscaleRequest.status) &&
                    'N/A'}
                  {upscaleRequest.status === 'processing' && (
                    <FormattedMessage id="bulk_upload.generating" />
                  )}
                  {upscaleRequest.status === 'success' && (
                    <>
                      <div
                        onClick={() =>
                          pushUrl(
                            generateRoute(intl.locale, 'routes.preview', {
                              requestId: upscaleRequest.id
                            })
                          )
                        }
                        className="bulk-upload__action"
                      >
                        <FormattedMessage id="bulk_upload.preview" />
                      </div>{' '}
                      &bull;{' '}
                      <div
                        onClick={() =>
                          downloadFile(
                            upscaleRequest.download_image_link,
                            () => {}
                          )
                        }
                        className="bulk-upload__action"
                      >
                        <FormattedMessage
                          id="bulk_upload.download_as_format"
                          values={{
                            format: getFileType(
                              upscaleRequest.upscaled_image
                            ).toUpperCase()
                          }}
                        />
                      </div>
                    </>
                  )}
                </span>
              </div>
            ))}
          </div>

          <div className="bulk-upload__status-container">
            <div className="bulk-upload__status">
              <span className="green">
                <FormattedMessage
                  id="bulk_upload.images"
                  values={{ imagesCount: upscaleRequests.length }}
                />
              </span>
              <br />
              <span className="bulk-upload__status-help-text">
                {Object.keys(counts)
                  .map((key) => `${counts[key]}x ${key.toUpperCase()}`)
                  .join(', ')}
              </span>
            </div>
            <div className="bulk-upload__status">
              <span className="red">
                <FormattedMessage
                  id="bulk_upload.errors"
                  values={{
                    errorsCount: upscaleRequests.filter(
                      (u) => 'failure' === u.status
                    ).length
                  }}
                />
              </span>
              <br />
              <span className="bulk-upload__status-help-text">
                <FormattedMessage id="bulk_upload.invalid_image_files" />
              </span>
            </div>
            <div className="bulk-upload__status">
              <FormattedMessage
                id="bulk_upload.upscales_used"
                values={{
                  upscalesUsed: upscaleRequests.filter(
                    (u) => u.status !== 'failure'
                  ).length
                }}
              />
              <br />
              <span className="bulk-upload__status-help-text">
                <FormattedMessage
                  id="bulk_upload.upscales_remaining"
                  values={{ upscalesLeft: remainingUpscales }}
                />
              </span>
            </div>
          </div>
        </div>

        <div className="center">
          <div
            className={cx({
              button: true,
              'button--primary': true,
              'button--large': true,
              'button--disabled': downloadDisabled,
              'm-t-30': true
            })}
            onClick={() => {
              if (!downloadDisabled) {
                downloadFile(
                  `/api/v1/bulk_uploads/${bulkUpload.id}.zip`,
                  () => {}
                )
              }
            }}
            role="presentation"
          >
            <FormattedMessage id="bulk_upload.download_images_as_zip" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BulkUpload
