import React from 'react'
import { FormattedMessage } from 'react-intl'

const ReceiptsList = ({ receipts }) => {
  const productImageUrl = (receipt) => {
    if (!receipt.paddle_product) {
      return ''
    }

    if (receipt.paddle_product.paddle_product === '647401') {
      return '/images/Avatar20@2x.png'
    } else if (receipt.paddle_product.paddle_product === '641075') {
      return '/images/Avatar50@2x.png'
    } else if (receipt.paddle_product.paddle_product === '647403') {
      return '/images/Avatar100@2x.png'
    } else if (receipt.paddle_product.paddle_product === '641076') {
      return '/images/Avatar500@2x.png'
    }

    return ''
  }

  return (
    <div className="upscales-list upscales-list--receipts">
      <div className="upscales-list__content">
        <div className="upscales-list__row">
          <div className="upscales-list__column upscales-list__column--heading">
            <FormattedMessage id="account_history.name" />
          </div>
          <div className="upscales-list__column upscales-list__column--input-output upscales-list__column--heading">
            <FormattedMessage id="account_history.product" />
          </div>
          <div className="upscales-list__column upscales-list__column--heading">
            <FormattedMessage id="account_history.upscales" />
          </div>
          <div className="upscales-list__column upscales-list__column--heading">
            <FormattedMessage id="account_history.price" />
          </div>
          <div className="upscales-list__column upscales-list__column--heading">
            <FormattedMessage id="account_history.date" />
          </div>
          <div className="upscales-list__column upscales-list__column--buttons" />
        </div>

        {receipts.map((receipt) => (
          <div className="upscales-list__row" key={`receipt-${receipt.id}`}>
            <div className="upscales-list__column">
              <img
                src={productImageUrl(receipt)}
                className="upscales-list__image"
              />
            </div>
            <div className="upscales-list__column">{receipt.product_name}</div>
            <div className="upscales-list__column">{receipt.upscales}</div>
            <div className="upscales-list__column">
              {receipt.paddle_product ? `${receipt.paddle_product.price}` : ''}
            </div>
            <div className="upscales-list__column">
              <FormattedMessage
                id="account_history.time_ago"
                values={{ time: receipt.date }}
              />
            </div>
            <div className="upscales-list__column">
              <div
                className="button button--secondary button--large"
                onClick={() => {
                  const win = window.open(receipt.receipt_url, '_blank')
                  win.focus()
                }}
                role="presentation"
              >
                <FormattedMessage id="account_history.view_receipt" />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ReceiptsList
