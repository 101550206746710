import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import editor from './editor'
import settings from './settings'
import upload from './upload'
import user from './user'

export default (history) =>
  combineReducers({
    editor,
    router: connectRouter(history),
    settings,
    upload,
    user
  })
