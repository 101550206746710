import React from 'react'

const Minimize = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M5 5H0V4h4V0h1zm10 0V0h1v4h4v1zM5 15v5H4v-4H0v-1zm10 0h5v1h-4v4h-1z"
      ></path>
    </svg>
  )
}

export default Minimize
