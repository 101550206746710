import React from 'react'

const Maximize = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      {...props}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#FFF" transform="translate(-1315 -350)">
          <path d="M1315 350h5v1h-4v4h-1v-5zm20 0v5h-1v-4h-4v-1h5zm-20 20v-5h1v4h4v1h-5zm20 0h-5v-1h4v-4h1v5z"></path>
        </g>
      </g>
    </svg>
  )
}

export default Maximize
