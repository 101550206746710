import React, { useState } from 'react'
import cx from 'classnames'
import { FormattedMessage, useIntl } from 'react-intl'

const FeatureSwitch = ({ onChange, options, value }) => {
  const optionWidth = 120
  let activeOptionIndex = 0

  options.forEach((option, index) => {
    if (option.value === value) {
      activeOptionIndex = index
    }
  })

  return (
    <div className="feature__switch-container">
      {options.map((option, index) => (
        <div
          className={cx({
            'feature__switch-option': true,
            'feature__switch-option--active': option.value === value
          })}
          key={option.id}
          style={{
            left: index * optionWidth
          }}
          onClick={() => onChange(option)}
          role="presentation"
        >
          <div>{option.name}</div>
        </div>
      ))}
      <div
        className="feature__switch-blue-box"
        style={{
          left: activeOptionIndex * optionWidth
        }}
      />
      <div className="feature__switch-arrow-left" />
    </div>
  )
}

const Features = () => {
  const [modelType, setModelType] = useState('artifact_removal')
  const intl = useIntl()

  return (
    <>
      <div className="feature">
        <div className="feature__container">
          <div className="feature__column">
            <div className="feature__content">
              <div className="mb-3">
                <span className="feature__badge">
                  <FormattedMessage id="features.new" />
                </span>
              </div>
              <div className="feature__title">
                <FormattedMessage id="features.experience_facepro" />
              </div>
              <div className="feature__description">
                <FormattedMessage id="features.experience_facepro_text" />
              </div>
            </div>
          </div>

          <div className="feature__column">
            <div className="feature__image-container">
              <img
                src="/images/FacePro-Upscaler.webp"
                srcSet="/images/FacePro-Upscaler.webp 1x, /images/FacePro-Upscaler@2x.webp 2x"
                className="feature__image"
                alt="Face Pro Upscaler"
              />
              <div
                className="comparison__label"
                style={{
                  bottom: 'auto',
                  left: 'auto',
                  right: 'calc(50% + 20px)',
                  top: 20
                }}
              >
                <FormattedMessage id="features.original" />
              </div>
              <div
                className="comparison__label"
                style={{
                  bottom: 'auto',
                  left: 'auto',
                  right: 90,
                  top: 20
                }}
              >
                <FormattedMessage id="features.facepro" />
              </div>
              <div className="feature__image__8x">8x</div>
            </div>
          </div>
        </div>
      </div>

      <div className="feature">
        <div className="feature__container feature__container--reverse-columns">
          <div className="feature__column">
            <div className="feature__image-container">
              <img
                src="/images/RefineArtifact.webp"
                srcSet="/images/RefineArtifact.webp 1x, /images/RefineArtifact@2x.webp 2x"
                className={cx({
                  feature__image: true,
                  'feature__image--hidden': modelType !== 'artifact_removal'
                })}
                alt="Artifact Removal"
              />
              <img
                src="/images/RefineIron.webp"
                srcSet="/images/RefineIron.webp 1x, /images/RefineIron@2x.webp 2x"
                className={cx({
                  feature__image: true,
                  'feature__image--hidden': modelType !== 'ironed_out'
                })}
                alt="Ironed Out"
              />
              <img
                src="/images/RefineHifi.webp"
                srcSet="/images/RefineHifi.webp 1x, /images/RefineHifi@2x.webp 2x"
                className={cx({
                  feature__image: true,
                  'feature__image--hidden': modelType !== 'detail_pro'
                })}
                alt="DetailPro"
              />
            </div>
          </div>
          <div className="feature__column">
            <div className="feature__content">
              <div className="feature__premium-feature">
                <FormattedMessage id="features.premium_feature" />
              </div>
              <div className="feature__title">
                <FormattedMessage id="features.three_types_of_magic" />
              </div>
              <div className="feature__description">
                <FormattedMessage id="features.three_types_of_magic_text" />
              </div>
              <FeatureSwitch
                onChange={(option) => setModelType(option.value)}
                options={[
                  {
                    id: 1,
                    name: intl.formatMessage({
                      id: 'image_preview.artifact_removal'
                    }),
                    value: 'artifact_removal'
                  },
                  {
                    id: 2,
                    name: intl.formatMessage({
                      id: 'image_preview.ironed_out'
                    }),
                    value: 'ironed_out'
                  },
                  {
                    id: 3,
                    name: intl.formatMessage({
                      id: 'image_preview.detail_pro'
                    }),
                    value: 'detail_pro'
                  }
                ]}
                value={modelType}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="feature">
        <div className="feature__container">
          <div className="feature__column">
            <div className="feature__content">
              <div className="feature__title">
                <FormattedMessage id="features.images_sharp_again" />
              </div>
              <div className="feature__description">
                <FormattedMessage id="features.images_sharp_again_text" />
              </div>
            </div>
          </div>

          <div className="feature__column">
            <div className="feature__image-container">
              <img
                src="/images/ImageUpscale.webp"
                srcSet="/images/ImageUpscale.webp 1x, /images/ImageUpscale@2x.webp 2x"
                className="feature__image"
                alt="Image Upscale"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="feature">
        <div className="feature__container">
          <div className="feature__column">
            <div className="feature__image-container">
              <img
                src="/images/SkyscraperUpscaler.webp"
                srcSet="/images/SkyscraperUpscaler.webp 1x, /images/SkyscraperUpscaler@2x.webp 2x"
                className="feature__image"
                alt="Image Upscale"
              />
              <div
                className="comparison__label"
                style={{
                  bottom: 'auto',
                  left: 'auto',
                  right: 'calc(50% + 20px)',
                  top: 20
                }}
              >
                <FormattedMessage id="image_preview.original" />
              </div>
              <div
                className="comparison__label"
                style={{
                  bottom: 'auto',
                  left: 'auto',
                  right: 20,
                  top: 20
                }}
              >
                <FormattedMessage id="image_preview.upscaled" />
              </div>
            </div>
          </div>
          <div className="feature__column">
            <div className="feature__content">
              <div className="feature__title">
                <FormattedMessage id="features.higher_resolution" />
              </div>
              <div className="feature__description">
                <FormattedMessage id="features.higher_resolution_text" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Features
