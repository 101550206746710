import React from 'react'

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="6"
      viewBox="0 0 11 6"
      {...props}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g stroke="#cddbdc" transform="translate(-1585 -383)">
          <path
            d="M1587 380L1587 387 1594 387"
            transform="rotate(-45 1590.5 383.5)"
          ></path>
        </g>
      </g>
    </svg>
  )
}

export default Icon
