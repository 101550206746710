import React from 'react'

function Cogwheel() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g
          fillRule="nonzero"
          className="svg-stroke"
          transform="translate(-1438 -323)"
        >
          <g transform="translate(1415 300)">
            <path d="M36.133 27.956l.003.004-.003-.004zm-.06-.105l-1.276-2.205-1.877.766-.228-.177a4.362 4.362 0 00-1.067-.62l-.273-.108-.039-.29-.23-1.717h-2.548l-.27 2.01-.276.106a4.177 4.177 0 00-1.063.619l-.229.177-.267-.11-1.61-.656-1.275 2.205 1.598 1.252-.039.285c-.03.218-.045.421-.045.612 0 .19.015.394.045.612l.039.285-.226.177-1.372 1.075 1.27 2.197 1.884-.757.227.176c.331.258.683.463 1.072.622l.267.11.039.286.232 1.717h2.545l.27-2 .264-.111a4.708 4.708 0 001.077-.624l.226-.176.267.107 1.616.65 1.271-2.197-1.598-1.252.04-.285c.029-.218.045-.421.045-.612 0-.19-.016-.394-.046-.612l-.039-.285.226-.177 1.373-1.075zm-1.339-2.313l-.001-.003.001.003zM29.81 27.5a2.5 2.5 0 11-2.5 2.5c0-1.386 1.113-2.5 2.5-2.5z"></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Cogwheel
