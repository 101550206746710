import React from 'react'
import cx from 'classnames'

const Switch = ({
  disabled = false,
  inputName,
  name,
  onChange,
  onDisabledClick,
  options,
  smoothing = false,
  value
}) => {
  const activeOption = options.find((option) => option.value === value)

  return (
    <div
      className={cx({
        switch: true,
        'switch--disabled': disabled,
        'switch--smoothing': smoothing
      })}
    >
      <div className="switch__name">
        {name}
        {disabled && <div className="icon icon--lock-dimmed m-l-5" />}
      </div>
      <div className="switch__items-container">
        {options.map((option) => (
          <div
            className={cx({
              switch__item: true,
              'switch__item--active': option.value === value,
              'switch__item--disabled': option.disabled
            })}
            key={option.id}
            onClick={() => {
              if (!option.disabled) {
                onChange(option.value)
              } else {
                onDisabledClick()
              }
            }}
          >
            {option.name}
          </div>
        ))}
      </div>
      <div className="switch__items-container switch__items-container--mobile">
        {disabled && activeOption && (
          <div
            className="switch__item switch__item--active"
            onClick={onDisabledClick}
            role="presentation"
          >
            {activeOption.name}
          </div>
        )}
        {!disabled && (
          <select
            name={inputName}
            id={inputName}
            value={value}
            onChange={(event) => onChange(event.target.value)}
            className="switch__item switch__item--active"
          >
            {options.map((option) => (
              <option
                value={option.value}
                key={option.id}
                disabled={option.disabled}
              >
                {option.name}
              </option>
            ))}
          </select>
        )}
      </div>
    </div>
  )
}

export default Switch
