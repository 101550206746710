import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { FormattedMessage, useIntl } from 'react-intl'
import ProcessingMessage from './ProcessingMessage'
import UploaderDropzone from './UploaderDropzone'
import ProcessingSingle from './ProcessingSingle'
import ProcessingBulk from './ProcessingBulk'
import { generateRoute } from '../i18n/helpers'

const Uploader = ({
  bulkUpload,
  bulkUploadFiles,
  cancelUploading,
  disabled = false,
  fileName,
  isBulkProcessing,
  isBulkUploading,
  isPaidUser,
  isUploading,
  model,
  processingFilePlacement,
  progress,
  push,
  remainingUpscales,
  scale,
  setModel,
  setScale,
  startBulkUpload,
  uploadFile,
  uploadType
}) => {
  const intl = useIntl()

  return (
    <div className={cx({
      uploader: true,
      'uploader--disabled': disabled
    })}>
      <div
        className={cx({
          uploader__status: true,
          'uploader__status--pulse': isUploading
        })}
      >
        {!isUploading && (
          <>
            <span className="uploader__4x">{scale}x</span>{' '}
            <span>
              <FormattedMessage id="uploader.upscale_and_enhance" />
            </span>{' '}
            <FormattedMessage id="uploader.with_smart_ai" />
            {!isPaidUser && (
              <span className="uploader__free">
                <FormattedMessage id="uploader.free" />
              </span>
            )}
          </>
        )}
        {isUploading && uploadType === 'single' && <ProcessingMessage />}
        {isUploading && uploadType === 'bulk' && isBulkUploading && (
          <ProcessingMessage />
        )}
        {!isBulkUploading && isBulkProcessing && (
          <span>
            <FormattedMessage id="uploader.bulk_upload_completed" />
          </span>
        )}
      </div>
      {!isUploading && (
        <UploaderDropzone
          disabled={disabled}
          isPaidUser={isPaidUser}
          model={model}
          onDrop={(acceptedFiles) => {
            if (acceptedFiles.length === 1) {
              uploadFile(acceptedFiles)
            } else if (!isPaidUser) {
              push(generateRoute(intl.locale, 'routes.upgrade'))
            } else {
              startBulkUpload(acceptedFiles)
            }
          }}
          push={push}
          scale={scale}
          setModel={setModel}
          setScale={setScale}
        />
      )}
      {isUploading && uploadType === 'single' && (
        <ProcessingSingle
          cancelUploading={cancelUploading}
          fileName={fileName}
          isPaidUser={isPaidUser}
          processingFilePlacement={processingFilePlacement}
          progress={progress}
        />
      )}
      {isUploading && uploadType === 'bulk' && (
        <ProcessingBulk
          bulkUpload={bulkUpload}
          isBulkProcessing={isBulkProcessing}
          isBulkUploading={isBulkUploading}
          remainingUpscales={remainingUpscales}
          uploads={bulkUploadFiles}
        />
      )}
      <a
        className="uploader__by-stock-photos"
        target="_blank"
        href="https://www.stockphotos.com/"
        rel="noreferrer"
      >
        by Stockphotos.com
      </a>
    </div>
  )
}

Uploader.propTypes = {
  cancelUploading: PropTypes.func.isRequired,
  fileName: PropTypes.string.isRequired,
  isUploading: PropTypes.bool.isRequired,
  processingFilePlacement: PropTypes.string,
  progress: PropTypes.number.isRequired,
  push: PropTypes.func.isRequired,
  uploadFile: PropTypes.func.isRequired
}

export default Uploader
