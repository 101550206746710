import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import Handle from '../icons/Handle'
import Minimize from '../icons/Minimize'
import { UpscaleRequestType } from '../../proptypes'

const ComparisonFullScreen = ({ upscaleRequest, toggleFullScreenMode }) => {
  const [sliderPosition, setSliderPosition] = useState(50)
  const [displayKeyPressMessage, setDisplayKeyPressMessage] = useState(true)
  const intl = useIntl()

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisplayKeyPressMessage(false)
    }, 3000)
    return () => clearTimeout(timer)
  }, [])

  return (
    <div className="image-preview__comparison-full-screen">
      <div className="comparison comparison--full-screen">
        <div
          className="comparison__cancel-full-screen"
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({
              id: 'image_preview.cancel_fullscreen'
            })
          }}
          style={{ opacity: displayKeyPressMessage ? 1 : 0 }}
        />
        <div className="comparison__image-improved-full-screen">
          <span className="semi-bold">
            <FormattedMessage id="image_preview.image_improved" /> →
          </span>
          &nbsp;
          <span
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage(
                {
                  id: 'image_preview.upscaled_from_to'
                },
                {
                  from: `${upscaleRequest.original_image_meta.width}x${upscaleRequest.original_image_meta.height}`,
                  to: `<span class="semi-bold">${upscaleRequest.upscaled_image_meta.width}x${upscaleRequest.upscaled_image_meta.height}</span>`
                }
              )
            }}
          />
        </div>
        <div
          className="comparison__divisor"
          style={{
            backgroundImage: `url(${upscaleRequest.upscaled_image})`,
            width: '3px',
            left: `${sliderPosition}%`
          }}
        >
          <Handle
            className="comparison__handle"
            style={{ left: `calc(${sliderPosition}% - 18.5px)` }}
          />
        </div>
        <div
          className="comparison__image comparison__image--original"
          style={{ width: `${sliderPosition}%` }}
        >
          <div className="comparison__label">
            <FormattedMessage id="image_preview.original" />
          </div>
          <img
            src={upscaleRequest.original_image}
            style={{
              height: '100vh',
              width: '100vw',
              objectFit: 'contain'
            }}
            alt="Original"
          />
        </div>
        <div className="comparison__image comparison__image--upscaled">
          <div className="comparison__label comparison__label--upscaled">
            <FormattedMessage id="image_preview.upscaled" />
          </div>
          <div
            className="comparison__full-screen"
            onClick={() => toggleFullScreenMode(false)}
            role="presentation"
          >
            <Minimize />
          </div>
          <img
            src={upscaleRequest.upscaled_image}
            style={{
              height: '100vh',
              width: '100vw',
              objectFit: 'contain'
            }}
            alt="Upscaled"
          />
        </div>
        <input
          type="range"
          min="0"
          max="100"
          value={sliderPosition}
          onChange={(event) => setSliderPosition(event.target.value)}
        />
      </div>
    </div>
  )
}

ComparisonFullScreen.propTypes = {
  toggleFullScreenMode: PropTypes.func.isRequired,
  upscaleRequest: UpscaleRequestType.isRequired
}

export default ComparisonFullScreen
