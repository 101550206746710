import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { FormattedMessage, injectIntl } from 'react-intl'
import cx from 'classnames'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Flag from '../components/icons/Flag'
import Message from '../components/Message'
import Checkbox from '../components/Checkbox'
import Ok from '../components/icons/Ok'
import Stripe from '../components/icons/Stripe'
import { generateRoute } from '../i18n/helpers'
import { fetchUpscaleRequest } from '../actions/upload'
import { report } from '../actions/editor'
import ShutterstockPromo from '../components/ShutterstockPromo'
import IstockPromo from '../components/IstockPromo'
import { UnprocessedUpscaleRequestType } from '../proptypes'
import { validateEmail } from '../helpers'

class FileReport extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      privacyPolicyAccepted: false,
      reportSubmitted: false,
      termsAccepted: false
    }
  }

  componentDidMount() {
    const { fetchUpscaleRequestCb, match, upscaleRequest } = this.props
    const { requestId } = match.params

    if (!upscaleRequest) {
      fetchUpscaleRequestCb(requestId)
    }
  }

  render() {
    const {
      pushUrl,
      reportCb,
      upscaleRequest,
      intl,
      location,
      statusMessage,
      user
    } = this.props
    const {
      email,
      privacyPolicyAccepted,
      reportSubmitted,
      termsAccepted
    } = this.state
    const shouldDisplayAd = user.userLoaded && user.userStatus === 'free'
    const shouldShowForm = user.userLoaded && !user.userAuthenticated

    const displayStatusMessage = () => {
      if (statusMessage === 'upscale_error') {
        return (
          <div
            className="file-report__so-happy"
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: 'report.upscale_error'
              })
            }}
          />
        )
      } else if (statusMessage === 'upscale_timeout') {
        return (
          <div
            className="file-report__so-happy"
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: 'report.upscale_timeout'
              })
            }}
          />
        )
      }

      return null
    }

    const renderSecondaryActionButton = () => (
      <>
        {upscaleRequest.upscaled_image && (
          <div
            className="file-report__upscale-another"
            onClick={() =>
              pushUrl(
                generateRoute(intl.locale, 'routes.preview', {
                  requestId: upscaleRequest.id
                })
              )
            }
            role="presentation"
          >
            <FormattedMessage id="report.cancel" />
          </div>
        )}

        {!upscaleRequest.upscaled_image && (
          <div
            className="file-report__upscale-another"
            onClick={() => pushUrl(generateRoute(intl.locale, 'routes.home'))}
            role="presentation"
          >
            <FormattedMessage id="upload_failed.upscale_another" />
          </div>
        )}
      </>
    )

    return (
      <div className="alert-glow">
        <Header />
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <Message
                failure
                center
                text={<FormattedMessage id="report.image_supervision" />}
                icon={<Flag style={{ fill: '#c51162' }} />}
              />
              {upscaleRequest && (
                <div className="file-report">
                  {shouldDisplayAd && (
                    <ShutterstockPromo className="banner file-report__shutterstock" />
                  )}
                  {shouldDisplayAd && (
                    <IstockPromo
                      location={location}
                      className="banner file-report__istock"
                    />
                  )}
                  <Stripe className="file-report__stripe" />
                  {!reportSubmitted && shouldShowForm && (
                    <div>
                      {displayStatusMessage()}
                      <div className="file-report__input-container">
                        {/* eslint-disable-next-line */}
                        <label
                          className="file-report__input-label"
                          htmlFor="email"
                        >
                          <FormattedMessage id="report.email" />
                        </label>
                        <input
                          className="file-report__input"
                          value={email}
                          onChange={(event) =>
                            this.setState({ email: event.target.value })
                          }
                          placeholder={intl.formatMessage({
                            id: 'report.enter_your_email'
                          })}
                          id="email"
                        />
                      </div>

                      <div className="file-report__terms">
                        <Checkbox
                          name="privacy-accepted"
                          checked={privacyPolicyAccepted}
                          onChange={(event) =>
                            this.setState({
                              privacyPolicyAccepted: event.target.checked
                            })
                          }
                          text={
                            <span
                              className="regular"
                              dangerouslySetInnerHTML={{
                                __html: intl.formatMessage({
                                  id: 'report.agree_with_privacy'
                                })
                              }}
                            />
                          }
                        />
                      </div>
                      <div className="file-report__terms">
                        <Checkbox
                          name="terms-accepted"
                          checked={termsAccepted}
                          onChange={(event) =>
                            this.setState({
                              termsAccepted: event.target.checked
                            })
                          }
                          text={
                            <span
                              className="regular"
                              dangerouslySetInnerHTML={{
                                __html: intl.formatMessage({
                                  id: 'report.agree_with_terms'
                                })
                              }}
                            />
                          }
                        />
                      </div>

                      <div className="file-report__get-help">
                        <div
                          className={cx({
                            button: true,
                            'button--primary': true,
                            'button--large': true,
                            'button--disabled':
                              !termsAccepted ||
                              !privacyPolicyAccepted ||
                              !validateEmail(email)
                          })}
                          onClick={() => {
                            if (
                              termsAccepted &&
                              privacyPolicyAccepted &&
                              validateEmail(email)
                            ) {
                              reportCb(upscaleRequest, email, () => {
                                this.setState({ reportSubmitted: true })
                              })
                            }
                          }}
                          role="presentation"
                        >
                          <FormattedMessage id="report.send_report" />
                        </div>
                      </div>

                      {renderSecondaryActionButton()}
                    </div>
                  )}
                  {!reportSubmitted && !shouldShowForm && (
                    <div>
                      {displayStatusMessage()}
                      <div className="file-report__get-help">
                        <div
                          className={cx({
                            button: true,
                            'button--primary': true,
                            'button--large': true
                          })}
                          onClick={() => {
                            reportCb(upscaleRequest, user.userEmail, () => {
                              this.setState({ reportSubmitted: true })
                            })
                          }}
                          role="presentation"
                        >
                          <FormattedMessage id="report.send_report" />
                        </div>
                      </div>
                      {renderSecondaryActionButton()}
                    </div>
                  )}
                  {reportSubmitted && (
                    <div>
                      <Ok className="file-report__ok" />
                      <div className="file-report__thank-you">
                        <FormattedMessage id="report.thank_you_for_support" />
                      </div>
                      <div className="file-report__let-us-get-back-to-you">
                        <FormattedMessage id="report.let_us_get_back_to_you" />
                      </div>
                      <div className="file-report__get-help">
                        <div
                          className="button button--primary button--large"
                          onClick={() =>
                            pushUrl(generateRoute(intl.locale, 'routes.home'))
                          }
                          role="presentation"
                        >
                          <FormattedMessage id="report.convert_another" />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  statusMessage: state.upload.statusMessage,
  upscaleRequest: state.editor.currentRequest,
  user: state.user
})

const mapDispatchToProps = (dispatch) => ({
  fetchUpscaleRequestCb: (requestId) => {
    dispatch(fetchUpscaleRequest(requestId))
  },
  pushUrl: (args) => {
    dispatch(push(args))
  },
  reportCb: (upscaleRequest, email, callback) => {
    dispatch(report(upscaleRequest, email, callback))
  }
})

FileReport.propTypes = {
  fetchUpscaleRequestCb: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired
  }).isRequired,
  location: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      requestId: PropTypes.string
    })
  }).isRequired,
  pushUrl: PropTypes.func.isRequired,
  reportCb: PropTypes.func.isRequired,
  upscaleRequest: UnprocessedUpscaleRequestType
}

FileReport.defaultProps = {
  upscaleRequest: null
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(FileReport))
