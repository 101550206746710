import React from 'react'

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="137"
      height="27"
      viewBox="0 0 137 27"
      {...props}
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeLinecap="round"
        strokeWidth="1"
      >
        <g stroke="#00B557" strokeWidth="1.5" transform="translate(-653 -529)">
          <g transform="translate(616 530)">
            <g transform="translate(38)">
              <path d="M0 24.377c20.51-6.569 42.597-11.422 66.264-14.56 23.666-3.139 45.754-4.854 66.263-5.147"></path>
              <path
                strokeLinejoin="round"
                d="M120.354 0c2.623.356 5.123.959 7.5 1.807a31.127 31.127 0 016.673 3.319c-2.524.535-4.748 1.205-6.673 2.011a43.097 43.097 0 00-5.63 2.863"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Icon
