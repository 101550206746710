import React, { useState } from 'react'
import cx from 'classnames'
import { connect, useDispatch } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import { push } from 'connected-react-router'
import Turnstile from 'react-turnstile'
import queryString from 'query-string'

import Checkbox from '../components/Checkbox'
import { validateEmail, snakeCase } from '../helpers'
import { generateRoute } from '../i18n/helpers'
import { signUp, validateLoginCode } from '../actions/auth'
import { TURNSTILE_SITE_KEY } from '../consts/turnstile'

const TwoStepLogin = ({
  errors,
  loginStep,
  signUpCb,
  userEmail,
  validateLoginCodeCb
}) => {
  const intl = useIntl()
  const queryParams = queryString.parse(location.search)
  const [email, setEmail] = useState(queryParams.email || '')
  const [turnstileKey, setTurnstileKey] = useState(Date.now())
  const [turnstileToken, setTurnstileToken] = useState('')
  const [loginCode, setLoginCode] = useState('')
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false)
  const formIsValid =
    termsAccepted &&
    privacyPolicyAccepted &&
    validateEmail(email) &&
    turnstileToken
  const dispatch = useDispatch()
  const pushUrl = (url) => dispatch(push(url))

  return (
    <>
      {loginStep === 'step-one' && (
        <div>
          <div
            className="file-report__so-happy"
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: 'magic_login.enter_email_message'
              })
            }}
          />
          <div className="file-report__input-container">
            {/* eslint-disable-next-line */}
            <label className="file-report__input-label" htmlFor="Email">
              E-mail
            </label>
            <input
              className={cx({
                'file-report__input': true,
                'file-report__input--errors': errors.length > 0
              })}
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              placeholder={intl.formatMessage({
                id: 'report.enter_your_email'
              })}
              id="Email"
            />
            {errors.map((error) => (
              <div className="file-report__error" key={error}>
                <FormattedMessage id={`checkout.errors.${snakeCase(error)}`} />
              </div>
            ))}
          </div>

          <div className="file-report__terms">
            <Checkbox
              name="privacy-accepted"
              checked={privacyPolicyAccepted}
              onChange={(event) =>
                setPrivacyPolicyAccepted(event.target.checked)
              }
              text={
                <span
                  className="regular"
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({
                      id: 'report.agree_with_privacy'
                    })
                  }}
                />
              }
            />
          </div>
          <div className="file-report__terms">
            <Checkbox
              name="terms-accepted"
              checked={termsAccepted}
              onChange={(event) => setTermsAccepted(event.target.checked)}
              text={
                <span
                  className="regular"
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({
                      id: 'report.agree_with_terms'
                    })
                  }}
                />
              }
            />
          </div>

          <div className="file-report__terms">
            <Turnstile
              key={turnstileKey}
              onVerify={(token) => {
                setTurnstileToken(token)
              }}
              sitekey={TURNSTILE_SITE_KEY}
            />
          </div>

          <div className="file-report__get-help">
            <div
              className={cx({
                button: true,
                'button--primary': true,
                'button--large': true,
                'button--disabled': !formIsValid
              })}
              onClick={() => {
                if (formIsValid) {
                  signUpCb(
                    email,
                    termsAccepted,
                    privacyPolicyAccepted,
                    turnstileToken,
                    intl.locale
                  )
                  setTurnstileKey(Date.now())
                }
              }}
              role="presentation"
            >
              <FormattedMessage id="magic_login.continue" />
            </div>
          </div>
          <div
            className="file-report__upscale-another m-b-30"
            onClick={() => pushUrl(generateRoute(intl.locale, 'routes.home'))}
            role="presentation"
          >
            <FormattedMessage id="contact.cancel" />
          </div>
        </div>
      )}
      {loginStep === 'step-two' && (
        <div>
          <div className="file-report__envelope">
            <div className="icon icon--envelope" />
          </div>
          <div
            className="file-report__check-inbox"
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: 'magic_login.check_your_inbox' })
            }}
          />
          <div
            className="file-report__check-inbox"
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: 'magic_login.check_spam_folder'
              })
            }}
          />
          <div className="file-report__input-container">
            {/* eslint-disable-next-line */}
            <label className="file-report__input-label" htmlFor="login-code">
              <FormattedMessage id="magic_login.login_code" />
            </label>
            <input
              className={cx({
                'file-report__input': true,
                'file-report__input--errors': errors.length > 0
              })}
              value={loginCode}
              onChange={(event) => setLoginCode(event.target.value)}
              placeholder={intl.formatMessage({
                id: 'magic_login.paste_login_code'
              })}
              id="login-code"
            />
            {errors.map((error) => (
              <div className="file-report__error" key={error}>
                <FormattedMessage id={`checkout.errors.${snakeCase(error)}`} />
              </div>
            ))}
          </div>

          <div className="file-report__get-help">
            <div
              className={cx({
                button: true,
                'button--primary': true,
                'button--large': true,
                'button--disabled': !loginCode
              })}
              onClick={() => {
                if (loginCode) {
                  validateLoginCodeCb(userEmail, loginCode)
                }
              }}
              role="presentation"
            >
              <FormattedMessage id="magic_login.finish" />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  errors: state.user.errors,
  loginStep: state.user.loginStep,
  userEmail: state.user.userEmail
})

const mapDispatchToProps = (dispatch) => ({
  signUpCb: (email, termsAccepted, privacyAccepted, turnstileToken, locale) => {
    dispatch(
      signUp(email, termsAccepted, privacyAccepted, turnstileToken, locale)
    )
  },
  validateLoginCodeCb: (email, loginCode) => {
    dispatch(validateLoginCode(email, loginCode))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(TwoStepLogin)
