import axios from 'axios'

export const fetchUpscaleRequests = () => (dispatch) => {
  dispatch({ type: Consts.UPSCALE_REQUEST_FETCH_START })

  axios
    .get(`/api/v1/upscale_requests`)
    .then((response) => {
      dispatch({
        type: Consts.UPSCALE_REQUEST_FETCH_DONE,
        request: response.data
      })
    })
    .catch(() => {
      dispatch({
        type: Consts.UPSCALE_REQUEST_FETCH_ERROR,
        errors: ['could_not_fetch_request']
      })
      dispatch(push(generateRoute(getLanguage(), 'routes.home')))
    })
}

export const fetchReceipts = () => {}
