import { createBrowserHistory, createMemoryHistory } from 'history'
import { applyMiddleware, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
import createRootReducer from '../reducers'

export const history =
  typeof window !== 'undefined' ? createBrowserHistory() : createMemoryHistory()

const configureStore = (preloadedState) => {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    composeWithDevTools(
      applyMiddleware(routerMiddleware(history), thunkMiddleware)
    )
  )

  return store
}

export default configureStore
