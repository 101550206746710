import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { useIntl } from 'react-intl'
import cx from 'classnames'
import { generateRoute } from '../i18n/helpers'

const UpscaleCounterMobile = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const remainingUpscales = parseInt(
    useSelector((state) => state.user.userRemainingUpscales)
  )

  return (
    <div className="button-group">
      <div
        className={cx({
          'button-group__button': true,
          'button-group__button--alert': remainingUpscales <= 3
        })}
        onClick={() =>
          dispatch(push(generateRoute(intl.locale, 'routes.pricing')))
        }
        role="presentation"
      >
        <div className="icon icon--upscales m-r-5" />
        {remainingUpscales}
      </div>
    </div>
  )
}

export default UpscaleCounterMobile
