import React, { useState, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useCookies } from 'react-cookie'
import cx from 'classnames'
import Close from './icons/Close'
import Shutterstock from './icons/Shutterstock'
import ExternalLink from './icons/ExternalLink'

const ExclusiveDealDialog = () => {
  const intl = useIntl()
  const cookieName = 'exclusive-deal-dialog-closed'
  const [cookies, setCookie] = useCookies([cookieName])
  const [displayConsent, setDisplayConsent] = useState(false)
  const [isHover, setHover] = useState(false)

  useEffect(() => {
    setDisplayConsent(!cookies[cookieName])
  }, [])

  return (
    <div
      className={cx({
        'exclusive-deal-dialog': true,
        'exclusive-deal-dialog--closed': !displayConsent
      })}
    >
      <div className="exclusive-deal-dialog__container">
        <div
          className="exclusive-deal-dialog__free-images"
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({
              id: 'exclusive_deal.free_images'
            })
          }}
        />
        <div className="exclusive-deal-dialog__deal">
          <FormattedMessage id="exclusive_deal.deal" />
        </div>
        <Close
          className="exclusive-deal-dialog__close"
          onClick={() => {
            setCookie(cookieName, true, {
              path: '/',
              expires: new Date(Date.now() + 60 * 60 * 1000) // time in ms
            })
            setDisplayConsent(false)
          }}
        />
        <Shutterstock className="exclusive-deal-dialog__logo" />
        <a
          className="exclusive-deal-dialog__button"
          href="https://shutterstock.7eer.net/c/2691128/861128/1305?subId1=sp&subId2=upscaler&subId3=black-popup&u=https%3A%2F%2Fwww.shutterstock.com%2Fdiscover%2F10-free-stock-images"
          target="_blank"
          rel="noreferrer"
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <ExternalLink color={isHover ? '#25282c' : '#fff'} />
          <FormattedMessage id="exclusive_deal.redeem_now" />
        </a>
      </div>
    </div>
  )
}

export default ExclusiveDealDialog
