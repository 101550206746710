import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import Pane from './Pane'
import RadioButton from '../RadioButton'
import Checkbox from '../Checkbox'
import { UpscaleRequestType } from '../../proptypes'
import { generateRoute } from '../../i18n/helpers'

const Options = (props) => {
  const { scheduleEnhancing, upscaleRequest } = props
  const [whiteBalance, setWhiteBalance] = useState(true)
  const [vividSaturation, setVividSaturation] = useState(false)
  const [hdrContrast, setHdrContrast] = useState(false)
  const [upscaleResolution, setUpscaleResolution] = useState(
    String(upscaleRequest.scale)
  )
  const [cleanupCompression, setCleanupCompression] = useState(65)
  const [enhancingModel, setEnhancingModel] = useState(
    String(upscaleRequest.model_type)
  )
  const userLoaded = useSelector((state) => state.user.userLoaded)
  const userStatus = useSelector((state) => state.user.userStatus)
  const isPaidUser = userLoaded && ['standard', 'premium'].includes(userStatus)
  const intl = useIntl()
  const originalImageMeta = upscaleRequest.original_image_meta
  const availableScales =
    originalImageMeta.width <= 1000 && originalImageMeta.height <= 1000
      ? [2, 4, 8]
      : [2, 4]
  const dispatch = useDispatch()
  const pushUrl = (url) => dispatch(push(url))

  const upscaleResolutionOptions = [
    {
      id: 1,
      text: (
        <span className="regular">
          <span className="green green-frame bold">2x</span>{' '}
          <FormattedMessage id="image_preview.upscale" />
          {!isPaidUser && (
            <span className="green uppercase fs-11 m-l-5 semi-bold">
              <FormattedMessage id="image_preview.free" />
            </span>
          )}
        </span>
      ),
      disabled: false,
      value: '2'
    },
    {
      id: 2,
      text: (
        <span className="regular">
          <span className="green green-frame bold">4x</span>{' '}
          <FormattedMessage id="image_preview.upscale" />
          {!isPaidUser && <div className="icon icon--lock m-l-5" />}
        </span>
      ),
      disabled: !isPaidUser,
      value: '4'
    },
    {
      id: 3,
      text: (
        <span className="regular">
          <span className="green green-frame bold">8x</span>{' '}
          <FormattedMessage id="image_preview.upscale" />
          {!isPaidUser && <div className="icon icon--lock m-l-5" />}
        </span>
      ),
      disabled: !isPaidUser || !availableScales.includes(8),
      value: '8'
    }
  ]

  const cleanupCompressionOptions = [
    {
      id: 4,
      text: <span className="regular">Lower size</span>,
      disabled: false,
      value: 65
    },
    {
      id: 5,
      text: <span className="regular">Sharper image</span>,
      disabled: false,
      value: 95
    }
  ]

  return (
    <div className="image-preview__options">
      <Pane
        title={intl.formatMessage({ id: 'image_preview.upscale_resolution' })}
        subtitle={<FormattedMessage id="image_preview.your_file_even_bigger" />}
        open
      >
        <RadioButton
          name="upscale-resolution-radio"
          options={upscaleResolutionOptions}
          selectedOption={upscaleResolution}
          onChange={(event) => {
            setUpscaleResolution(event.target.value)
          }}
        />
      </Pane>
      <Pane
        title={intl.formatMessage({ id: 'image_preview.ai_model' })}
        subtitle={<FormattedMessage id="image_preview.smooth_or_sharp_image" />}
        open
        extraMargin
      >
        <RadioButton
          name="cleanup-compression-radio"
          options={[
            {
              id: 'deblock',
              text: (
                <span className="regular">
                  <FormattedMessage id="image_preview.artifact_removal" />
                  {!isPaidUser && <div className="icon icon--lock m-l-5" />}
                </span>
              ),
              disabled: !isPaidUser,
              value: 'deblock'
            },
            {
              id: 'ultra',
              text: (
                <span className="regular">
                  <FormattedMessage id="image_preview.ironed_out" />
                  {!isPaidUser && <div className="icon icon--lock m-l-5" />}
                </span>
              ),
              disabled: !isPaidUser,
              value: 'ultra'
            },
            {
              id: 'detailpro',
              text: (
                <span className="regular">
                  <FormattedMessage id="image_preview.detail_pro" />

                  {!isPaidUser && (
                    <span className="green uppercase fs-11 m-l-5 semi-bold">
                      <FormattedMessage id="image_preview.free" />
                    </span>
                  )}
                </span>
              ),
              disabled: false,
              value: 'detailpro'
            },
            {
              id: 'facepro',
              text: (
                <span className="regular">
                  <FormattedMessage id="image_preview.face_pro" />
                  {!isPaidUser && <div className="icon icon--lock m-l-5" />}
                </span>
              ),
              disabled: !isPaidUser,
              value: 'facepro'
            }
          ]}
          selectedOption={enhancingModel}
          onChange={(event) => {
            setEnhancingModel(event.target.value)
          }}
        />
      </Pane>
      {false && (
        <Pane
          title="<span>Cleanup</span> compression"
          subtitle="Smooth or sharp edges."
          open
        >
          <RadioButton
            name="cleanup-compression-radio"
            options={cleanupCompressionOptions}
            selectedOption={cleanupCompression}
            onChange={(event) => {
              setCleanupCompression(event.target.value)
            }}
          />
        </Pane>
      )}
      {false && (
        <Pane
          title="<span>Enhance</span> model"
          subtitle="Balanced & vivid."
          open
          extraMargin
        >
          <Checkbox
            name="white-balance"
            checked={whiteBalance}
            onChange={(event) => setWhiteBalance(event.target.checked)}
            text="White balance"
            disabled
          />
          <Checkbox
            name="vivid-saturation"
            checked={vividSaturation}
            onChange={(event) => setVividSaturation(event.target.checked)}
            text="Vivid saturation"
            disabled
          />
          <Checkbox
            name="hdr-contrast"
            checked={hdrContrast}
            onChange={(event) => setHdrContrast(event.target.checked)}
            text="HDR contrast"
            disabled
          />
        </Pane>
      )}
      {isPaidUser && (
        <div
          className="button button--primary button--medium"
          onClick={() => {
            if (isPaidUser) {
              scheduleEnhancing(
                cleanupCompression,
                parseInt(upscaleResolution, 10),
                enhancingModel
              )
            }
          }}
          role="presentation"
        >
          <FormattedMessage id="image_preview.enhance" />
        </div>
      )}
      {!isPaidUser && (
        <div
          className="button button--primary button--medium"
          onClick={() => pushUrl(generateRoute(intl.locale, 'routes.pricing'))}
          role="presentation"
        >
          Buy more
        </div>
      )}
    </div>
  )
}

Options.propTypes = {
  scheduleEnhancing: PropTypes.func.isRequired,
  upscaleRequest: UpscaleRequestType.isRequired
}

export default Options
