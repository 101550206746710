import Consts from '../consts'

const initialState = {
  currentRequest: null,
  downloadUrl: null,
  errors: [],
  isDownloading: false,
  isFetching: false,
  isProcessing: false
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case Consts.FILE_UPLOAD_START: {
      return { ...state, currentRequest: null, errors: [] }
    }
    case Consts.FILE_UPLOAD_DONE: {
      return { ...state, currentFile: action.file, isProcessing: true }
    }
    case Consts.UPSCALE_REQUEST_FETCH_START: {
      return { ...state, isFetching: true }
    }
    case Consts.UPSCALE_REQUEST_FETCH_DONE: {
      return { ...state, isFetching: false, currentRequest: action.request }
    }
    case Consts.UPSCALE_REQUEST_FETCH_ERROR: {
      return { ...state, errors: action.errors }
    }
    case Consts.DOWNLOAD_SCHEDULE_START: {
      return { ...state, isDownloading: true }
    }
    case Consts.DOWNLOAD_SCHEDULE_DONE: {
      return { ...state, currentRequest: action.request }
    }
    case Consts.DOWNLOAD_CHECK_STATUS_DONE: {
      return {
        ...state,
        currentRequest: action.upscaleRequest,
        isDownloading: ['created', 'processing'].includes(
          action.upscaleRequest.status
        )
      }
    }
    case Consts.DOWNLOAD_READY: {
      return { ...state, downloadUrl: action.downloadUrl }
    }
    default:
      return state
  }
}
