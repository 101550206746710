import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Processing from '../components/Processing'
import { checkProcessingStatus } from '../actions/editor'
import { fetchUpscaleRequest } from '../actions/upload'
import { UpscaleRequestType } from '../proptypes'

class ProcessView extends React.Component {
  componentDidMount() {
    const { fetchUpscaleRequestCb, match, upscaleRequest } = this.props
    const { requestId } = match.params

    if (!upscaleRequest) {
      fetchUpscaleRequestCb(requestId)
    }
  }

  render() {
    const { checkProcessingStatusCb, pushUrl, upscaleRequest } = this.props

    return (
      <div className="oval-glow">
        <Header />
        {upscaleRequest && (
          <Processing
            checkProcessingStatus={checkProcessingStatusCb}
            push={pushUrl}
            upscaleRequest={upscaleRequest}
          />
        )}
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  upscaleRequest: state.editor.currentRequest
})

const mapDispatchToProps = (dispatch) => ({
  checkProcessingStatusCb: (upscaleRequest) => {
    dispatch(checkProcessingStatus(upscaleRequest))
  },
  fetchUpscaleRequestCb: (requestId) => {
    dispatch(fetchUpscaleRequest(requestId))
  },
  pushUrl: (args) => {
    dispatch(push(args))
  }
})

ProcessView.propTypes = {
  checkProcessingStatusCb: PropTypes.func.isRequired,
  fetchUpscaleRequestCb: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      requestId: PropTypes.string
    })
  }).isRequired,
  pushUrl: PropTypes.func.isRequired,
  upscaleRequest: UpscaleRequestType
}

ProcessView.defaultProps = {
  upscaleRequest: null
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcessView)
