import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const RadioButton = (props) => {
  const { name, options, selectedOption, onChange } = props

  return options.map((option) => (
    <label
      key={option.id}
      className={cx({
        'radio-button': true,
        'radio-button--disabled': option.disabled
      })}
      htmlFor={`radio-button-${option.id}`}
    >
      {option.text}
      <input
        type="radio"
        name={name}
        disabled={option.disabled}
        checked={option.value === selectedOption}
        value={option.value}
        onChange={onChange}
        id={`radio-button-${option.id}`}
      />
      <span className="radio-button__checkmark" />
    </label>
  ))
}

RadioButton.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool.isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired
}

export default RadioButton
