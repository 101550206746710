import React, { useState, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import axios from 'axios'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { generateRoute } from '../i18n/helpers'
import ReceiptsList from '../components/ReceiptsList'

const Receipts = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const userAuthenticated = useSelector((state) => state.user.userAuthenticated)
  const userEmail = useSelector((state) => state.user.userEmail)
  const userLoaded = useSelector((state) => state.user.userLoaded)
  const [receipts, setReceipts] = useState([])

  const fetchReceipts = () => {
    axios.get(`/users/receipts?locale=${intl.locale}`).then((response) => {
      setReceipts(response.data)
    })
  }

  useEffect(() => {
    if (userLoaded && !userAuthenticated) {
      dispatch(push(generateRoute(intl.locale, 'routes.home')))
    } else if (userLoaded && userAuthenticated) {
      fetchReceipts()
    }
  }, [userLoaded, userAuthenticated, intl.locale])

  return (
    <div className="oval-glow">
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            {userAuthenticated && (
              <>
                <h1 className="account__heading">
                  <FormattedMessage id="account_history.receipts" />
                </h1>
                <div className="account__user-email">{userEmail}</div>
                <ReceiptsList receipts={receipts} />
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Receipts
