import React, { useState, useEffect } from 'react'
import GoToTop from './icons/GoToTop'

const GoToTopOfPageButton = () => {
  const [showScroll, setShowScroll] = useState(false)
  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 0) {
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 0) {
      setShowScroll(false)
    }
  }
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop)

    return () => window.removeEventListener('scroll', checkScrollTop)
  })

  return (
    <div
      className="go-to-top"
      style={{ opacity: showScroll ? 1 : 0 }}
      onClick={scrollTop}
      role="presentation"
    >
      <GoToTop />
    </div>
  )
}

export default GoToTopOfPageButton
