import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

const ProcessingAutoExtended = ({ cancelUploading }) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => setOpen(true), 30000)

    return () => clearTimeout(timeout)
  }, [])

  return (
    open && (
      <div className="terms-dialog">
        <div className="terms-dialog__friendly m-t-30">
          <FormattedMessage id="uploader.processing_autoextended" />
        </div>
        <div className="terms-dialog__content">
          <FormattedMessage id="uploader.processing_autoextended_text" />
        </div>
        <div className="d-flex align-items-center">
          <div
            className="button button--primary button--large m-r-30"
            onClick={() => setOpen(false)}
          >
            <FormattedMessage id="uploader.ok_continue" />
          </div>
          <div
            className="file-report__upscale-another"
            onClick={cancelUploading}
            role="presentation"
          >
            <FormattedMessage id="uploader.cancel" />
          </div>
        </div>
      </div>
    )
  )
}

ProcessingAutoExtended.propTypes = {
  cancelUploading: PropTypes.func.isRequired
}

export default ProcessingAutoExtended
