import axios from 'axios'
import { push } from 'connected-react-router'
import Consts from '../consts'
import { generateRoute, getLanguage } from '../i18n/helpers'
import { getFingerprint } from './upload'
import { pushRegisteredUser, pushFreeUser } from './crisp'

const getHomePath = () => generateRoute(getLanguage(), 'routes.home')

export const signUp =
  (
    email,
    termsOfServiceAccepted,
    privacyPolicyAccepted,
    turnstileToken,
    locale,
    afterSignUpUrl = false,
    referrer = ''
  ) =>
  (dispatch) => {
    dispatch({ type: Consts.USER_SIGN_UP_START, email })

    axios
      .post('/users/sign_up', {
        user: {
          email,
          terms_of_service_accepted: termsOfServiceAccepted,
          privacy_policy_accepted: privacyPolicyAccepted,
          locale,
          referrer,
          turnstile_token: turnstileToken
        }
      })
      .then(() => {
        dispatch({ type: Consts.USER_SIGN_UP_DONE, email })
        if (afterSignUpUrl) {
          dispatch(push(afterSignUpUrl))
        }
      })
      .catch((error) => {
        dispatch({
          type: Consts.USER_SIGN_UP_ERROR,
          errors: error.response.data
        })
      })
  }

export const validateLoginCode = (email, loginCode) => (dispatch) => {
  dispatch({ type: Consts.USER_VERIFY_LOGIN_CODE_START })

  axios
    .post('/magic_links/sign_in_with_temporary_password', {
      magic_link: {
        email,
        temporary_password: loginCode
      }
    })
    .then(() => {
      dispatch({ type: Consts.USER_VERIFY_LOGIN_CODE_DONE, email })
      dispatch(fetchUser())
    })
    .catch((error) => {
      dispatch({
        type: Consts.USER_VERIFY_LOGIN_CODE_ERROR,
        errors: error.response.data
      })
    })
}

export const fetchUser = () => async (dispatch) => {
  const fingerprint = await getFingerprint()

  dispatch({ type: Consts.USER_FETCH_START })

  axios
    .post('/users/current', { user: { fingerprint } })
    .then((response) => {
      const user = response.data

      if (user.email) {
        dispatch({
          type: Consts.USER_FETCH_DONE,
          userAuthenticated: true,
          userEmail: user.email,
          userRemainingUpscales: user.limits.remaining_upscales,
          userStatus: user.user_type,
          userAvatar: user.gravatar_url,
          userUpscaleExpirationTime: user.upscale_expiration_time
        })
        pushRegisteredUser(user)
      } else {
        dispatch({
          type: Consts.USER_FETCH_DONE,
          userAuthenticated: false,
          userEmail: '',
          userRemainingUpscales: user.limits.remaining_upscales,
          userStatus: 'free',
          userAvatar: null
        })
        pushFreeUser(user)
      }
    })
    .catch(() => {
      dispatch({ type: Consts.USER_FETCH_ERROR })
    })
}

export const logOut = () => (dispatch) => {
  dispatch({ type: Consts.USER_VERIFY_LOGIN_CODE_START })

  axios
    .delete('/users/logout')
    .then(() => {
      dispatch({ type: Consts.USER_LOGOUT_DONE })
      dispatch(push(getHomePath()))
      dispatch(fetchUser())
    })
    .catch(() => {
      dispatch({ type: Consts.USER_LOGOUT_ERROR })
    })
}

export const sendMessage =
  (message, email, termsAccepted, language, onSuccess) => () => {
    axios
      .post('/api/v1/contacts/create_message', {
        contact: {
          message,
          email,
          terms_accepted: termsAccepted,
          jelly: 'fish',
          language
        }
      })
      .then(() => {
        onSuccess()
      })
  }

export const purchasedCredits = () => (dispatch) => {
  dispatch({ type: Consts.USER_PURCHASED_CREDITS })
}
