const initialState = {
  maintenanceMode: false,
  statusMessage: '',
  statusMessageColor: 'gray'
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    default:
      return state
  }
}
