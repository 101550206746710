import React from 'react'

const ExternalLink = ({ color = '#00A8FF' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="18"
    height="18"
    viewBox="0 0 18 18"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-163 -217)">
        <g transform="translate(145 109)">
          <g transform="translate(0 98)">
            <g transform="translate(18 10.5)">
              <g transform="translate(0 1.5)">
                <mask id="mask-6" fill="#fff">
                  <path id="path-6" d="M10 6L16 6 16 18 0 18 0 0 10 0z"></path>
                </mask>
                <rect
                  width="15"
                  height="15"
                  x="0.5"
                  y="0.5"
                  stroke={color}
                  mask="url(#mask-6)"
                  rx="2"
                ></rect>
              </g>
              <path stroke={color} d="M11.5 0L17.5 0 17.5 6"></path>
              <path stroke={color} d="M6 11.5L17.208.292"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default ExternalLink
