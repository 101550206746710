import React from 'react'
import Dropzone from 'react-dropzone'
import cx from 'classnames'
import { FormattedMessage } from 'react-intl'
import UploadSwitches from './UploadSwitches'
import GreenArrow from './icons/GreenArrow'

const UploaderDropzone = ({
  disabled = false,
  isPaidUser,
  model,
  onDrop,
  push,
  scale,
  setModel,
  setScale
}) => {
  return (
    <>
      <Dropzone
        disabled={disabled}
        onDrop={onDrop}
        multiple
        accept="image/jpg, image/jpeg, image/png, image/webp, image/bmp"
        maxFiles={50}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <div {...getRootProps()}>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <input {...getInputProps()} />
              <div
                className={cx({
                  uploader__dropzone: true,
                  'uploader__dropzone--is-uploading': false
                })}
              >
                <div>
                  <div className="uploader__info-text uploader__info-text--drag-your-image">
                    <FormattedMessage id="uploader.drag_your_images_here" />
                  </div>
                  <div className="button button--primary uploader__upload-button button--large">
                    <FormattedMessage id="uploader.upload_images" />
                  </div>
                  <div className="uploader__info-text">
                    <FormattedMessage id="uploader.jpn_png_tiff" />
                  </div>
                  <div className="uploader__info-text uploader__info-text--max-file-size">
                    {isPaidUser ? (
                      <FormattedMessage
                        id="uploader.max_file_size_paid"
                        values={{ sizeInMB: 20, sizeInPx: 8000 }}
                      />
                    ) : (
                      <FormattedMessage
                        id="uploader.max_file_size_free"
                        values={{ sizeInMB: 4, sizeInPx: 8000 }}
                      />
                    )}
                  </div>
                  <div className="uploader__smart-technology">
                    <FormattedMessage id="uploader.smart_technology_does_magic" />
                  </div>
                  <GreenArrow className="uploader__pointing-arrow" />
                </div>
              </div>
            </div>
          </section>
        )}
      </Dropzone>
      <UploadSwitches
        model={model}
        push={push}
        setModel={setModel}
        scale={scale}
        setScale={setScale}
      />
    </>
  )
}

export default UploaderDropzone
