import axios from 'axios'
import { push } from 'connected-react-router'
import { DirectUpload } from './directUpload'
import { generateRoute, getLanguage } from '../i18n/helpers'
import Consts from '../consts'
import { fetchUser } from './auth'
import { getFileType, snakeCase, validateImage } from '../helpers'
import { pushUploadFailedEvent, pushUpscaleEvent } from './crisp'

const DIRECT_UPLOAD_URL = '/api/v1/bulk_uploads/presigned_url'

class Upload {
  constructor({ file, url, onProgress, onStatusChange }) {
    this.directUpload = new DirectUpload(file, url, this)
    this.onProgress = onProgress
    this.onStatusChange = onStatusChange
  }

  start() {
    return new Promise((resolve, reject) => {
      this.directUpload.create((error, blob) => {
        if (error) {
          reject('invalid_file')
        } else {
          resolve(blob)
        }
      })
    })
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener('progress', ({ loaded, total }) => {
      const progress = Math.round((loaded / total) * 100)

      this.onStatusChange('uploading')
      this.onProgress(progress)
    })
  }
}

const getPreviewPath = (upscaleRequest) =>
  generateRoute(getLanguage(), 'routes.preview', {
    requestId: upscaleRequest.id
  })

const getReportPath = (upscaleRequest) =>
  generateRoute(getLanguage(), 'routes.report', {
    requestId: upscaleRequest.id
  })

const parseUploadErrors = (response) => {
  if (Array.isArray(response && response.data)) {
    return response.data.map((err) => snakeCase(err))
  }

  return ['original_image_is_not_a_valid_image']
}

export const checkProcessingStatus = (upscaleRequest) => (dispatch) => {
  dispatch({ type: Consts.UPSCALE_REQUEST_CHECK_STATUS_START })

  axios
    .get(`/api/v1/upscale_requests/${upscaleRequest.id}/status`)
    .then((response) => {
      dispatch({ type: Consts.UPSCALE_REQUEST_CHECK_STATUS_DONE })

      if (response.data.status === 'success') {
        pushUpscaleEvent(response.data, 'success')
        dispatch({ type: Consts.FILE_ENHANCE_DONE })
        dispatch(push(getPreviewPath(upscaleRequest)))
        dispatch(fetchUser())
      }

      if (response.data.status === 'failure') {
        pushUpscaleEvent(response.data, 'failure')
        dispatch({
          type: Consts.UPSCALE_REQUEST_CHECK_STATUS_ERROR,
          statusMessage: response.data.status_message
        })
        dispatch(push(getReportPath(upscaleRequest)))
      }
    })
    .catch(() => {
      dispatch({
        type: Consts.UPSCALE_REQUEST_CHECK_STATUS_ERROR,
        status_message: 'upscale_error'
      })
      dispatch(push(getReportPath(upscaleRequest)))
    })
}

export const getFingerprint = async () => {
  /*
   ** Conditionally load FingerPrintJS
   */
  const FingerprintJS = require('@fingerprintjs/fingerprintjs') // eslint-disable-line

  /*
   ** We recommend to call `load` at application startup.
   */
  const fp = await FingerprintJS.load()

  /*
   ** The FingerprintJS agent is ready.
   ** Get a visitor identifier when you'd like to.
   */
  const result = await fp.get()

  /*
   ** This is the visitor identifier:
   */
  return result.visitorId
}

export const uploadFile =
  (acceptedFiles, terms, privacy, scale, model, isPaidUser, turnstileToken) =>
  async (dispatch) => {
    const file = acceptedFiles[0]

    if (!file) return

    const validImage = await validateImage(file, {
      maxFileSize: (isPaidUser ? 20 : 4) * 1024 * 1024
    })

    if (!validImage) {
      dispatch({
        type: Consts.FILE_UPLOAD_ERROR,
        errors: ['original_image_is_too_big']
      })
      dispatch(
        push(
          `${generateRoute(
            getLanguage(),
            'routes.upload_failed'
          )}?reason=original_image_is_too_big`
        )
      )
      return
    }

    const formData = new FormData()
    const fingerprint = await getFingerprint()
    formData.append('upscale_request[original_image]', file, file.name)
    formData.append('upscale_request[terms_of_service_accepted]', terms)
    formData.append('upscale_request[privacy_policy_accepted]', privacy)
    formData.append('upscale_request[fingerprint]', fingerprint)
    formData.append('upscale_request[scale]', scale)
    formData.append('upscale_request[model_type]', model)
    formData.append('upscale_request[turnstile_token]', turnstileToken)

    dispatch({ type: Consts.FILE_UPLOAD_START, fileName: file.name })

    axios({
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
      url: '/api/v1/upscale_requests',
      onUploadProgress: (progressEvent) => {
        const progress = Math.ceil(
          (progressEvent.loaded / progressEvent.total) * 33
        )

        dispatch({
          type: Consts.FILE_UPLOAD_PROGRESS,
          progress: Math.round(progress)
        })
      }
    })
      .then((response) => {
        const upscaleRequest = response.data

        dispatch({
          type: Consts.FILE_UPLOAD_DONE,
          file: upscaleRequest
        })
      })
      .catch((error) => {
        const errors = parseUploadErrors(error.response)

        pushUploadFailedEvent(errors.join(' '))
        dispatch({ type: Consts.FILE_UPLOAD_ERROR, errors })
        dispatch(
          push(
            `${generateRoute(
              getLanguage(),
              'routes.upload_failed'
            )}?reason=${errors.join('_')}`
          )
        )
      })
  }

export const cancelUploading = () => (dispatch) => {
  dispatch({ type: Consts.FILE_UPLOAD_CANCEL })
}

export const fetchUpscaleRequest = (requestId) => (dispatch) => {
  dispatch({ type: Consts.UPSCALE_REQUEST_FETCH_START })

  axios
    .get(`/api/v1/upscale_requests/${requestId}`)
    .then((response) => {
      dispatch({
        type: Consts.UPSCALE_REQUEST_FETCH_DONE,
        request: response.data
      })
    })
    .catch(() => {
      dispatch({
        type: Consts.UPSCALE_REQUEST_FETCH_ERROR,
        errors: ['could_not_fetch_request']
      })
      dispatch(push(generateRoute(getLanguage(), 'routes.home')))
    })
}

export const createBulkUpload = (model, scale) => {
  const params = { bulk_upload: { model_type: model, scale } }

  return axios
    .post('/api/v1/bulk_uploads', params)
    .then((response) => response.data)
}

const updateBulkUpload = (uploadId, body) => (dispatch) => {
  dispatch({
    type: Consts.BULK_UPLOAD_SINGLE_UPLOAD_UPDATED,
    uploadId,
    body
  })
}

export const startFileUpload = (bulkUpload, file) => async (dispatch) => {
  const validImage = await validateImage(file, {
    maxFileSize: 20 * 1024 * 1024
  })
  const directUpload = new Upload({
    file,
    onProgress: (progress) =>
      dispatch(updateBulkUpload(file.name, { progress })),
    onStatusChange: (status) =>
      dispatch(updateBulkUpload(file.name, { status })),
    url: DIRECT_UPLOAD_URL
  })
  const upload = validImage
    ? {
        blobId: null,
        directUpload,
        filename: file.name,
        fileType: getFileType(file.name),
        id: file.name,
        previewUrl: window.URL.createObjectURL(file),
        size: file.size,
        status: 'upload_started',
        upscaleRequestId: null
      }
    : {
        blobId: null,
        directUpload: null,
        filename: file.name,
        fileType: getFileType(file.name),
        id: file.name,
        previewUrl: window.URL.createObjectURL(file),
        size: file.size,
        status: 'invalid_file',
        upscaleRequestId: null
      }

  dispatch({
    type: Consts.BULK_UPLOAD_SINGLE_UPLOAD_STARTED,
    upload
  })

  if (!validImage) return

  return upload.directUpload
    .start()
    .then((blob) => {
      dispatch(updateBulkUpload(upload.id, { status: 'uploaded' }))

      return axios.post(
        `/api/v1/bulk_uploads/${bulkUpload.id}/schedule_enhancing`,
        { upscale_request: { original_image: blob.signed_id } }
      )
    })
    .then((response) => {
      dispatch(
        updateBulkUpload(upload.id, {
          upscaleRequestId: response.data.id,
          status: 'before_processing'
        })
      )
      dispatch(fetchUser())
    })
    .catch((error) => {
      if (typeof error === 'string') {
        dispatch(updateBulkUpload(upload.id, { status: error }))
      } else if (error.response) {
        dispatch(
          updateBulkUpload(upload.id, {
            status: snakeCase(error.response.data[0])
          })
        )
      }
    })
}

export const startBulkUpload =
  (acceptedFiles, model, scale) => async (dispatch) => {
    dispatch({ type: Consts.BULK_UPLOAD_CREATE_START })

    const bulkUpload = await createBulkUpload(model, scale)

    dispatch({ type: Consts.BULK_UPLOAD_CREATE_DONE, bulkUpload })

    acceptedFiles.map((file) => dispatch(startFileUpload(bulkUpload, file)))
  }

export const checkBulkUploadProcessingStatus = (bulkUpload) => (dispatch) => {
  return axios.get(`/api/v1/bulk_uploads/${bulkUpload.id}`).then((response) => {
    dispatch({
      type: Consts.BULK_UPLOAD_STATUS_CHECKED,
      bulkUpload: response.data
    })
  })
}

export const cleanupUploadState = () => ({
  type: Consts.CLEANUP_UPLOAD_STATE
})
