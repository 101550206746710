export default {
  BULK_UPLOAD_SINGLE_UPLOAD_STARTED: 'BULK_UPLOAD_SINGLE_UPLOAD_STARTED',
  BULK_UPLOAD_SINGLE_UPLOAD_UPDATED: 'BULK_UPLOAD_SINGLE_UPLOAD_UPDATED',
  BULK_UPLOAD_CREATE_START: 'BULK_UPLOAD_CREATE_START',
  BULK_UPLOAD_CREATE_DONE: 'BULK_UPLOAD_CREATE_DONE',
  BULK_UPLOAD_CREATE_ERROR: 'BULK_UPLOAD_CREATE_ERROR',
  BULK_UPLOAD_STATUS_CHECKED: 'BULK_UPLOAD_STATUS_CHECKED',
  CLEANUP_UPLOAD_STATE: 'CLEANUP_UPLOAD_STATE',
  DOWNLOAD_SCHEDULE_START: 'DOWNLOAD_SCHEDULE_START',
  DOWNLOAD_SCHEDULE_DONE: 'DOWNLOAD_SCHEDULE_DONE',
  DOWNLOAD_SCHEDULE_ERROR: 'DOWNLOAD_SCHEDULE_ERROR',
  DOWNLOAD_CHECK_STATUS_START: 'DOWNLOAD_CHECK_STATUS_START',
  DOWNLOAD_CHECK_STATUS_DONE: 'DOWNLOAD_CHECK_STATUS_DONE',
  DOWNLOAD_CHECK_STATUS_ERROR: 'DOWNLOAD_CHECK_STATUS_ERROR',
  DOWNLOAD_READY: 'DOWNLOAD_READY',
  FILE_UPLOAD_START: 'FILE_UPLOAD_START',
  FILE_UPLOAD_DONE: 'FILE_UPLOAD_DONE',
  FILE_UPLOAD_PROGRESS: 'FILE_UPLOAD_PROGRESS',
  FILE_UPLOAD_ERROR: 'FILE_UPLOAD_ERROR',
  FILE_ENHANCE_DONE: 'FILE_ENHANCE_DONE',
  FILE_UPLOAD_CANCEL: 'FILE_UPLOAD_CANCEL',
  UPSCALE_REQUEST_FETCH_START: 'UPSCALE_REQUEST_FETCH_START',
  UPSCALE_REQUEST_FETCH_DONE: 'UPSCALE_REQUEST_FETCH_DONE',
  UPSCALE_REQUEST_FETCH_ERROR: 'UPSCALE_REQUEST_FETCH_ERROR',
  UPSCALE_REQUEST_SCHEDULE_ENHANCING_START:
    'UPSCALE_REQUEST_SCHEDULE_ENHANCING_START',
  UPSCALE_REQUEST_SCHEDULE_ENHANCING_DONE:
    'UPSCALE_REQUEST_SCHEDULE_ENHANCING_DONE',
  UPSCALE_REQUEST_SCHEDULE_ENHANCING_ERROR:
    'UPSCALE_REQUEST_SCHEDULE_ENHANCING_ERROR',
  UPSCALE_REQUEST_REPORT_START: 'UPSCALE_REQUEST_REPORT_START',
  UPSCALE_REQUEST_REPORT_DONE: 'UPSCALE_REQUEST_REPORT_DONE',
  UPSCALE_REQUEST_REPORT_ERROR: 'UPSCALE_REQUEST_REPORT_ERROR',
  UPSCALE_REQUEST_CHECK_STATUS_START: 'UPSCALE_REQUEST_CHECK_STATUS_START',
  UPSCALE_REQUEST_CHECK_STATUS_DONE: 'UPSCALE_REQUEST_CHECK_STATUS_DONE',
  UPSCALE_REQUEST_CHECK_STATUS_ERROR: 'UPSCALE_REQUEST_CHECK_STATUS_ERROR',
  USER_SIGN_UP_START: 'USER_SIGN_UP_START',
  USER_SIGN_UP_DONE: 'USER_SIGN_UP_DONE',
  USER_SIGN_UP_ERROR: 'USER_SIGN_UP_ERROR',
  USER_VERIFY_LOGIN_CODE_START: 'USER_VERIFY_LOGIN_CODE_START',
  USER_VERIFY_LOGIN_CODE_DONE: 'USER_VERIFY_LOGIN_CODE_DONE',
  USER_VERIFY_LOGIN_CODE_ERROR: 'USER_VERIFY_LOGIN_CODE_ERROR',
  USER_FETCH_START: 'USER_FETCH_START',
  USER_FETCH_DONE: 'USER_FETCH_DONE',
  USER_FETCH_ERROR: 'USER_FETCH_ERROR',
  USER_LOGOUT_START: 'USER_LOGOUT_START',
  USER_LOGOUT_DONE: 'USER_LOGOUT_DONE',
  USER_LOGOUT_ERROR: 'USER_LOGOUT_ERROR',
  USER_PURCHASED_CREDITS: 'USER_PURCHASED_CREDITS'
}
