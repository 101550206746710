import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { FormattedMessage, useIntl } from 'react-intl'
import LanguageSwitcher from '../i18n/components/LanguageSwitcher'
import { generateRoute } from '../i18n/helpers'
import LanguageSwitcherMobile from '../i18n/components/LanguageSwitcherMobile'

const Footer = ({ pushUrl }) => {
  const intl = useIntl()

  return (
    <div className="footer">
      <LanguageSwitcherMobile />
      <div className="footer__copyright-container">
        <FormattedMessage
          id="footer.copyright"
          values={{ year: new Date().getFullYear() }}
        />
        <span>Stockphotos.com</span>
      </div>
      <div className="footer__menu">
        <div
          className="footer__menu-item"
          onClick={() => pushUrl(generateRoute(intl.locale, 'routes.pricing'))}
          role="presentation"
        >
          <FormattedMessage id="footer.pricing" />
        </div>
        <div
          className="footer__menu-item"
          onClick={() => pushUrl(generateRoute(intl.locale, 'routes.privacy'))}
          role="presentation"
        >
          <FormattedMessage id="footer.privacy_policy" />
        </div>
        <div
          className="footer__menu-item"
          onClick={() => pushUrl(generateRoute(intl.locale, 'routes.terms'))}
          role="presentation"
        >
          <FormattedMessage id="footer.terms_of_use" />
        </div>
        <a
          className="footer__menu-item"
          href="https://stockphotos.firstpromoter.com/"
          target="_blank"
          rel="nofollow"
        >
          <FormattedMessage id="footer.join_affiliate" />
        </a>
        <div
          className="footer__menu-item"
          onClick={() => pushUrl(generateRoute(intl.locale, 'routes.api'))}
          role="presentation"
        >
          <FormattedMessage id="footer.api" />
        </div>
        {false && (
          <div
            className="footer__menu-item"
            onClick={() => pushUrl(generateRoute(intl.locale, 'routes.help'))}
            role="presentation"
          >
            <FormattedMessage id="footer.help" />
          </div>
        )}
        <div
          className="footer__menu-item"
          onClick={() => pushUrl(generateRoute(intl.locale, 'routes.contact'))}
          role="presentation"
        >
          <FormattedMessage id="footer.contact" />
        </div>
      </div>
      <LanguageSwitcher />
    </div>
  )
}

Footer.propTypes = {
  pushUrl: PropTypes.func.isRequired
}

export default connect(null, { pushUrl: push })(Footer)
