import React from 'react'

const Ok = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="72"
    height="72"
    viewBox="0 0 72 72"
    {...props}
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-924 -390)">
        <g transform="translate(924 390)">
          <circle
            cx="36"
            cy="36"
            r="35.5"
            fill="#FFF"
            stroke="#00B557"
          ></circle>
          <path
            fill="#00B557"
            d="M32.973 41.802L50.775 24l2.829 2.829L35.802 44.63l-2.83 2.829L20 34.487l2.829-2.829 10.144 10.144z"
          ></path>
        </g>
      </g>
    </g>
  </svg>
)

export default Ok
