import React, { useEffect, useState } from 'react'

export const ClientOnly = ({ children, ...props }) => {
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  if (!isMounted) {
    return null
  }

  return <div {...props}>{children}</div>
}
