import Consts from '../consts'

const initialState = {
  bulkUpload: null,
  bulkUploadCompleted: false,
  bulkUploadFiles: [],
  currentFile: null,
  fileName: '',
  isBulkProcessing: false,
  isBulkUploading: false,
  isUploading: false,
  errors: [],
  progress: 0,
  statusMessage: '',
  uploadType: 'single'
}

export const undoneStatuses = ['before_processing', 'created', 'processing']
export const doneStatuses = [
  'invalid_file',
  'success',
  'failure',
  'limit_exhausted'
]
const uploadingStatuses = ['upload_started', 'uploading', 'uploaded']

const getRandomArbitrary = (min, max) =>
  Math.floor(Math.random() * (max - min) + min)

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case Consts.FILE_UPLOAD_START: {
      return {
        ...state,
        isUploading: true,
        errors: [],
        progress: 0,
        currentFile: null,
        fileName: action.fileName,
        uploadType: 'single'
      }
    }
    case Consts.FILE_UPLOAD_PROGRESS: {
      return { ...state, progress: action.progress }
    }
    case Consts.FILE_UPLOAD_DONE: {
      return { ...state, currentFile: action.file }
    }
    case Consts.FILE_UPLOAD_ERROR: {
      return { ...state, isUploading: false, errors: action.errors }
    }
    case Consts.UPSCALE_REQUEST_CHECK_STATUS_DONE: {
      const randomProgress = getRandomArbitrary(5, 10)

      return {
        ...state,
        progress: Math.min(99, state.progress + randomProgress)
      }
    }
    case Consts.UPSCALE_REQUEST_CHECK_STATUS_ERROR: {
      return { ...state, isUploading: false }
    }
    case Consts.FILE_ENHANCE_DONE: {
      return { ...state, isUploading: false }
    }
    case Consts.FILE_UPLOAD_CANCEL: {
      return { ...state, isUploading: false, currentFile: null }
    }
    case Consts.BULK_UPLOAD_CREATE_START: {
      return {
        ...state,
        bulkUploadCompleted: false,
        isBulkUploading: true,
        isUploading: true,
        uploadType: 'bulk'
      }
    }
    case Consts.BULK_UPLOAD_CREATE_DONE: {
      return { ...state, bulkUpload: action.bulkUpload }
    }
    case Consts.BULK_UPLOAD_SINGLE_UPLOAD_STARTED: {
      return {
        ...state,
        bulkUploadFiles: [...state.bulkUploadFiles, action.upload]
      }
    }
    case Consts.BULK_UPLOAD_SINGLE_UPLOAD_UPDATED: {
      const bulkUploadFiles = state.bulkUploadFiles.map((u) => {
        if (u.id === action.uploadId) {
          return { ...u, ...action.body }
        }

        return u
      })
      const unprocessedFiles = bulkUploadFiles.filter((u) =>
        undoneStatuses.includes(u.status)
      )
      const isBulkProcessing = unprocessedFiles.length > 0

      return { ...state, bulkUploadFiles, isBulkProcessing }
    }
    case Consts.BULK_UPLOAD_STATUS_CHECKED: {
      const bulkUpload = action.bulkUpload
      const bulkUploadFiles = state.bulkUploadFiles.map((u) => {
        const upscaleRequest =
          bulkUpload.upscale_requests.find(
            (upscaleRequest) => upscaleRequest.id === u.upscaleRequestId
          ) || {}

        return { ...u, ...upscaleRequest }
      })
      const unprocessedFiles = bulkUploadFiles.filter((u) =>
        undoneStatuses.includes(u.status)
      )
      const isBulkProcessing = unprocessedFiles.length > 0
      const filesNotUploaded = bulkUploadFiles.filter((u) =>
        uploadingStatuses.includes(u.status)
      )
      const isBulkUploading = filesNotUploaded.length > 0
      const bulkUploadCompleted = !isBulkUploading

      return {
        ...state,
        bulkUpload: { ...state.bulkUpload, status: bulkUpload.status },
        bulkUploadCompleted,
        bulkUploadFiles,
        isBulkProcessing,
        isBulkUploading
      }
    }
    case Consts.CLEANUP_UPLOAD_STATE: {
      return initialState
    }
    default:
      return state
  }
}
