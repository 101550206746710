import React, { useState, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import cx from 'classnames'
import axios from 'axios'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Message from '../components/Message'
import Checkbox from '../components/Checkbox'
import { fetchUser } from '../actions/auth'
import { validateEmail, snakeCase } from '../helpers'
import { generateRoute } from '../i18n/helpers'
import { pushTransactionEvent } from '../actions/crisp'

const Checkout = (props) => {
  const intl = useIntl()
  const [step, setStep] = useState('step-zero')
  const [email, setEmail] = useState('')
  const [loginCode, setLoginCode] = useState('')
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false)
  const [errors, setErrors] = useState([])
  const dispatch = useDispatch()
  const userAuthenticated = useSelector((state) => state.user.userAuthenticated)
  const userEmail = useSelector((state) => state.user.userEmail)
  const userLoaded = useSelector((state) => state.user.userLoaded)
  const { productId } = props.match.params
  const locale = intl.locale === 'cs' ? 'en' : intl.locale
  const formIsValid =
    termsAccepted && privacyPolicyAccepted && validateEmail(email)
  const [product, setProduct] = useState(null)

  const validateUser = ({ onSuccess }) => {
    axios
      .post('/users/validate', {
        user: {
          email,
          terms_of_service_accepted: termsAccepted,
          privacy_policy_accepted: privacyPolicyAccepted,
          locale: intl.locale
        }
      })
      .then(onSuccess)
      .catch((error) => {
        setErrors(error.response.data)
      })
  }

  const signUp = () => {
    axios
      .post('/users/sign_up', {
        user: {
          email,
          terms_of_service_accepted: true,
          privacy_policy_accepted: true,
          checkout: true,
          locale: intl.locale
        }
      })
      .then(() => {
        setStep('step-three')
      })
  }

  const validateLoginCode = () => {
    axios
      .post('/magic_links/sign_in_with_temporary_password', {
        magic_link: {
          email,
          temporary_password: loginCode
        }
      })
      .then(() => {
        dispatch(push(generateRoute(intl.locale, 'routes.checkout_thank_you')))
        dispatch(fetchUser())
      })
      .catch((error) => {
        setErrors(error.response.data)
      })
  }

  const openCheckout = () => {
    const Paddle = typeof window !== 'undefined' ? window.Paddle : null

    if (Paddle) {
      Paddle.Checkout.open({
        email,
        successCallback: (data) => {
          pushTransactionEvent(data)

          window.fpr('referral', { email })

          if (userLoaded && userAuthenticated) {
            dispatch(fetchUser())
            dispatch(
              push(generateRoute(intl.locale, 'routes.checkout_thank_you'))
            )
          } else {
            signUp()
          }
        },
        frameTarget: 'checkout-container',
        frameInitialHeight: 416,
        frameStyle:
          'width: 100%; max-width:495px; background-color: transparent; border: none; margin: 0 auto;',
        locale,
        method: 'inline',
        passthrough: JSON.stringify({
          locale: intl.locale
        }),
        product: productId
      })
    }
  }

  const renderProductInfo = () => (
    <div className="file-report__product">
      <div className="file-report__product-trait">
        <div className="file-report__product-trait-name">
          <FormattedMessage id="checkout.upscales" />
        </div>
        <div className="file-report__product-trait-value file-report__product-trait-value--bold">
          {product.extra_upscales ? (
            <span>
              {product.upscale_credits * 0.5}<span style={{ color: '#ed7b0b' }}>+{product.upscale_credits * 0.5}</span>
            </span>
          ) : (
            product.upscale_credits
          )}
        </div>
      </div>
      {false && (
        <div className="file-report__product-trait">
          <div className="file-report__product-trait-name">
            <FormattedMessage id="checkout.price_per_upscale" />
          </div>
          <div className="file-report__product-trait-value">
            {product.pricePerUpscale}
          </div>
        </div>
      )}
      <div className="file-report__product-trait">
        <div className="file-report__product-trait-name">
          <FormattedMessage id="checkout.total" />
        </div>
        <div className="file-report__product-trait-value">${product.price}</div>
      </div>
    </div>
  )

  const renderStepOne = () => (
    <div>
      {product && renderProductInfo()}
      <div className="file-report__inline-row m-b-30">
        <div className="file-report__inline-row">
          <div className="icon icon--payments m-r-10" />
        </div>
        <div className="pricing-plans__vat">
          <FormattedMessage id="pricing.plans.plus_vat" />
        </div>
      </div>

      <div className="file-report__so-happy">
        <FormattedMessage id="checkout.enter_your_email" />
      </div>
      <div className="file-report__input-container">
        {/* eslint-disable-next-line */}
        <label className="file-report__input-label" htmlFor="Email">
          E-mail
        </label>
        <input
          className={cx({
            'file-report__input': true,
            'file-report__input--errors': errors.length > 0
          })}
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          placeholder={intl.formatMessage({
            id: 'report.enter_your_email'
          })}
          id="Email"
        />
        {errors.map((error) => (
          <div className="file-report__error" key={error}>
            <FormattedMessage id={`checkout.errors.${snakeCase(error)}`} />
          </div>
        ))}
      </div>
      <div className="file-report__terms">
        <Checkbox
          name="terms-accepted"
          checked={termsAccepted}
          onChange={(event) => setTermsAccepted(event.target.checked)}
          text={
            <span
              className="regular"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: 'report.agree_with_terms'
                })
              }}
            />
          }
        />
      </div>
      <div className="file-report__terms">
        <Checkbox
          name="privacy-accepted"
          checked={privacyPolicyAccepted}
          onChange={(event) => setPrivacyPolicyAccepted(event.target.checked)}
          text={
            <span
              className="regular"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: 'report.agree_with_privacy'
                })
              }}
            />
          }
        />
      </div>
      <div className="file-report__get-help">
        <div
          className={cx({
            button: true,
            'button--primary': true,
            'button--large': true,
            'button--disabled': !formIsValid
          })}
          onClick={() => {
            if (formIsValid) {
              validateUser({ onSuccess: () => setStep('step-two') })
            }
          }}
          role="presentation"
        >
          <FormattedMessage id="checkout.continue" />
        </div>
      </div>
    </div>
  )

  const renderStepThree = () => (
    <div>
      <div className="file-report__envelope">
        <div className="icon icon--envelope" />
      </div>
      <div
        className="file-report__check-inbox"
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({ id: 'magic_login.check_your_inbox' })
        }}
      />
      <div
        className="file-report__check-inbox"
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({
            id: 'magic_login.check_spam_folder'
          })
        }}
      />
      <div className="file-report__input-container">
        {/* eslint-disable-next-line */}
        <label className="file-report__input-label" htmlFor="Login code">
          <FormattedMessage id="checkout.login_code" />
        </label>
        <input
          className={cx({
            'file-report__input': true,
            'file-report__input--errors': errors.length > 0
          })}
          value={loginCode}
          onChange={(event) => {
            setLoginCode(event.target.value)
            setErrors([])
          }}
          placeholder={intl.formatMessage({ id: 'checkout.paste_login_code' })}
          id="Login code"
        />
        {errors.map((error) => (
          <div className="file-report__error" key={error}>
            <FormattedMessage id={`checkout.errors.${snakeCase(error)}`} />
          </div>
        ))}
      </div>
      <div className="file-report__get-help">
        <div
          className={cx({
            button: true,
            'button--primary': true,
            'button--large': true,
            'button--disabled': !loginCode
          })}
          onClick={() => {
            if (loginCode) {
              validateLoginCode()
            }
          }}
          role="presentation"
        >
          <FormattedMessage id="checkout.finish" />
        </div>
      </div>
    </div>
  )

  useEffect(() => {
    const fetchProduct = async () => {
      axios
        .get(`/api/v1/products/${productId}`)
        .then((response) => {
          setProduct(response.data)
        })
        .catch(() => {
          dispatch(push(generateRoute(intl.locale, 'routes.home')))
        });
    }

    fetchProduct()
  }, [])

  useEffect(() => {
    if (userLoaded && !userAuthenticated) {
      setStep('step-one')
    } else if (userLoaded && userAuthenticated) {
      setEmail(userEmail)
      setStep('step-two')
    }
  }, [userLoaded, userAuthenticated])

  useEffect(() => {
    if (step === 'step-two') {
      openCheckout()
    }
  }, [step])

  return (
    <div className="oval-glow">
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-md-2" />
          <div className="col-md-8">
            <div className="article">
              {step === 'step-one' && (
                <Message
                  center
                  text={<FormattedMessage id="checkout.confirm_payment" />}
                />
              )}
              {step === 'step-two' && (
                <Message
                  center
                  text={<FormattedMessage id="checkout.confirm_payment" />}
                />
              )}
              {step === 'step-three' && (
                <Message
                  center
                  text={<FormattedMessage id="checkout.insert_magic_code" />}
                />
              )}

              <div className="file-report">
                <div className="file-report__stripe-blue" />
                {step === 'step-one' && renderStepOne()}
                {step === 'step-two' && (
                  <div style={{ width: '100%', maxWidth: 456 }}>
                    {product && renderProductInfo()}
                    <div className="checkout-container" />
                  </div>
                )}
                {step === 'step-three' && renderStepThree()}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Checkout
