import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { FormattedMessage, useIntl } from 'react-intl'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Message from '../components/Message'
import { generateRoute } from '../i18n/helpers'
import { getRootUrl } from '../helpers'
import CanWeDoBetter from '../components/CanWeDoBetter'
import CartoonizeLogo from '../components/icons/CartoonizeLogo'
import { fetchUpscaleRequest } from '../actions/upload'
import Placement from '../components/Placement'
import { VismeLogo } from '../components/VismeLogo'

export const downloadFile = (file, callback) => {
  const link = document.createElement('a')
  link.download = true
  link.href = file

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)

  if (typeof callback === 'function') {
    callback()
  }
}

const scrollTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
}

const ThankYou = (props) => {
  const [downloadStarted, setDownloadStarted] = useState(false)
  const {
    downloadUrl,
    fetchUpscaleRequestCb,
    leftBoxPlacement,
    match,
    pushUrl,
    rightBoxPlacement,
    upscaleRequest,
    userLoaded,
    userStatus
  } = props
  const { requestId } = match.params
  const intl = useIntl()
  const isPaidUser = userLoaded && ['standard', 'premium'].includes(userStatus)

  useEffect(() => {
    fetchUpscaleRequestCb(requestId)
  }, [requestId])

  if (isPaidUser) {
    return (
      <div className="good-glow">
        <Header />
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {downloadUrl && (
                <div className="center m-b-30">
                  <Message
                    center
                    text={
                      <span
                        dangerouslySetInnerHTML={{
                          __html: intl.formatMessage(
                            {
                              id: 'thank_you.download_has_started_paid_user'
                            },
                            {
                              downloadUrl: `<a href="${downloadUrl}" download>${intl.formatMessage(
                                { id: 'thank_you.click_here' }
                              )}</a>`
                            }
                          )
                        }}
                      />
                    }
                    style={{ marginBottom: 80 }}
                  />
                </div>
              )}
              <CanWeDoBetter />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }

  return (
    <div className="good-glow">
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <Message
              center
              text={
                downloadStarted ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage(
                        {
                          id: 'thank_you.download_has_started'
                        },
                        {
                          downloadUrl: `<a href="${downloadUrl}" download>${intl.formatMessage(
                            { id: 'thank_you.click_here' }
                          )}</a>`
                        }
                      )
                    }}
                  />
                ) : (
                  <FormattedMessage id="thank_you.awesome_discounts" />
                )
              }
              style={{ marginBottom: 80 }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-2" />
          <div className="col-md-4">
            <Placement className="banner" html={leftBoxPlacement} />
          </div>
          <div className="col-md-4">
            <Placement className="banner" html={rightBoxPlacement} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="image-preview__download-buttons">
              {downloadUrl && !downloadStarted && (
                <div
                  className="button button--primary button--large"
                  onClick={() => {
                    downloadFile(downloadUrl, () => setDownloadStarted(true))
                    scrollTop()
                  }}
                  role="presentation"
                >
                  <FormattedMessage id="thank_you.go_to_download" />
                </div>
              )}
              {downloadStarted && (
                <div
                  className="button button--primary button--large"
                  onClick={() => {
                    pushUrl(generateRoute(intl.locale, 'routes.home'))
                  }}
                  role="presentation"
                >
                  <FormattedMessage id="thank_you.convert_another" />
                </div>
              )}
            </div>
            <CanWeDoBetter />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

const mapStateToProps = (state) => ({
  isFetching: state.editor.isFetching,
  downloadUrl: state.editor.downloadUrl,
  leftBoxPlacement: state.settings.placements.leftBoxThankYou,
  rightBoxPlacement: state.settings.placements.rightBoxThankYou,
  upscaleRequest: state.editor.currentRequest,
  userLoaded: state.user.userLoaded,
  userStatus: state.user.userStatus
})

const mapDispatchToProps = (dispatch) => ({
  fetchUpscaleRequestCb: (requestId) => {
    dispatch(fetchUpscaleRequest(requestId))
  },
  pushUrl: (args) => {
    dispatch(push(args))
  }
})

ThankYou.propTypes = {
  downloadUrl: PropTypes.string,
  location: PropTypes.shape({}).isRequired,
  pushUrl: PropTypes.func.isRequired
}

ThankYou.defaultProps = {
  downloadUrl: null
}

export default connect(mapStateToProps, mapDispatchToProps)(ThankYou)
