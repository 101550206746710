import React from 'react'

const Download = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    {...props}
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="currentColor" transform="translate(-727 -1001)">
        <g transform="translate(695 980)">
          <g transform="translate(32 21)">
            <path
              d="M-0.211 9.096H9.789V10.096H-0.211z"
              transform="rotate(45 4.79 9.596)"
            ></path>
            <path
              d="M7.189 9.096H17.189V10.096H7.189z"
              transform="scale(-1 1) rotate(45 0 -19.83)"
            ></path>
            <path d="M8 0H9V13.5H8z"></path>
            <path d="M0 16H17V17H0z"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Download
