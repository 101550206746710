import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import Header from '../components/Header'
import ImagePreview from '../components/ImagePreview'
import Footer from '../components/Footer'
import ExclusiveDealDialog from '../components/ExclusiveDealDialog'
import { fetchUpscaleRequest } from '../actions/upload'
import { scheduleEnhancing } from '../actions/editor'
import { startImageDownload } from '../actions/download'
import { UpscaleRequestType } from '../proptypes'

const Preview = ({
  fetchUpscaleRequestCb,
  isFetching,
  match,
  mobileUpscalePlacement,
  pushUrl,
  remainingUpscales,
  scheduleEnhancingCb,
  startDownloadCb,
  upscaleRequest,
  userLoaded,
  userStatus
}) => {
  const { requestId } = match.params
  const shouldDisplayAd = userLoaded && userStatus === 'free'

  useEffect(() => {
    fetchUpscaleRequestCb(requestId)
  }, [requestId])

  return (
    <div className="oval-glow">
      <Header />
      {!isFetching && upscaleRequest && (
        <ImagePreview
          mobileUpscalePlacement={mobileUpscalePlacement}
          push={pushUrl}
          remainingUpscales={remainingUpscales}
          scheduleEnhancing={scheduleEnhancingCb}
          shouldDisplayAd={shouldDisplayAd}
          startDownload={startDownloadCb}
          upscaleRequest={upscaleRequest}
        />
      )}
      {false && shouldDisplayAd && <ExclusiveDealDialog />}
      <Footer />
    </div>
  )
}

const mapStateToProps = (state) => ({
  isFetching: state.editor.isFetching,
  mobileUpscalePlacement: state.settings.placements.mobileUpscale,
  remainingUpscales: state.user.userRemainingUpscales,
  upscaleRequest: state.editor.currentRequest,
  userLoaded: state.user.userLoaded,
  userStatus: state.user.userStatus
})

const mapDispatchToProps = (dispatch) => ({
  fetchUpscaleRequestCb: (requestId) => {
    dispatch(fetchUpscaleRequest(requestId))
  },
  pushUrl: (args) => {
    dispatch(push(args))
  },
  scheduleEnhancingCb: (upscaleRequest, compression, scale, model) => {
    dispatch(scheduleEnhancing(upscaleRequest, compression, scale, model))
  },
  startDownloadCb: (upscaleRequest) => {
    dispatch(startImageDownload(upscaleRequest))
  }
})

Preview.propTypes = {
  fetchUpscaleRequestCb: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      requestId: PropTypes.string
    })
  }).isRequired,
  mobileUpscalePlacement: PropTypes.string,
  pushUrl: PropTypes.func.isRequired,
  scheduleEnhancingCb: PropTypes.func.isRequired,
  startDownloadCb: PropTypes.func.isRequired,
  upscaleRequest: UpscaleRequestType
}

Preview.defaultProps = {
  upscaleRequest: null
}

export default connect(mapStateToProps, mapDispatchToProps)(Preview)
