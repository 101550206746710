export const AppLanguage = {
  Czech: 'cs',
  Dutch: 'nl',
  English: 'en',
  German: 'de',
  Portuguese: 'pt',
  Spanish: 'es'
}

export const LanguageName = {
  cs: 'Český',
  de: 'Deutsch',
  en: 'English',
  es: 'Español',
  nl: 'Nederlands',
  pt: 'Portugues'
}

export const AppRoute = {
  Home: 'routes.home',
  Preview: 'routes.preview',
  Export: 'routes.export',
  ThankYou: 'routes.thank_you',
  Process: 'routes.process',
  Report: 'routes.report',
  UploadFailed: 'routes.upload_failed',
  Help: 'routes.help',
  Login: 'routes.login',
  Relogin: 'routes.relogin',
  Signup: 'routes.signup',
  TermsOfService: 'routes.terms',
  PrivacyPolicy: 'routes.privacy',
  Contact: 'routes.contact',
  Pricing: 'routes.pricing',
  Checkout: 'routes.checkout',
  CheckoutThankYou: 'routes.checkout_thank_you',
  Account: 'routes.account',
  Receipts: 'routes.receipts',
  Promo: 'routes.promo',
  BulkUpload: 'routes.bulk_upload',
  BulkUploadPreview: 'routes.bulk_upload_preview',
  Upgrade: 'routes.upgrade',
  Api: 'routes.api'
}
