import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { FormattedMessage, useIntl } from 'react-intl'
import LoadingSpider from './icons/LoadingSpider'
import ZeroesAndOnes from './ZeroesAndOnes'
import Features from './Features'
import { UpscaleRequestType } from '../proptypes'
import { generateRoute } from '../i18n/helpers'

const getRandomArbitrary = (min, max) =>
  Math.floor(Math.random() * (max - min) + min)

const Processing = ({ checkProcessingStatus, push, upscaleRequest }) => {
  const intl = useIntl()
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      const randomProgress = getRandomArbitrary(7, 12)

      checkProcessingStatus(upscaleRequest)
      setProgress((oldProgress) => Math.min(99, oldProgress + randomProgress))
    }, 2000)
    return () => clearInterval(interval)
  }, [])

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-1" />
        <div className="col-md-10">
          {upscaleRequest && (
            <div className="uploader">
              <div className="uploader__status uploader__status--pulse">
                <span>
                  <FormattedMessage id="process.uploading_and_enhancing" />
                </span>
              </div>
              <div
                className={cx({
                  uploader__dropzone: true,
                  'uploader__dropzone--is-uploading': true
                })}
              >
                <LoadingSpider className="uploader__loading-spider" />
                <div className="uploader__zeroes-and-ones">
                  <ZeroesAndOnes />
                </div>
                <div style={{ width: '100%', padding: 30, zIndex: 8 }}>
                  <div
                    className="uploader__info-text"
                    style={{ marginBottom: 330, marginTop: 60 }}
                  >
                    <FormattedMessage
                      id="process.file_is_being_processed"
                      values={{
                        file: upscaleRequest.file_name
                      }}
                    />
                  </div>

                  <div style={{ zIndex: 1 }}>
                    <div
                      className="button button--secondary uploader__cancel-button"
                      onClick={() =>
                        push(generateRoute(intl.locale, 'routes.home'))
                      }
                      style={{ marginRight: 20 }}
                      role="presentation"
                    >
                      <FormattedMessage id="process.cancel" />
                    </div>
                  </div>

                  <div className="uploader__progress-bar-container">
                    <div
                      className="uploader__progress-bar"
                      style={{ width: `${progress}%` }}
                    />
                    <div
                      className="uploader__progress"
                      style={{ left: `calc(${progress}% - 20px)` }}
                    >
                      {`${progress}%`}
                    </div>
                  </div>
                </div>
              </div>
              <div className="uploader__by-stock-photos">
                by Stockphotos.com
              </div>
            </div>
          )}

          <Features />
        </div>
      </div>
    </div>
  )
}

Processing.propTypes = {
  checkProcessingStatus: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  upscaleRequest: UpscaleRequestType.isRequired
}

export default Processing
