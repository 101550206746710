import mime from 'mime-types'

export const validateEmail = (email) => {
  /* eslint-disable-next-line no-useless-escape */
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return re.test(String(email).toLowerCase())
}

export const snakeCase = (string) => {
  const parts = string.replace(/\W+/g, ' ').split(/ |\B(?=[A-Z])/) || []

  return parts.map((word) => word.toLowerCase()).join('_')
}

const getExtensionFromFileUrl = (fileUrl) => {
  if (!fileUrl) return ''

  const extension = fileUrl.split('.').pop()

  return extension || ''
}

export const getFileType = (fileUrl) => {
  const fileType = mime.lookup(fileUrl)
  const extension = mime.extension(fileUrl)

  if (fileType) {
    return mime.extension(fileType)
  } else if (extension) {
    return extension
  }

  return getExtensionFromFileUrl(fileUrl)
}

export const getRootUrl = () => {
  const location = window.location

  return (
    location.protocol +
    '//' +
    location.hostname +
    (location.port ? ':' + location.port : '')
  )
}

export const humanFileSize = (bytes, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B'
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let u = -1
  const r = 10 ** dp

  do {
    bytes /= thresh
    ++u
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  )

  return bytes.toFixed(dp) + ' ' + units[u]
}

export const countOccurrences = (array) =>
  array.reduce((counts, currentElement) => {
    if (typeof counts[currentElement] == 'undefined') {
      counts[currentElement] = 1
    } else {
      counts[currentElement] += 1
    }

    return counts
  }, {})

const MAX_DIMENSION = 8000 // Default max dimension
const MAX_SIZE = 4 * 1024 * 1024 // Default max size (4MB)

export const validateImage = (
  file,
  { maxDimension = MAX_DIMENSION, maxFileSize = MAX_SIZE } = {}
) =>
  new Promise((resolve) => {
    // Check file size
    if (file.size > maxFileSize) {
      resolve(false)
      return
    }

    const reader = new FileReader()

    reader.onload = () => {
      const img = new Image()

      img.src = reader.result

      img.onload = () => {
        if (img.width > maxDimension || img.height > maxDimension) {
          resolve(false)
        } else {
          resolve(true)
        }
      }
    }

    reader.readAsDataURL(file)
  })
