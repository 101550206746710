import React from 'react'
import PropTypes from 'prop-types'
import InnerHTML from 'dangerously-set-html-content'

const Placement = ({ className = '', html }) => (
  <div className={className}>
    <InnerHTML html={html} />
  </div>
)

Placement.propTypes = {
  className: PropTypes.string,
  html: PropTypes.string
}

export default Placement
