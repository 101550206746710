import React from 'react'
import PropTypes from 'prop-types'
import { Switch } from 'react-router'
import { useIntl } from 'react-intl'

export const LocalizedSwitch = ({ baseLocale, children }) => {
  /**
   * inject params and formatMessage through hooks, so we can localize the route
   */
  const { formatMessage, locale } = useIntl()
  const localeParam = baseLocale === locale ? '' : `/${locale}`

  /**
   *
   * @param path can be string, undefined or string array
   * @returns Localized string path or path array
   */
  const localizeRoutePath = (path) => {
    switch (typeof path) {
      case 'undefined':
        return undefined
      case 'object':
        return path.map((key) => `${localeParam}${formatMessage({ id: key })}`)
      default: {
        const isFallbackRoute = path === '*'

        return isFallbackRoute
          ? path
          : `${localeParam}${formatMessage({ id: path })}`
      }
    }
  }

  /**
   * Apply localization to all routes
   * Also checks if all children elements are <Route /> components
   */
  return (
    <Switch>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            ...child.props,
            path: localizeRoutePath(child.props.path)
          })
        }

        return child
      })}
    </Switch>
  )
}

LocalizedSwitch.propTypes = {
  baseLocale: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}
