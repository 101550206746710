import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const Message = ({ center, failure, icon, text, style }) => (
  <div
    className={cx({
      message: true,
      'message--failure': failure,
      'message--center': center
    })}
    style={style}
  >
    {icon}
    {text}
  </div>
)

Message.propTypes = {
  center: PropTypes.bool,
  failure: PropTypes.bool,
  icon: PropTypes.node,
  style: PropTypes.shape({}),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
}

Message.defaultProps = {
  center: false,
  failure: false,
  icon: null,
  style: {}
}

export default Message
