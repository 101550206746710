import React from 'react'

const BackBlue = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="11"
    viewBox="0 0 21 11"
    {...props}
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g className="svg-stroke" transform="translate(-464 -325)">
        <g transform="translate(465 324.95)">
          <path
            d="M1.44974747 2L1.44974747 9 8.44974747 9"
            transform="rotate(45 4.95 5.5)"
          ></path>
          <path d="M0 5.55h20"></path>
        </g>
      </g>
    </g>
  </svg>
)

export default BackBlue
