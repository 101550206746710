import React from 'react'

const Buoy = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    {...props}
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-826 -444)">
        <g transform="translate(826 444)">
          <mask id="mask-buoy" fill="#fff">
            <circle id="path-1" cx="18" cy="18" r="18"></circle>
          </mask>
          <circle cx="18" cy="18" r="17.5" stroke="#00A8FF"></circle>
          <g stroke="#00A8FF" mask="url(#mask-buoy)">
            <g>
              <path d="M10 1l15.19 34.177" transform="translate(.5)"></path>
              <path
                d="M10 1l15.19 34.177"
                transform="translate(.5) rotate(45.462 17.595 18.088)"
              ></path>
              <path
                d="M10 1l15.19 34.177"
                transform="translate(.5) rotate(90 17.595 18.088)"
              ></path>
              <path
                d="M10 1l15.19 34.177"
                transform="translate(.5) rotate(135.462 17.595 18.088)"
              ></path>
            </g>
          </g>
          <circle
            cx="18"
            cy="18"
            r="10.5"
            fill="#FFF"
            stroke="#00A8FF"
            mask="url(#mask-buoy)"
          ></circle>
          <g fill="#00A8FF" mask="url(#mask-buoy)">
            <g transform="translate(10.5 1)">
              <path d="M2.85148403 7L0 0 14 0 11.2940449 7z"></path>
              <path
                d="M3.85148403 34L1 27 15 27 12.2940449 34z"
                transform="matrix(1 0 0 -1 0 61)"
              ></path>
            </g>
          </g>
          <g fill="#00A8FF" mask="url(#mask-buoy)">
            <g transform="rotate(-90 13.25 12.25)">
              <path d="M2.85148403 7L0 0 14 0 11.2940449 7z"></path>
              <path
                d="M3.85148403 34L1 27 15 27 12.2940449 34z"
                transform="matrix(1 0 0 -1 0 61)"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Buoy
