import { appStrings } from '.'

export const getLanguage = () => {
  const { pathname } = window.location
  const paths = pathname.split('/')
  const language = paths[1]

  if (['cs', 'de', 'nl', 'es', 'pt'].includes(language)) {
    return language
  }

  return 'en'
}

export const generateRoute = (language, routeKey, params = {}) => {
  const matchingRoute = appStrings[language][routeKey]

  let finalRoute = matchingRoute
  Object.keys(params).forEach((key) => {
    finalRoute = finalRoute.replace(`:${key}`, params[key])
  })

  return `${language === 'en' ? '' : `/${language}`}${finalRoute}`
}

export const getMatchingRoute = (messages, path, params, locale, language) => {
  /**
   * Get the key of the route the user is currently on
   */
  const route = locale === 'en' ? path : path.split(`/${locale}`)[1]
  const routeKey = Object.keys(messages).find((key) => messages[key] === route)

  /**
   * Find the matching route for the new language
   */
  const matchingRoute = appStrings[language][routeKey]

  let finalRoute = matchingRoute
  Object.keys(params).forEach((key) => {
    finalRoute = finalRoute.replace(`:${key}`, params[key])
  })

  /**
   * Return localized route
   */
  return `${language === 'en' ? '' : `/${language}`}${finalRoute}`
}
