import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { ClientOnly } from './ClientOnly'
import LoginFormHorizontal from './LoginFormHorizontal'

const YouDeserveATreat = ({ location, serviceName }) => {
  const intl = useIntl()

  return (
    <div className="promo-box">
      <div className="promo-box__heart icon icon--heart" />
      <div
        className="promo-box__heading"
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({ id: 'promo.you_deserve_a_treat' })
        }}
      />
      <div className="promo-box__content">
        <FormattedMessage
          id="promo.stockphotosecrets_is_changing_to"
          values={{
            serviceName: <span className="bold">{serviceName}</span>
          }}
        />
      </div>
      <ClientOnly>
        <LoginFormHorizontal location={location} serviceName={serviceName} />
      </ClientOnly>
    </div>
  )
}

export default YouDeserveATreat
