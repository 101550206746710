import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { FormattedMessage, useIntl } from 'react-intl'
import cx from 'classnames'
import Logo from './icons/Logo'
import BgRemoverLogo from './icons/BgRemoverLogo'
import ConverterLogo from './icons/ConverterLogo'
import Crown from './icons/Crown'
import { generateRoute } from '../i18n/helpers'
import Hamburger from './icons/Hamburger'
import MobileMenu from './MobileMenu'
import { fetchUser, logOut } from '../actions/auth'
import UpscaleCounter from './UpscaleCounter'
import UserMenu from './UserMenu'
import UpscaleCounterMobile from './UpscaleCounterMobile'

const Header = ({
  logOutCb,
  mobileMenuPlacement,
  pushUrl,
  statusMessage,
  statusMessageColor,
  userAuthenticated,
  userLoaded,
  userStatus
}) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const isPaidUser = userLoaded && ['standard', 'premium'].includes(userStatus)

  useEffect(() => {
    const config = {
      selector: '.js-headway-news',
      account: 'xWD3b7'
    }

    if (typeof Headway != 'undefined') {
      Headway.init(config)
    }
  })

  useEffect(() => {
    if (!userLoaded) {
      dispatch(fetchUser())
    }
  })

  return (
    <>
      {statusMessage && (
        <div
          className={`header__status-message header__status-message--${statusMessageColor}`}
        >
          <div
            className="container"
            dangerouslySetInnerHTML={{
              __html: statusMessage
            }}
          />
        </div>
      )}
      <div
        className={cx({
          header: true,
          'header--with-status-message': statusMessage
        })}
      >
        <div className="container">
          <div className="header__content-container">
            <div
              className="header__logo-container"
              onClick={() => pushUrl(generateRoute(intl.locale, 'routes.home'))}
              role="presentation"
            >
              <Logo />
              Upscaler
              {!isPaidUser && (
                <span>
                  <FormattedMessage id="header.free" />
                </span>
              )}
            </div>
            {userLoaded && (
              <div className="header__counter-container header__counter-container--mobile">
                <UpscaleCounterMobile />
              </div>
            )}
            <div className="header__menu">
              <div className="header__menu-item js-headway-news">
                <FormattedMessage id="header.news" />
              </div>
              {false && (
                <div className="header__menu-item">
                  <Crown />
                  <FormattedMessage id="header.get_premium" />
                </div>
              )}
              <a
                className="header__menu-item"
                href="https://bgremover.stockphotos.com/"
                target="_blank"
                rel="noreferrer"
              >
                <BgRemoverLogo />
                BG Remover
              </a>
              <a
                className="header__menu-item"
                href={intl.formatMessage({ id: 'header.converter_url' })}
                target="_blank"
                rel="noreferrer"
              >
                <ConverterLogo />
                <FormattedMessage id="header.converter" />
              </a>
              {userLoaded && (
                <div className="header__counter-container">
                  <UpscaleCounter />
                </div>
              )}
              {userLoaded && userAuthenticated && (
                <UserMenu logOut={logOutCb} push={pushUrl} />
              )}
              {userLoaded && !userAuthenticated && (
                <div
                  className="header__menu-item header__menu-item--button"
                  onClick={() =>
                    pushUrl(generateRoute(intl.locale, 'routes.login'))
                  }
                  role="presentation"
                >
                  <FormattedMessage id="header.login" />
                </div>
              )}
            </div>
            <div
              className="header__menu-icon"
              onClick={() => setMobileMenuOpen(true)}
            >
              <Hamburger />
            </div>
            <MobileMenu
              closeMenu={() => setMobileMenuOpen(false)}
              logOut={logOutCb}
              open={mobileMenuOpen}
              mobileMenuPlacement={mobileMenuPlacement}
              pushUrl={pushUrl}
            />
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  mobileMenuPlacement: state.settings.placements.mobileMenu,
  statusMessage: state.settings.statusMessage,
  statusMessageColor: state.settings.statusMessageColor,
  userAuthenticated: state.user.userAuthenticated,
  userLoaded: state.user.userLoaded,
  userStatus: state.user.userStatus
})

Header.propTypes = {
  pushUrl: PropTypes.func.isRequired
}

export default connect(mapStateToProps, { logOutCb: logOut, pushUrl: push })(
  Header
)
