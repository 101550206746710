import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import { push } from 'connected-react-router'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Message from '../components/Message'
import TwoStepLogin from '../components/TwoStepLogin'
import { generateRoute } from '../i18n/helpers'
import { ClientOnly } from '../components/ClientOnly'

const Login = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const userLoaded = useSelector((state) => state.user.userLoaded)
  const userAuthenticated = useSelector((state) => state.user.userAuthenticated)
  const loginStep = useSelector((state) => state.user.loginStep)

  useEffect(() => {
    if (userLoaded && userAuthenticated) {
      dispatch(push(generateRoute(intl.locale, 'routes.home')))
    }
  }, [userLoaded, userAuthenticated])

  return (
    <div className="oval-glow">
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            {loginStep === 'step-one' && (
              <Message
                center
                text={<FormattedMessage id="magic_login.login_to_upscaler" />}
              />
            )}
            {loginStep === 'step-two' && (
              <Message
                center
                text={<FormattedMessage id="magic_login.insert_magic_code" />}
              />
            )}
            <div className="file-report">
              <div className="file-report__stripe-blue" />
              <ClientOnly>
                <TwoStepLogin />
              </ClientOnly>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Login
