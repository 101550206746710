import React from 'react'
import cx from 'classnames'

const Tabs = ({ activeTab, onTabChange, tabs }) => (
  <div className="tabs">
    {tabs.map((tab) => (
      <div
        key={tab.id}
        className={cx({
          tabs__tab: true,
          'tabs__tab--active': tab.id === activeTab
        })}
        onClick={() => onTabChange(tab)}
      >
        {tab.name}
      </div>
    ))}
  </div>
)

export default Tabs
