import React from 'react'
import { FormattedMessage } from 'react-intl'

const DiscountGreen = () => (
  <div className="discount">
    <div className="discount__percent-off">
      <FormattedMessage id="thank_you.canva_45_days" />
    </div>
    <div className="discount__exclusive-deal">
      <FormattedMessage id="thank_you.canva_extended_free_trial" />
    </div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="290"
      height="110"
      viewBox="0 0 290 110"
    >
      <defs>
        <polygon
          id="path-1"
          points="-2.20323759e-12 0 280 0 280 90 -2.20323759e-12 90"
        ></polygon>
        <filter
          x="-5.7%"
          y="-15.6%"
          width="111.4%"
          height="135.6%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            stdDeviation="5"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0"
            type="matrix"
            in="shadowBlurOuter1"
          ></feColorMatrix>
        </filter>
        <path
          d="M5,5 L271,5 C273.209139,5 275,6.790861 275,9 L275,81 C275,83.209139 273.209139,85 271,85 L5,85 C2.790861,85 1,83.209139 1,81 L1,9 C1,6.790861 2.790861,5 5,5 Z"
          id="path-3"
        ></path>
        <mask
          id="mask-4"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
          x="0"
          y="0"
          width="274"
          height="80"
          fill="white"
        >
          <use xlinkHref="#path-3"></use>
        </mask>
        <linearGradient
          x1="100%"
          y1="50%"
          x2="73.9550781%"
          y2="50%"
          id="linearGradient-5"
        >
          <stop stopColor="#4F09A8" offset="0%"></stop>
          <stop stopColor="#7D2AE8" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g
        id="Website"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="07a-Downloading"
          transform="translate(-1005.000000, -398.000000)"
        >
          <g id="iStock-Ad" transform="translate(1005.000000, 316.000000)">
            <g id="CodeBg" transform="translate(0.000000, 90.000000)">
              <g id="Rectangle">
                <use
                  fill="black"
                  fillOpacity="1"
                  filter="url(#filter-2)"
                  xlinkHref="#path-1"
                ></use>
                <use
                  fill="#FFFFFF"
                  fillRule="evenodd"
                  xlinkHref="#path-1"
                ></use>
              </g>
              <use
                id="Rectangle"
                stroke="#333C4C"
                mask="url(#mask-4)"
                strokeWidth="2"
                opacity="0.3"
                strokeDasharray="6,4"
                xlinkHref="#path-3"
              ></use>
              <polygon
                id="Rectangle"
                fill="url(#linearGradient-5)"
                points="-1.11564404e-12 0 220 0 250 90 -1.11564404e-12 90"
              ></polygon>
              <polygon
                id="Rectangle"
                fill="#7D2AE8"
                points="199 19.3066406 219.944402 0 250 90"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
)

export default DiscountGreen
