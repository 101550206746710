import React from 'react'

const Supersmootth = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="120"
    height="120"
    viewBox="0 0 120 120"
    {...props}
  >
    <defs>
      <radialGradient
        id="radialGradient-1"
        cx="50%"
        cy="50%"
        r="50%"
        fx="50%"
        fy="50%"
        gradientTransform="matrix(0 1 -.99761 0 .999 0)"
      >
        <stop offset="0%" stopColor="#00A8FF"></stop>
        <stop offset="100%" stopColor="#00A8FF" stopOpacity="0"></stop>
      </radialGradient>
      <radialGradient
        id="radialGradient-2"
        cx="50%"
        cy="50%"
        r="50%"
        fx="50%"
        fy="50%"
        gradientTransform="matrix(0 1 -.99576 0 .998 0)"
      >
        <stop offset="0%" stopColor="#00A8FF"></stop>
        <stop offset="100%" stopColor="#00A8FF" stopOpacity="0"></stop>
      </radialGradient>
      <linearGradient
        id="linearGradient-3"
        x1="28.407%"
        x2="75.686%"
        y1="58.165%"
        y2="135.64%"
      >
        <stop offset="0%" stopColor="#F1FDFF"></stop>
        <stop offset="100%" stopColor="#8DD8FF"></stop>
      </linearGradient>
      <path
        id="path-4"
        d="M.297 54.288c-.396-2.274-.396-4.602 0-6.984.396-2.383 1.047-4.227 1.955-5.533.75 1.616 1.416 2.72 2 3.312.583.592 1.467 1.059 2.65 1.402-.83-4.23-1.247-7.039-1.247-8.425 0-1.387.416-2.72 1.248-4 .872-1.334 2.569-3.515 5.09-6.544 2.52-3.029 4.545-5.141 6.075-6.336.123 2.304.517 4.111 1.184 5.42.667 1.31 1.76 2.43 3.278 3.36-.228-4.316-.228-7.243 0-8.78.229-1.537.899-3.11 2.01-4.719 2.407-3.397 5.833-6.727 10.278-9.989C39.263 3.21 43.13 1.052 46.418 0c3.862 6.101 4.713 13.97 2.551 23.606-2.162 9.636-6.879 17.742-14.151 24.318-1.003.707-2.535 1.25-4.596 1.625-2.062.375-4.626.546-7.692.51.227.787.653 1.54 1.276 2.26.623.72 1.563 1.377 2.82 1.97-2.918 2.48-6.375 4.1-10.374 4.863-3.999.762-7.777.547-11.335-.645"
      ></path>
      <linearGradient
        id="linearGradient-6"
        x1="35.768%"
        x2="42.427%"
        y1="60.955%"
        y2="72.409%"
      >
        <stop offset="0%" stopColor="#F1FDFF"></stop>
        <stop offset="100%" stopColor="#8DD8FF"></stop>
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-1230 -860)">
        <g transform="translate(540 860)">
          <g transform="translate(690)">
            <circle
              cx="60"
              cy="60"
              r="59"
              stroke="#00A8FF"
              strokeWidth="2"
            ></circle>
            <ellipse
              cx="60"
              cy="86.5"
              fill="url(#radialGradient-1)"
              fillOpacity="0.15"
              rx="35"
              ry="16.5"
            ></ellipse>
            <ellipse
              cx="60"
              cy="81.5"
              fill="url(#radialGradient-2)"
              fillOpacity="0.1"
              rx="35"
              ry="10.5"
            ></ellipse>
            <g transform="translate(40.317 25)">
              <mask id="mask-supersmooth" fill="#fff">
                <use xlinkHref="#path-4"></use>
              </mask>
              <use
                fill="url(#linearGradient-3)"
                stroke="#00A8FF"
                strokeLinejoin="round"
                xlinkHref="#path-4"
              ></use>
              <g stroke="#00A8FF" mask="url(#mask-supersmooth)">
                <path
                  d="M13.297 0C12.432 3.002 12 5.56 12 7.677s.432 5.343 1.297 9.678"
                  transform="translate(27.886) rotate(10 12.648 8.677)"
                ></path>
                <path
                  d="M9.297 2C8.432 5.002 8 7.56 8 9.677s.432 5.343 1.297 9.678"
                  transform="translate(27.886) rotate(5 8.648 10.677)"
                ></path>
                <path
                  d="M5.297 4C4.432 7.002 4 9.56 4 11.677s.432 5.343 1.297 9.678"
                  transform="translate(27.886)"
                ></path>
                <path
                  d="M14.843 24.24c-.865 3.002-1.297 5.561-1.297 7.678 0 2.116.432 5.342 1.297 9.677"
                  transform="translate(27.886) scale(-1 1) rotate(-62 0 56.54)"
                ></path>
                <path
                  d="M13.176 30.663c-.864 3.002-1.296 5.56-1.296 7.677s.432 5.342 1.296 9.678"
                  transform="translate(27.886) scale(-1 1) rotate(-67 0 58.268)"
                ></path>
                <path
                  d="M9.51 36.085c-.864 3.002-1.296 5.561-1.296 7.677 0 2.117.432 5.343 1.296 9.678"
                  transform="translate(27.886) scale(-1 1) rotate(-72 0 56.96)"
                ></path>
              </g>
            </g>
            <path
              fill="url(#linearGradient-6)"
              stroke="#00A8FF"
              strokeLinejoin="round"
              d="M28 88.057c8.101-.026 16.641-3.43 25.62-10.214 8.978-6.784 16.645-16.53 22.998-29.237-6.945 8.618-14.61 16.142-22.998 22.571-8.387 6.43-15.714 10.222-21.98 11.376L28 88.057z"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Supersmootth
