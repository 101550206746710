import React, { useEffect, useState } from 'react'

// <script src="https://fast.wistia.com/embed/medias/e2p6icmxne.jsonp" async></script>
// <script src="https://fast.wistia.com/assets/external/E-v1.js" async></script>

const loadScript = (scriptId, scriptSrc, callback = null) => {
  const existingScript = document.getElementById(scriptId)

  if (!existingScript) {
    const script = document.createElement('script')
    script.src = scriptSrc
    script.id = scriptId
    document.body.appendChild(script)
    script.onload = () => {
      if (callback) callback()
    }
  }

  if (existingScript && callback) callback()
}

const ShutterstockVideo = () => {
  const [wistiaMediaLoaded, setWistiaMediaLoaded] = useState(false)
  const [wistiaExternalLoaded, setWistiaExternalLoaded] = useState(false)
  const [videoLoaded, setVideoLoaded] = useState(false)

  useEffect(() => {
    loadScript(
      'wistiaMedia',
      'https://fast.wistia.com/embed/medias/e2p6icmxne.jsonp',
      () => setWistiaMediaLoaded(true)
    )
    loadScript(
      'wistiaExternal',
      'https://fast.wistia.com/assets/external/E-v1.js',
      () => setWistiaExternalLoaded(true)
    )
  }, [])

  return (
    <div>
      {wistiaMediaLoaded && wistiaExternalLoaded && (
        <div
          className="wistia_responsive_padding"
          style={{
            padding: '56.25 % 0 0 0',
            position: 'relative'
          }}
        >
          <div
            className="wistia_responsive_wrapper"
            style={{
              height: '100%',
              left: 0,
              position: 'absolute',
              top: 0,
              width: '100%'
            }}
          >
            <div
              className="wistia_embed wistia_async_e2p6icmxne seo=false videoFoam=true"
              style={{
                height: '100%',
                position: 'relative',
                width: '100%'
              }}
            >
              <div
                className="wistia_swatch"
                style={{
                  height: '100%',
                  left: 0,
                  opacity: videoLoaded ? 1 : 0,
                  overflow: 'hidden',
                  position: 'absolute',
                  top: 0,
                  transition: 'opacity 200ms',
                  width: '100%'
                }}
              >
                <img
                  src="https://fast.wistia.com/embed/medias/e2p6icmxne/swatch"
                  style={{
                    filter: 'blur(5px)',
                    height: '100%',
                    objectFit: 'contain',
                    width: '100%'
                  }}
                  alt=""
                  aria-hidden="true"
                  onLoad={() => setVideoLoaded(true)}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ShutterstockVideo
