import React from 'react'

const Globe = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g stroke="#646B76" transform="translate(-1240 -1213)">
        <g transform="translate(315 1214)">
          <g transform="translate(926)">
            <g>
              <circle cx="11" cy="11" r="11"></circle>
              <ellipse cx="11" cy="11" rx="4" ry="11"></ellipse>
              <path d="M0 11h22M1.833 5h18.334M1.833 17h18.334"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Globe
