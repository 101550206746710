import React from 'react'

const Handle = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="40"
    height="96"
    viewBox="0 0 40 96"
    {...props}
  >
    <defs>
      <linearGradient id="linearGradient-1" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#65CBFF"></stop>
        <stop offset="100%" stopColor="#00A8FF"></stop>
      </linearGradient>
      <rect id="path-2" width="20" height="76" x="0" y="0" rx="10"></rect>
      <filter
        id="filter-3"
        width="260%"
        height="142.1%"
        x="-80%"
        y="-18.4%"
        filterUnits="objectBoundingBox"
      >
        <feOffset
          dy="2"
          in="SourceAlpha"
          result="shadowOffsetOuter1"
        ></feOffset>
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="5"
        ></feGaussianBlur>
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        ></feComposite>
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        ></feColorMatrix>
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-940 -504)">
        <g transform="translate(535 295)">
          <g transform="translate(415 217)">
            <g>
              <use
                fill="#000"
                filter="url(#filter-3)"
                xlinkHref="#path-2"
              ></use>
              <rect
                width="18"
                height="74"
                x="1"
                y="1"
                fill="url(#linearGradient-1)"
                stroke="#00A8FF"
                strokeLinejoin="square"
                strokeWidth="2"
                rx="9"
              ></rect>
            </g>
            <circle cx="10" cy="38" r="2" fill="#FFF"></circle>
            <circle cx="10" cy="18" r="2" fill="#FFF"></circle>
            <circle cx="10" cy="58" r="2" fill="#FFF"></circle>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Handle
