import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

const ProcessingMessage = () => {
  const [longProcessing, setLongProcessing] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLongProcessing(true)
    }, 10000)

    return () => clearTimeout(timeout)
  }, [])

  return (
    <span>
      {longProcessing ? (
        <FormattedMessage id="uploader.still_uploading_and_enhancing" />
      ) : (
        <FormattedMessage id="uploader.uploading_and_enhancing" />
      )}
    </span>
  )
}

export default ProcessingMessage
