import React from 'react'
import { Prompt } from 'react-router'
import { FormattedMessage, useIntl } from 'react-intl'
import cx from 'classnames'
import { countOccurrences, getRootUrl, humanFileSize } from '../helpers'
import { generateRoute } from '../i18n/helpers'

const ProcessingBulk = ({
  bulkUpload,
  isBulkUploading,
  isBulkProcessing,
  remainingUpscales,
  uploads
}) => {
  const intl = useIntl()
  const counts = countOccurrences(uploads.map((u) => u.fileType))
  const doneStatuses = [
    'invalid_file',
    'success',
    'failure',
    'limit_exhausted',
    'content_not_safe'
  ]
  const uploadsDone = uploads.filter((u) =>
    doneStatuses.includes(u.status)
  ).length
  const uploadsAll = uploads.length
  const progress = Math.round((uploadsDone / uploadsAll) * 100)
  const upscaleUsedStatuses = [
    'before_processing',
    'created',
    'processing',
    'success'
  ]
  const bulkUploadUrl = () =>
    getRootUrl() +
    generateRoute(intl.locale, 'routes.bulk_upload_preview', {
      bulkUploadId: bulkUpload.id
    })

  return (
    <div className="bulk-upload">
      <Prompt
        when={isBulkUploading}
        message={intl.formatMessage({
          id: 'bulk_upload.you_have_unsaved_changes'
        })}
      />
      <div className="bulk-upload__uploading-images-text">
        {isBulkUploading && (
          <FormattedMessage id="bulk_upload.images_are_being_uploaded" />
        )}
        {!isBulkUploading && isBulkProcessing && (
          <FormattedMessage
            id="bulk_upload.check_batch_status_at"
            values={{
              bulkUploadUrl: (
                <a href={bulkUploadUrl()} target="_blank" className="blue">
                  {bulkUploadUrl()}
                </a>
              )
            }}
          />
        )}
      </div>
      <div className="bulk-upload__file-list-row bulk-upload__file-list-row--header">
        <span />
        <span>
          <FormattedMessage id="bulk_upload.image" />
        </span>
        <span>
          <FormattedMessage id="bulk_upload.status" />
        </span>
        <span>
          <FormattedMessage id="bulk_upload.size" />
        </span>
        <span />
      </div>
      <div className="bulk-upload__file-list-container">
        {uploads.map((upload) => (
          <div className="bulk-upload__file-list-row" key={upload.id}>
            <span className="center">
              {!['invalid_file', 'upload_started'].includes(upload.status) && (
                <img
                  src={upload.previewUrl}
                  style={{ height: 'auto', maxHeight: 25 }}
                />
              )}
            </span>

            <span
              className={cx({
                'greyed-out': ['limit_exhausted', 'upload_startted'].includes(
                  upload.status
                ),
                green: upload.status === 'success',
                red: ['failure', 'invalid_file', 'content_not_safe'].includes(
                  upload.status
                ),
                'semi-bold': [
                  'failure',
                  'invalid_file',
                  'content_not_safe'
                ].includes(upload.status)
              })}
            >
              {upload.filename}
            </span>
            <span
              className={cx({
                green: upload.status === 'success',
                blue: upload.status === 'limit_exhausted',
                red: ['failure', 'invalid_file', 'content_not_safe'].includes(
                  upload.status
                )
              })}
            >
              {upload.status === 'limit_exhausted' && (
                <a
                  href={generateRoute(intl.locale, 'routes.pricing')}
                  target="_blank"
                  className="no-underline blue"
                >
                  <FormattedMessage
                    id={`bulk_upload.status.${upload.status}`}
                  />
                </a>
              )}
              {upload.status !== 'limit_exhausted' && (
                <FormattedMessage id={`bulk_upload.status.${upload.status}`} />
              )}
            </span>
            <span>{humanFileSize(upload.size)}</span>
            <span
              className={cx({
                blue: upload.status === 'success'
              })}
            >
              {!['processing', 'success'].includes(upload.status) && 'N/A'}
              {upload.status === 'processing' && (
                <FormattedMessage id="bulk_upload.generating" />
              )}
              {upload.status === 'success' && (
                <a
                  href={generateRoute(intl.locale, 'routes.preview', {
                    requestId: upload.upscaleRequestId
                  })}
                  target="_blank"
                  className="no-underline blue"
                >
                  <FormattedMessage id="bulk_upload.preview" />
                </a>
              )}
            </span>
          </div>
        ))}
      </div>
      <div className="bulk-upload__status-container">
        <div className="bulk-upload__status">
          <span className="green">
            <FormattedMessage
              id="bulk_upload.images"
              values={{ imagesCount: uploads.length }}
            />
          </span>
          <br />
          <span className="bulk-upload__status-help-text">
            {Object.keys(counts)
              .map((key) => `${counts[key]}x ${key.toUpperCase()}`)
              .join(', ')}
          </span>
        </div>
        <div className="bulk-upload__status">
          <span className="red">
            <FormattedMessage
              id="bulk_upload.errors"
              values={{
                errorsCount: uploads.filter((u) =>
                  [
                    'failure',
                    'invalid_file',
                    'limit_exhausted',
                    'content_not_safe'
                  ].includes(u.status)
                ).length
              }}
            />
          </span>
          <br />
          <span className="bulk-upload__status-help-text">
            <FormattedMessage id="bulk_upload.invalid_image_files" />
          </span>
        </div>
        <div className="bulk-upload__status">
          <FormattedMessage
            id="bulk_upload.upscales_used"
            values={{
              upscalesUsed: uploads.filter((u) =>
                upscaleUsedStatuses.includes(u.status)
              ).length
            }}
          />
          <br />
          <span className="bulk-upload__status-help-text">
            <FormattedMessage
              id="bulk_upload.upscales_remaining"
              values={{ upscalesLeft: remainingUpscales }}
            />
          </span>
        </div>
      </div>
      <div className="bulk-upload__progress-bar-container">
        <div className="uploader__progress-bar-container">
          <div
            className="uploader__progress-bar"
            style={{ width: `${progress}%` }}
          />
          <div
            className="uploader__progress"
            style={{ left: `calc(${progress}% - 20px)` }}
          >
            {`${progress}%`}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProcessingBulk
