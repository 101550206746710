import React from 'react'

const Stripe = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="630"
    height="10"
    viewBox="0 0 630 10"
    {...props}
  >
    <defs>
      <linearGradient id="linearGradient-1" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#FFF"></stop>
        <stop offset="100%" stopColor="#FFF" stopOpacity="0"></stop>
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-645 -300)">
        <g transform="translate(645 300)">
          <g transform="translate(-30)">
            <path fill="#00A8FF" d="M170 0L210 0 200 9.2 160 9.2z"></path>
            <path fill="#C51162" d="M250 0L290 0 280 9.2 240 9.2z"></path>
            <path fill="#00A8FF" d="M10 0L50 0 40 9.2 0 9.2z"></path>
            <path fill="#C51162" d="M90 0L130 0 120 9.2 80 9.2z"></path>
            <path fill="#00A8FF" d="M330 0L370 0 360 9.2 320 9.2z"></path>
            <path fill="#C51162" d="M410 0L450 0 440 9.2 400 9.2z"></path>
            <path fill="#00A8FF" d="M490 0L530 0 520 9.2 480 9.2z"></path>
            <path fill="#C51162" d="M570 0L610 0 600 9.2 560 9.2z"></path>
            <path fill="#00A8FF" d="M650 0L690 0 680 9.2 640 9.2z"></path>
            <path
              fill="url(#linearGradient-1)"
              fillOpacity="0.2"
              d="M0 0H690V9.2H0z"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Stripe
