import React from 'react'

const LoadingSpider = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="313"
    height="314"
    viewBox="0 0 313 314"
    {...props}
  >
    <defs>
      <linearGradient id="linearGradient-1" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#646B76" stopOpacity="0"></stop>
        <stop offset="100%" stopColor="#646B76"></stop>
      </linearGradient>
    </defs>
    <g
      fill="none"
      fillRule="evenodd"
      stroke="none"
      strokeWidth="1"
      opacity="0.5"
    >
      <g
        stroke="url(#linearGradient-1)"
        strokeWidth="2"
        transform="translate(-804 -394)"
      >
        <g transform="translate(800 390)">
          <g transform="translate(119.355 6.426)">
            <path d="M41.29 153.022c19.092-13.387 31.557-33.835 37.395-61.345 5.838-27.51 4.978-58.07-2.58-91.677"></path>
            <path
              d="M0 306.044c19.092-13.387 31.556-33.835 37.395-61.345 5.838-27.51 4.977-58.07-2.581-91.677"
              transform="rotate(180 20.605 229.533)"
            ></path>
          </g>
          <g transform="rotate(15 76.241 607.632)">
            <path d="M41.29 153.022c19.092-13.387 31.557-33.835 37.395-61.345 5.838-27.51 4.978-58.07-2.58-91.677"></path>
            <path
              d="M0 306.044c19.092-13.387 31.556-33.835 37.395-61.345 5.838-27.51 4.977-58.07-2.581-91.677"
              transform="rotate(180 20.605 229.533)"
            ></path>
          </g>
          <g transform="rotate(30 88.655 378.302)">
            <path d="M41.29 153.022c19.092-13.387 31.557-33.835 37.395-61.345 5.838-27.51 4.978-58.07-2.58-91.677"></path>
            <path
              d="M0 306.044c19.092-13.387 31.556-33.835 37.395-61.345 5.838-27.51 4.977-58.07-2.581-91.677"
              transform="rotate(180 20.605 229.533)"
            ></path>
          </g>
          <g transform="rotate(45 92.889 300.082)">
            <path d="M41.29 153.022c19.092-13.387 31.557-33.835 37.395-61.345 5.838-27.51 4.978-58.07-2.58-91.677"></path>
            <path
              d="M0 306.044c19.092-13.387 31.556-33.835 37.395-61.345 5.838-27.51 4.977-58.07-2.581-91.677"
              transform="rotate(180 20.605 229.533)"
            ></path>
          </g>
          <g transform="rotate(60 95.08 259.593)">
            <path d="M41.29 153.022c19.092-13.387 31.557-33.835 37.395-61.345 5.838-27.51 4.978-58.07-2.58-91.677"></path>
            <path
              d="M0 306.044c19.092-13.387 31.556-33.835 37.395-61.345 5.838-27.51 4.977-58.07-2.581-91.677"
              transform="rotate(180 20.605 229.533)"
            ></path>
          </g>
          <g transform="rotate(75 96.458 234.14)">
            <path d="M41.29 153.022c19.092-13.387 31.557-33.835 37.395-61.345 5.838-27.51 4.978-58.07-2.58-91.677"></path>
            <path
              d="M0 306.044c19.092-13.387 31.556-33.835 37.395-61.345 5.838-27.51 4.977-58.07-2.581-91.677"
              transform="rotate(180 20.605 229.533)"
            ></path>
          </g>
          <g transform="rotate(90 97.432 216.142)">
            <path d="M41.29 153.022c19.092-13.387 31.557-33.835 37.395-61.345 5.838-27.51 4.978-58.07-2.58-91.677"></path>
            <path
              d="M0 306.044c19.092-13.387 31.556-33.835 37.395-61.345 5.838-27.51 4.977-58.07-2.581-91.677"
              transform="rotate(180 20.605 229.533)"
            ></path>
          </g>
          <g transform="rotate(105 98.18 202.332)">
            <path d="M41.29 153.022c19.092-13.387 31.557-33.835 37.395-61.345 5.838-27.51 4.978-58.07-2.58-91.677"></path>
            <path
              d="M0 306.044c19.092-13.387 31.556-33.835 37.395-61.345 5.838-27.51 4.977-58.07-2.581-91.677"
              transform="rotate(180 20.605 229.533)"
            ></path>
          </g>
          <g transform="rotate(120 98.79 191.056)">
            <path d="M41.29 153.022c19.092-13.387 31.557-33.835 37.395-61.345 5.838-27.51 4.978-58.07-2.58-91.677"></path>
            <path
              d="M0 306.044c19.092-13.387 31.556-33.835 37.395-61.345 5.838-27.51 4.977-58.07-2.581-91.677"
              transform="rotate(180 20.605 229.533)"
            ></path>
          </g>
          <g transform="rotate(135 99.314 181.373)">
            <path d="M41.29 153.022c19.092-13.387 31.557-33.835 37.395-61.345 5.838-27.51 4.978-58.07-2.58-91.677"></path>
            <path
              d="M0 306.044c19.092-13.387 31.556-33.835 37.395-61.345 5.838-27.51 4.977-58.07-2.581-91.677"
              transform="rotate(180 20.605 229.533)"
            ></path>
          </g>
          <g transform="rotate(150 99.784 172.691)">
            <path d="M41.29 153.022c19.092-13.387 31.557-33.835 37.395-61.345 5.838-27.51 4.978-58.07-2.58-91.677"></path>
            <path
              d="M0 306.044c19.092-13.387 31.556-33.835 37.395-61.345 5.838-27.51 4.977-58.07-2.581-91.677"
              transform="rotate(180 20.605 229.533)"
            ></path>
          </g>
          <g transform="rotate(165 100.222 164.601)">
            <path d="M41.29 153.022c19.092-13.387 31.557-33.835 37.395-61.345 5.838-27.51 4.978-58.07-2.58-91.677"></path>
            <path
              d="M0 306.044c19.092-13.387 31.556-33.835 37.395-61.345 5.838-27.51 4.977-58.07-2.581-91.677"
              transform="rotate(180 20.605 229.533)"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default LoadingSpider
