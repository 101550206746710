import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'
import cx from 'classnames'
import Download from './icons/Download'
import { downloadFile } from '../actions/download'
import { generateRoute } from '../i18n/helpers'
import { countOccurrences, getFileType } from '../helpers'
import ModelType from './ModelType'

const BulkUploadsListItem = ({ bulkUpload }) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const pushUrl = (url) => dispatch(push(url))
  const [downloadStarted, setDownloadStarted] = useState(false)
  const upscaleRequests = bulkUpload.upscale_requests.filter(
    (u) => u.upscaled_image
  )
  const counts = countOccurrences(
    upscaleRequests.map(
      (u) =>
        `${getFileType(u.original_image)} → ${getFileType(u.upscaled_image)}`
    )
  )
  const downloadDisabled =
    upscaleRequests.filter((u) => u.status === 'success').length === 0 ||
    downloadStarted

  return (
    <div className="upscales-list__row">
      <div className="upscales-list__column">
        {upscaleRequests.length > 0 && (
          <div className="upscales-list__pile-container">
            <img
              src={upscaleRequests[0].thumbnail_url}
              className="upscales-list__pile"
              onClick={() =>
                pushUrl(
                  generateRoute(intl.locale, 'routes.bulk_upload_preview', {
                    bulkUploadId: bulkUpload.id
                  })
                )
              }
            />
          </div>
        )}
        {upscaleRequests.length === 0 && (
          <div
            className="icon icon--zip-archive cursor"
            onClick={() =>
              pushUrl(
                generateRoute(intl.locale, 'routes.bulk_upload_preview', {
                  bulkUploadId: bulkUpload.id
                })
              )
            }
          />
        )}
      </div>
      <div className="upscales-list__column">
        <div
          className="pricing-plans__scale pricing-plans__scale--active"
          style={{ marginBottom: 10 }}
        >
          {bulkUpload.scale}x
        </div>
        <ModelType modelName={bulkUpload.model_type} />
      </div>
      <div className="upscales-list__column">
        <FormattedMessage
          id="account_history.time_ago"
          values={{ time: bulkUpload.date }}
        />
      </div>
      <div className="upscales-list__column upscales-list__column--input-output">
        {Object.keys(counts).map((key) => (
          <div key={key}>{`${counts[key]}x ${key.toUpperCase()}`}</div>
        ))}
      </div>
      <div className="upscales-list__column upscales-list__column--buttons">
        <div className="image-preview__buttons-container">
          <div
            className={cx({
              button: true,
              'button--secondary': true,
              'button--large': true,
              'button--disabled': downloadDisabled,
              'image-preview__button--download': true
            })}
            onClick={() => {
              if (!downloadDisabled) {
                setDownloadStarted(true)

                setTimeout(() => setDownloadStarted(false), 5000)

                downloadFile(
                  `/api/v1/bulk_uploads/${bulkUpload.id}.zip`,
                  () => {}
                )
              }
            }}
            role="presentation"
          >
            <Download style={{ marginRight: 15 }} />
            <FormattedMessage id="account_history.download_batch" />
          </div>
        </div>
      </div>
      <div className="upscales-list__column upscales-list__column--input-output">
        <span
          className="blue cursor"
          onClick={() =>
            pushUrl(
              generateRoute(intl.locale, 'routes.bulk_upload_preview', {
                bulkUploadId: bulkUpload.id
              })
            )
          }
        >
          <FormattedMessage
            id="account_history.show_n_images"
            values={{ n: bulkUpload.upscale_requests_count }}
          />
        </span>
      </div>
    </div>
  )
}

const BulkUploadsList = ({ bulkUploads }) => (
  <div className="upscales-list upscales-list--bulk">
    <div className="upscales-list__content">
      <div className="upscales-list__row">
        <div className="upscales-list__column upscales-list__column--heading">
          <FormattedMessage id="account_history.preview" />
        </div>
        <div className="upscales-list__column upscales-list__column--heading">
          <FormattedMessage id="account_history.settings" />
        </div>
        <div className="upscales-list__column upscales-list__column--heading">
          <FormattedMessage id="account_history.upscaled" />
        </div>
        <div className="upscales-list__column upscales-list__column--heading">
          <FormattedMessage id="account_history.input_and_output" />
        </div>
        <div className="upscales-list__column upscales-list__column--buttons" />
        <div className="upscales-list__column" />
      </div>

      {bulkUploads.map((bulkUpload) => (
        <BulkUploadsListItem key={bulkUpload.id} bulkUpload={bulkUpload} />
      ))}
    </div>
  </div>
)

export default BulkUploadsList
