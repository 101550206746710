import PropTypes from 'prop-types'

export const UnprocessedUpscaleRequestType = PropTypes.shape({
  content_type: PropTypes.oneOf([
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/webp',
    'image/bmp'
  ]).isRequired,
  facebook: PropTypes.bool.isRequired,
  file_name: PropTypes.string.isRequired,
  file_size: PropTypes.string,
  height: PropTypes.number,
  horizontal_alignment: PropTypes.oneOf(['left', 'middle', 'right']).isRequired,
  id: PropTypes.string.isRequired,
  instagram: PropTypes.bool.isRequired,
  linkedin: PropTypes.bool.isRequired,
  model_type: PropTypes.oneOf([
    'sr',
    'deblock',
    'ultra',
    'detailpro',
    'facepro'
  ]).isRequired,
  original_image: PropTypes.string.isRequired,
  original_image_meta: PropTypes.shape({
    analyzed: PropTypes.oneOf([true]),
    height: PropTypes.number,
    identified: PropTypes.oneOf([true]).isRequired,
    width: PropTypes.number
  }),
  scale: PropTypes.oneOf([2, 4, 6, 8]).isRequired,
  status: PropTypes.oneOf(['created', 'processing', 'success', 'failure'])
    .isRequired,
  upscaled_image: PropTypes.string,
  upscaled_image_meta: PropTypes.shape({
    analyzed: PropTypes.oneOf([true]),
    height: PropTypes.number,
    identified: PropTypes.oneOf([true]).isRequired,
    width: PropTypes.number.isRequired
  }),
  vertical_alignment: PropTypes.oneOf(['top', 'center', 'bottom']).isRequired,
  width: PropTypes.number
})

export const UpscaleRequestType = PropTypes.shape({
  content_type: PropTypes.oneOf([
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/webp',
    'image/bmp'
  ]).isRequired,
  facebook: PropTypes.bool.isRequired,
  file_name: PropTypes.string.isRequired,
  file_size: PropTypes.string.isRequired,
  height: PropTypes.number,
  horizontal_alignment: PropTypes.oneOf(['left', 'middle', 'right']).isRequired,
  id: PropTypes.string.isRequired,
  instagram: PropTypes.bool.isRequired,
  linkedin: PropTypes.bool.isRequired,
  model_type: PropTypes.oneOf([
    'sr',
    'deblock',
    'ultra',
    'facepro',
    'detailpro'
  ]).isRequired,
  original_image: PropTypes.string.isRequired,
  original_image_meta: PropTypes.shape({
    analyzed: PropTypes.oneOf([true]).isRequired,
    height: PropTypes.number.isRequired,
    identified: PropTypes.oneOf([true]).isRequired,
    width: PropTypes.number.isRequired
  }).isRequired,
  scale: PropTypes.oneOf([2, 4, 6, 8]).isRequired,
  status: PropTypes.oneOf(['created', 'processing', 'success', 'failure'])
    .isRequired,
  upscaled_image: PropTypes.string.isRequired,
  upscaled_image_meta: PropTypes.shape({
    analyzed: PropTypes.oneOf([true]),
    height: PropTypes.number,
    identified: PropTypes.oneOf([true]).isRequired,
    width: PropTypes.number.isRequired
  }).isRequired,
  vertical_alignment: PropTypes.oneOf(['top', 'center', 'bottom']).isRequired,
  width: PropTypes.number
})
