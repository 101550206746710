import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import Istock from './icons/Istock'
import Copy from './icons/Copy'
import DiscountGreen from './DiscountGreen'

const copyToClipboard = (text) => {
  const input = document.createElement('textarea')
  input.innerHTML = text
  document.body.appendChild(input)
  input.select()
  const result = document.execCommand('copy')
  document.body.removeChild(input)
  return result
}

const IstockPromo = ({ className, location }) => {
  const { search } = location
  const [copied, setCopied] = useState(false)
  const displayCode = search.includes('codeid=16058')
  const intl = useIntl()

  return (
    <div className={className}>
      <div className="banner__logo-container">
        <Istock />
      </div>
      <DiscountGreen />
      <div
        className="banner__text"
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({ id: 'thank_you.istock_text' })
        }}
      />
      <div className="banner__button-container">
        {!displayCode && (
          <a
            className="button button--secondary button--medium"
            href="https://istockphoto.6q33.net/c/2912860/479045/4205?subId1=sp&subId2=upscaler"
            onClick={() => {
              window.open('?codeid=16058', '_blank')
            }}
          >
            <FormattedMessage id="thank_you.redeem_now" />
          </a>
        )}
        {displayCode && (
          <>
            <div className="banner__code">15SPS</div>
            <div
              className="banner__copy"
              onClick={() => {
                copyToClipboard('15SPS')
                setCopied(true)
              }}
              role="presentation"
            >
              <Copy />
              {copied ? (
                <FormattedMessage id="thank_you.copied" />
              ) : (
                <FormattedMessage id="thank_you.copy" />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

IstockPromo.propTypes = {
  className: PropTypes.string,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired
}

IstockPromo.defaultProps = {
  className: 'banner'
}

export default IstockPromo
