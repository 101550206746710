import React from 'react'

const Flag = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="17"
    viewBox="0 0 15 17"
    {...props}
  >
    <g fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-820 -260)">
        <g transform="translate(820 260)">
          <path d="M0 0H1V17H0z"></path>
          <path
            fillRule="nonzero"
            d="M8.691 3H14v8H8.309l-1-2H.5v1h6.191l1 2H15V2H9.309l-1-2H0v1h7.691l1 2zM14 11.5l.5-.5H14v.5z"
          ></path>
        </g>
      </g>
    </g>
  </svg>
)

export default Flag
