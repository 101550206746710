import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import cx from 'classnames'
import Checkbox from './Checkbox'

const TermsDialog = ({ acceptTerms }) => {
  const intl = useIntl()
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false)

  return (
    <div className="uploader">
      <div className="uploader__status">
        <span className="uploader__4x">2x</span>{' '}
        <span>
          <FormattedMessage id="uploader.upscale_and_enhance" />
        </span>{' '}
        <FormattedMessage id="uploader.with_smart_ai" />
        <span className="uploader__free">
          <FormattedMessage id="uploader.free" />
        </span>
      </div>
      <div
        className={cx({
          uploader__dropzone: true,
          'uploader__dropzone--is-uploading': false
        })}
        style={{ overflow: 'inherit' }}
      >
        <div className="terms-dialog">
          <div className="terms-dialog__hello">
            <FormattedMessage id="terms_dialog.hello" />
          </div>
          <div className="terms-dialog__friendly">
            <FormattedMessage id="terms_dialog.friendly_terms" />
          </div>
          <div className="terms-dialog__content">
            <FormattedMessage id="terms_dialog.before_we_process" />
          </div>
          <Checkbox
            name="privacy-accepted"
            checked={privacyPolicyAccepted}
            onChange={(event) => setPrivacyPolicyAccepted(event.target.checked)}
            text={
              <span
                className="regular"
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'terms_dialog.agree_with_privacy'
                  })
                }}
              />
            }
          />
          <Checkbox
            name="terms-accepted"
            checked={termsAccepted}
            onChange={(event) => setTermsAccepted(event.target.checked)}
            text={
              <span
                className="regular"
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'terms_dialog.agree_with_terms'
                  })
                }}
              />
            }
          />
          <div
            className={cx({
              button: true,
              'button--primary': true,
              'button--large': true,
              'button--disabled': !termsAccepted || !privacyPolicyAccepted
            })}
            onClick={() => {
              if (termsAccepted && privacyPolicyAccepted) {
                acceptTerms(termsAccepted, privacyPolicyAccepted)
              }
            }}
            role="presentation"
          >
            <FormattedMessage id="terms_dialog.continue" />
          </div>
        </div>
      </div>
      <a
        className="uploader__by-stock-photos"
        target="_blank"
        href="https://www.stockphotos.com/"
        rel="noreferrer"
      >
        by Stockphotos.com
      </a>
    </div>
  )
}

TermsDialog.propTypes = {
  acceptTerms: PropTypes.func.isRequired
}

export default TermsDialog
