import React from 'react'

const GoToTop = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="60"
      height="60"
      viewBox="0 0 60 60"
      {...props}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g>
          <path
            stroke="#00A8FF"
            d="M26.4497475 29.4497475L26.4497475 36.4497475 33.4497475 36.4497475"
            transform="scale(1 -1) rotate(-45 -49.598 0)"
          ></path>
        </g>
      </g>
    </svg>
  )
}

export default GoToTop
