import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Handle from '../icons/Handle'
import Maximize from '../icons/Maximize'
import { UpscaleRequestType } from '../../proptypes'

const getWindowHeight = () => {
  const { innerHeight } = window
  const { clientHeight } = document.documentElement
  const body = document.getElementsByTagName('body')[0]

  return innerHeight || clientHeight || body.clientHeight
}

const getPreviewHeight = (
  realAspectRatioHeight,
  minPreviewHeight,
  maxPreviewHeight
) => {
  if (realAspectRatioHeight < minPreviewHeight) {
    return minPreviewHeight
  } else if (realAspectRatioHeight > maxPreviewHeight) {
    return maxPreviewHeight
  }

  return realAspectRatioHeight
}

const Comparison = ({ upscaleRequest, toggleFullScreenMode }) => {
  const [sliderPosition, setSliderPosition] = useState(50)
  const aspectRatio =
    upscaleRequest.original_image_meta.height /
    upscaleRequest.original_image_meta.width
  const windowHeight = getWindowHeight()
  const realAspectRatioHeight = aspectRatio * 850
  const minPreviewHeight = 500
  const maxPreviewHeight = Math.max(windowHeight * 0.7, 500)
  const previewHeight = getPreviewHeight(
    realAspectRatioHeight,
    minPreviewHeight,
    maxPreviewHeight
  )

  return (
    <div className="comparison" style={{ height: previewHeight }}>
      <div
        className="comparison__divisor"
        style={{
          backgroundImage: `url(${upscaleRequest.upscaled_image})`,
          left: `${sliderPosition}%`
        }}
      >
        <Handle
          className="comparison__handle"
          style={{ left: `calc(${sliderPosition}% - 18.5px)` }}
        />
      </div>
      <div
        className="comparison__image comparison__image--original"
        style={{ width: `${sliderPosition}%` }}
      >
        <div className="comparison__label">
          <FormattedMessage id="image_preview.original" />
        </div>
        <img
          src={upscaleRequest.original_image}
          style={{
            height: previewHeight,
            width: 850,
            objectFit: 'cover'
          }}
          alt="Original"
        />
      </div>
      <div className="comparison__image comparison__image--upscaled">
        <div className="comparison__label comparison__label--upscaled">
          <FormattedMessage id="image_preview.upscaled" />
        </div>
        <div
          className="comparison__full-screen"
          onClick={() => toggleFullScreenMode(true)}
          role="presentation"
        >
          <Maximize />
        </div>
        <img
          src={upscaleRequest.upscaled_image}
          style={{
            height: previewHeight,
            width: 850,
            objectFit: 'cover'
          }}
          alt="Upscaled"
        />
      </div>
      <input
        type="range"
        min="0"
        max="100"
        value={sliderPosition}
        onChange={(event) => setSliderPosition(event.target.value)}
      />
    </div>
  )
}

Comparison.propTypes = {
  toggleFullScreenMode: PropTypes.func.isRequired,
  upscaleRequest: UpscaleRequestType.isRequired
}

export default Comparison
