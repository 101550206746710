import React, { useState, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import axios from 'axios'
import Header from '../components/Header'
import Footer from '../components/Footer'

const Help = () => {
  const intl = useIntl()
  const [page, setPage] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      axios.get(`/api/v1/pages/help?locale=${intl.locale}`).then((response) => {
        setPage(response.data)
      })
    }

    fetchData()
  }, [intl.locale])

  return (
    <>
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-md-2" />
          <div className="col-md-8">
            <div className="article">
              <h1 className="article__title center">
                <FormattedMessage id="help.title" />
              </h1>
              {page && (
                <div
                  className="article__content"
                  dangerouslySetInnerHTML={{
                    __html: page.content
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Help
