import React from 'react'

const BgRemoverLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 20 20"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g
        fill="currentColor"
        fillRule="nonzero"
        transform="translate(-345 -162)"
      >
        <g transform="translate(345 162)">
          <path d="M8.727 0v7.272H16v2.183h-2a4.121 4.121 0 11-7.454 2.424l-.001-2.425H4.121A4.121 4.121 0 116.545 2V0h2.182zm-6.06 12.121a1.212 1.212 0 110 2.424 1.212 1.212 0 010-2.424zm8-2.182a1.94 1.94 0 100 3.88 1.94 1.94 0 000-3.88zm-5.819 0a1.212 1.212 0 110 2.425 1.212 1.212 0 010-2.425zm-.727-6.545a1.94 1.94 0 100 3.879 1.94 1.94 0 000-3.88zm6.303.97a1.212 1.212 0 110 2.424 1.212 1.212 0 010-2.424zm2.182-2.182a1.212 1.212 0 110 2.424 1.212 1.212 0 010-2.424zM14.788 0a1.212 1.212 0 110 2.424 1.212 1.212 0 010-2.424z"></path>
        </g>
      </g>
    </g>
  </svg>
)

export default BgRemoverLogo
