import React from 'react'
import { FormattedMessage } from 'react-intl'
import cx from 'classnames'

const UploadDisabled = () => (
  <div className="uploader">
    <div className="uploader__status">
      <span className="uploader__4x">2x</span>{' '}
      <span>
        <FormattedMessage id="uploader.upscale_and_enhance" />
      </span>{' '}
      <FormattedMessage id="uploader.with_smart_ai" />
      <span className="uploader__free">
        <FormattedMessage id="uploader.free" />
      </span>
    </div>
    <div
      className={cx({
        uploader__dropzone: true,
        'uploader__dropzone--is-uploading': false
      })}
    >
      <div className="terms-dialog">
        <div className="terms-dialog__hello">
          <FormattedMessage id="upload_disabled.text_1" />
        </div>
        <div className="terms-dialog__friendly">
          <FormattedMessage id="upload_disabled.text_2" />
        </div>
      </div>
    </div>
    <a
      className="uploader__by-stock-photos"
      target="_blank"
      href="https://www.stockphotos.com/"
      rel="noreferrer"
    >
      by Stockphotos.com
    </a>
  </div>
)

export default UploadDisabled
