import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import { push } from 'connected-react-router'
import queryString from 'query-string'
import cx from 'classnames'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Message from '../components/Message'
import { validateEmail } from '../helpers'
import { generateRoute } from '../i18n/helpers'
import { signUp } from '../actions/auth'

const Relogin = (params) => {
  const intl = useIntl()
  const queryParams = queryString.parse(params.location.search)
  const dispatch = useDispatch()
  const userLoaded = useSelector((state) => state.user.userLoaded)
  const userAuthenticated = useSelector((state) => state.user.userAuthenticated)
  const loginStep = useSelector((state) => state.user.loginStep)
  const email = queryParams.email
  const formIsValid = validateEmail(email)

  useEffect(() => {
    if (userLoaded && userAuthenticated) {
      dispatch(push(generateRoute(intl.locale, 'routes.home')))
    }
  }, [userLoaded, userAuthenticated])

  useEffect(() => {
    if (loginStep === 'step-two') {
      dispatch(push(generateRoute(intl.locale, 'routes.login')))
    }
  }, [loginStep])

  return (
    <div className="oval-glow">
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <Message
              center
              text={<FormattedMessage id="magic_login.magic_link_expired" />}
            />
            <div className="file-report">
              <div className="file-report__stripe-blue" />
              <div>
                <div
                  className="file-report__so-happy"
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({
                      id: 'magic_login.magic_link_expired_explainer'
                    })
                  }}
                />
                <div className="file-report__get-help">
                  <div
                    className={cx({
                      button: true,
                      'button--primary': true,
                      'button--large': true,
                      'button--disabled': !formIsValid
                    })}
                    onClick={() => {
                      if (formIsValid) {
                        dispatch(signUp(email, true, true, intl.locale))
                      }
                    }}
                    role="presentation"
                  >
                    <FormattedMessage id="magic_login.resend_new_link" />
                  </div>
                </div>

                <div
                  className="file-report__upscale-another m-b-30"
                  onClick={() =>
                    dispatch(push(generateRoute(intl.locale, 'routes.signup')))
                  }
                  role="presentation"
                >
                  <FormattedMessage id="magic_login.create_account_instead" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Relogin
