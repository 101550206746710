import React from 'react'
import { FormattedMessage } from 'react-intl'

const DiscountGreen = () => (
  <div className="discount">
    <div className="discount__percent-off">
      <FormattedMessage
        id="thank_you.percent_off"
        values={{
          percent: '15%'
        }}
      />
    </div>
    <div className="discount__exclusive-deal">
      <FormattedMessage id="thank_you.special_offer" />
    </div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="290"
      height="110"
      viewBox="0 0 290 110"
    >
      <defs>
        <path
          id="path-1"
          d="M-2.20323759e-12 0L280 0 280 90 -2.20323759e-12 90z"
        />
        <filter
          id="filter-2"
          width="111.4%"
          height="135.6%"
          x="-5.7%"
          y="-15.6%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="5"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
        </filter>
        <path
          id="path-3"
          d="M5 5h266a4 4 0 014 4v72a4 4 0 01-4 4H5a4 4 0 01-4-4V9a4 4 0 014-4z"
        />
        <mask
          id="mask-discount-green"
          width="274"
          height="80"
          x="0"
          y="0"
          fill="#fff"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
        >
          <use xlinkHref="#path-3" />
        </mask>
        <linearGradient
          id="linearGradient-5"
          x1="100%"
          x2="73.955%"
          y1="50%"
          y2="50%"
        >
          <stop offset="0%" stopColor="#007864" />
          <stop offset="100%" stopColor="#02A488" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-1005 -440)">
          <g transform="translate(1005 358)">
            <g transform="translate(0 90)">
              <g>
                <use fill="#000" filter="url(#filter-2)" xlinkHref="#path-1" />
                <use fill="#FFF" xlinkHref="#path-1" />
              </g>
              <use
                stroke="#333C4C"
                strokeDasharray="6,4"
                strokeWidth="2"
                mask="url(#mask-discount-green)"
                opacity="0.3"
                xlinkHref="#path-3"
              />
              <path
                fill="url(#linearGradient-5)"
                d="M-1.11564404e-12 0L220 0 250 90 -1.11564404e-12 90z"
              />
              <path fill="#02A488" d="M199 19.3066406L219.944402 0 250 90z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
)

export default DiscountGreen
