import Consts from '../consts'

const initialState = {
  credits: 3,
  errors: [],
  loginStep: 'step-one',
  receipts: [],
  upscales: [],
  urlAfterLogin: null,
  userAuthenticated: false,
  userEmail: '',
  userLoaded: false,
  userPurchasedCredits: false,
  userRemainingUpscales: 0,
  userStatus: 'free',
  userUpscaleExpirationTime: null
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case Consts.USER_SIGN_UP_START: {
      return { ...state, userEmail: action.email, errors: [] }
    }
    case Consts.USER_SIGN_UP_DONE: {
      return { ...state, loginStep: 'step-two', userEmail: action.email }
    }
    case Consts.USER_SIGN_UP_ERROR: {
      return { ...state, errors: action.errors }
    }
    case Consts.USER_VERIFY_LOGIN_CODE_START: {
      return { ...state, errors: [] }
    }
    case Consts.USER_VERIFY_LOGIN_CODE_DONE: {
      return {
        ...state,
        userAuthenticated: true,
        userEmail: action.email
      }
    }
    case Consts.USER_VERIFY_LOGIN_CODE_ERROR: {
      return { ...state, errors: action.errors }
    }
    case Consts.USER_FETCH_DONE: {
      const {
        userAuthenticated,
        userAvatar,
        userEmail,
        userRemainingUpscales,
        userStatus,
        userUpscaleExpirationTime
      } = action
      // user purchased credits and the number of upscales didn't change
      const userPurchasedCredits =
        state.userPurchasedCredits &&
        state.userRemainingUpscales === action.userRemainingUpscales

      return {
        ...state,
        userAuthenticated,
        userAvatar,
        userEmail,
        userLoaded: true,
        userPurchasedCredits,
        userRemainingUpscales,
        userStatus,
        userUpscaleExpirationTime
      }
    }
    case Consts.USER_FETCH_ERROR: {
      return { ...state, isAuthenticated: false, userLoaded: true }
    }
    case Consts.USER_PURCHASED_CREDITS: {
      return { ...state, userPurchasedCredits: true }
    }
    case Consts.USER_LOGOUT_DONE: {
      return {
        ...state,
        loginStep: 'step-one',
        userAuthenticated: false,
        userEmail: ''
      }
    }
    default:
      return state
  }
}
