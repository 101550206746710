import React, { useState, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import axios from 'axios'
import cx from 'classnames'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { generateRoute } from '../i18n/helpers'
import UpscalesList from '../components/UpscalesList'
import Pagination from '../components/Pagination'
import Tabs from '../components/Tabs'
import BulkUploadsList from '../components/BulkUploadsList'

// TODO: Cleanup
const Account = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const userAuthenticated = useSelector((state) => state.user.userAuthenticated)
  const userEmail = useSelector((state) => state.user.userEmail)
  const userLoaded = useSelector((state) => state.user.userLoaded)
  const userUpscaleExpirationTime = useSelector(
    (state) => state.user.userUpscaleExpirationTime
  )
  const [upscales, setUpscales] = useState([])
  const [bulkUploads, setBulkUploads] = useState([])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [tab, setTab] = useState({
    id: 'upscales',
    name: 'Upscales'
  })
  const [downloadStarted, setDownloadStarted] = useState(false)
  const perPage = 20
  const selectedUpscales = upscales.filter((u) => u.selected)

  const fetchUpscaleRequests = () => {
    axios
      .get(
        `/api/v1/upscale_requests?locale=${intl.locale}&page=${page}&per_page=${perPage}`
      )
      .then((response) => {
        setUpscales(
          response.data.upscale_requests.map((u) => ({ ...u, selected: false }))
        )
        setTotalPages(response.data.total_pages)
      })
  }

  const fetchBulkUploads = () => {
    axios
      .get(
        `/api/v1/bulk_uploads?locale=${intl.locale}&page=${page}&per_page=${perPage}`
      )
      .then((response) => {
        setBulkUploads(response.data.bulk_uploads)
        setTotalPages(response.data.total_pages)
      })
  }

  const deleteUpscaleRequest = (upscaleRequest) => {
    axios.delete(`/api/v1/upscale_requests/${upscaleRequest.id}`).then(() => {
      const newUpscales = upscales.filter((u) => u.id !== upscaleRequest.id)

      setUpscales(newUpscales)
    })
  }

  const bulkDownload = () => {
    if (downloadStarted) return

    const upscaleRequestIds = selectedUpscales.map((u) => u.id)

    setDownloadStarted(true)

    axios
      .post(
        '/api/v1/bulk_uploads/bulk_download.zip',
        {
          bulk_download: { upscale_request_ids: upscaleRequestIds }
        },
        {
          responseType: 'blob'
        }
      )
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/zip' })
        const downloadUrl = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', 'upscaler-bulk-download.zip')
        document.body.appendChild(link)
        link.click()
        setDownloadStarted(false)
      })
  }

  useEffect(() => {
    if (userLoaded && !userAuthenticated) {
      dispatch(push(generateRoute(intl.locale, 'routes.home')))
    } else if (userLoaded && userAuthenticated) {
      if (tab.id === 'bulks') {
        fetchBulkUploads()
      } else {
        fetchUpscaleRequests()
      }
    }
  }, [userLoaded, userAuthenticated, intl.locale, page, tab])

  return (
    <div className="oval-glow">
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            {userAuthenticated && (
              <>
                <h1 className="account__heading">
                  <FormattedMessage id="account_history.title" />
                </h1>
                <div className="account__user-email">{userEmail}</div>
                <div className="account__page-description">
                  <FormattedMessage
                    id="account_history.description"
                    values={{ days: userUpscaleExpirationTime }}
                  />
                </div>
                <div className="m-b-30">
                  <Tabs
                    activeTab={tab.id}
                    onTabChange={(tab) => {
                      setTab(tab)
                      setPage(1)
                    }}
                    tabs={[
                      {
                        id: 'upscales',
                        name: 'Upscales'
                      },
                      {
                        id: 'bulks',
                        name: 'Bulk Uploads'
                      }
                    ]}
                  />
                </div>

                {tab.id === 'upscales' && (
                  <UpscalesList
                    upscales={upscales}
                    deleteUpscaleRequest={deleteUpscaleRequest}
                    toggleUpscaleRequestSelection={(upscale) => {
                      setUpscales((allUpscales) =>
                        allUpscales.map((u) => {
                          if (upscale.id === u.id) {
                            return { ...u, selected: !u.selected }
                          }

                          return u
                        })
                      )
                    }}
                  />
                )}
                {tab.id === 'bulks' && (
                  <BulkUploadsList bulkUploads={bulkUploads} />
                )}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <div>
                    {totalPages > 1 && (
                      <Pagination
                        currentPage={page}
                        setPage={setPage}
                        totalPages={totalPages}
                      />
                    )}
                  </div>
                  <div>
                    {selectedUpscales.length > 0 && (
                      <div
                        className={cx({
                          button: true,
                          'button--secondary': true,
                          'button--large': true,
                          'button--disabled': downloadStarted
                        })}
                        onClick={bulkDownload}
                        role="presentation"
                      >
                        <FormattedMessage
                          id="account_history.download_n_images"
                          values={{ n: selectedUpscales.length }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Account
