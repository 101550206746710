import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import DropDown from '../icons/DropDown'

const Pane = ({ title, children, open, subtitle, extraMargin }) => {
  const [isOpen, setIsOpen] = useState(open)

  return (
    <div
      className={cx({
        pane: true,
        'pane--with-extra-margin': extraMargin,
        'pane--open': isOpen
      })}
    >
      <div
        className="pane__title"
        onClick={() => setIsOpen(!isOpen)}
        dangerouslySetInnerHTML={{ __html: title }}
        role="presentation"
      />
      <div
        className="pane__subtitle"
        onClick={() => setIsOpen(!isOpen)}
        role="presentation"
      >
        {subtitle}
      </div>
      <DropDown className="pane__arrow" />
      {isOpen && <div className="pane__content">{children}</div>}
    </div>
  )
}

Pane.propTypes = {
  children: PropTypes.node.isRequired,
  extraMargin: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  title: PropTypes.string.isRequired
}

Pane.defaultProps = {
  extraMargin: false
}

export default Pane
