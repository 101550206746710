import React from 'react'

const SocialFb = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="25"
    viewBox="0 0 12 25"
    {...props}
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g stroke="currentColor" transform="translate(-964 -997)">
        <g transform="translate(935 980)">
          <path d="M40.5 25.271h-3.746v-2.49c0-.936.611-1.154 1.042-1.154h2.643v-4.113L36.8 17.5c-4.042 0-4.962 3.067-4.962 5.03v2.741H29.5v4.238h2.337V41.5h4.917V29.509h3.317l.429-4.238z"></path>
        </g>
      </g>
    </g>
  </svg>
)

export default SocialFb
