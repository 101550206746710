import React from 'react'

const ZeroesAndOnes = () => (
  <div className="zeroes-and-ones">
    <div className="zeroes-and-ones__glow" />
    <div className="zeroes-and-ones__glow zeroes-and-ones__glow--bottom" />0 0 1
    0 0 1 1 0 1 0 0 1 1 1 0 0 0 1 0 0 1 1 1 0 1 0 0 1 0 0 0 0 0 1 0 0 1 1 0 1 0
    0 1 1 1 0 0 0 1 0 0 1 1 1 0 1 0 0 1 0 0 0 0 0 1 0 0 1 1 0 1 0 0 1 1 1 0 0 0
    1 0 0 1 0 0 0 0 0 1 0 0 1 1 0 1 0 0 1 1 1 0 0 0 1 0 0 1 1 1 0 1 0 0 1 0 0 0
    0 0 1 0 0 1 1 1 1 0 1 0 0 0 1 0 0 1 1 0 1 0 0 1 1 1 0 0 0 1 0 0 1 1 1 0 1 0
    0 1 0 0 0 0 0 1 0 0 1 1 1 0 0 0 1 0 0 1 1 1 0 1 0 0 1 0 0 0 0 0 1 0 0 1 1 0
    1 0 0 1 1 1 0 0 0 1 0 0 0 1 0 0 1 1 0 1 0 0 1 1 1 0 0 0 1 0 0 1 1 1 0 1 0 0
    1 0 0 0 0 0 1 0 0 1 1 0 0 0 1 1 1 1 0 1 0 0 0 1 0 0 1 1 0 1 0 0 1 1 1 0 0 0
    1 0 0 0 0 0 1 0 0 1 1 0 1 0 0 1 1 1 0 0 0 1 0 0 1 1 1 0 1 0 0 1 0 0 0 0 0 1
    0 1 0 0 1 1 1 0 1 0 0 1 0 0 0 0 0 0 0 1 0 0 1 1 0 1 0 0 1 1 1 0 0 0 1 0 0 1
    1 1 0 1 0 0 1 0 0 0 0 0 1 0 0 1 1 0 1 0 0 1 1 1 0 0 0 1 0 0 1 1 1 0 1 0 0 1
    0 0 0 0 0 1 0 0 1 1 0 1 0 0 1 1 1 0 0 0 1 0 0 1 0 0 0 0 0 1 0 0 1 1 0 1 0 0
    1 1 1 0 0 0 1 0 0 1 1 1 0 1 0 0 1 0 0 0 0 0 1 0 0 1 1 1 1 0 1 0 0 0 1 0 0 1
    1 0 1 0 0 1 1 1 0 0 0 1 0 0 1 1 1 0 1 0 0 1 0 0 0 0 0 1 0 0 1 1 1 0 0 0 1 0
    0 1 1 1 0 1 0 0 1 0 0 0 0 0 1 0 0 1 1 0 1 0 0 1 1 1 0 0 0 1 0 0 0 1 0 0 1 1
    0 1 0 0 1 1 1 0 0 0 1 0 0 1 1 1 0 1 0 0 1 0 0 0 0 0 1 0 0 1 1 0 0 0 1 1 1 1
    0 1 0 0 0 1 0 0 1 1 0 1 0 0 1 1 1 0 0 0 1 0 0 0 0 0 1 0 0 1 1 0 1 0 0 1 1 1
    0 0 0 1 0 0 1 1 1 0 1 0 0 1 0 0 0 0 0 1 0 1 0 0 1 1 1 0 1 0 0 1 0 0 0 0 0 0
    0 1 0 0 1 1 0 1 0 0 1 1 1 0 0 0 1 0 0 1 1 1 0 1 0 0 1 0 0 0 0 0 1 0 0 1 1 0
    1 0 0 1 1 1 0 0 0 1 0 0 1 1 1 0 1 0 0 1 0 0 0 0 0 1 0 0 1 1 0 1 0 0 1 1 1 0
    0 0 1 0 0 1 0 0 0 0 0 1 0 0 1 1 0 1 0 0 1 1 1 0 0 0 1 0 0 1 1 1 0 1 0 0 1 0
    0 0 0 0 1 0 0 1 1 1 1 0 1 0 0 0 1 0 0 1 1 0 1 0 0 1 1 1 0 0 0 1 0 0 1 1 1 0
    1 0 0 1 0 0 0 0 0 1 0 0 1 1 1 0 0 0 1 0 0 1 1 1 0 1 0 0 1 0 0 0 0 0 1 0 0 1
    1 0 1 0 0 1 1 1 0 0 0 1 0 0 0 1 0 0 1 1 0 1 0 0 1 1 1 0 0 0 1 0 0 1 1 1 0 1
    0 0 1 0 0 0 0 0 1 0 0 1 1 0 0 0 1 1 1 1 0 1 0 0 0 1 0 0 1 1 0 1 0 0 1 1 1 0
    0 0 1 0 0 0 0 0 1 0 0 1 1 0 1 0 0 1 1 1 0 0 0 1 0 0 1 1 1 0 1 0 0 1 0 0 0 0
    0 1 0 1 0 0 1 1 1 0 1 0 0 1 0 0 0 0 0 0 0 1 0 0 1 1 0 1 0 0 1 1 1 0 0 0 1 0
    0 1 1 1 0 1 0 0 1 0 0 0 0 0 1 0 0 1 1 0 1 0 0 1 1 1 0 0 0 1 0 0 1 1 1 0 1 0
    0 1 0 0 0 0 0 1 0 0 1 1 0 1 0 0 1 1 1 0 0 0 1 0 0 1 0 0 0 0 0 1 0 0 1 1 0 1
    0 0 1 1 1 0 0 0 1 0 0 1 1 1 0 1 0 0 1 0 0 0 0 0 1 0 0 1 1 1 1 0 1 0 0 0 1 0
    0 1 1 0 1 0 0 1 1 1 0 0 0 1 0 0 1 1 1 0 1 0 0 1 0 0 0 0 0 1 0 0 1 1 1 0 0 0
    1 0 0 1 1 1 0 1 0 0 1 0 0 0 0 0 1 0 0 1 1 0 1 0 0 1 1 1 0 0 0 1 0 0 0 1 0 0
    1 1 0 1 0 0 1 1 1 0 0 0 1 0 0 1 1 1 0 1 0 0 1 0 0 0 0 0 1 0 0 1 1 0 0 0 1 1
    1 1 0 1 0 0 0 1 0 0 1 1 0 1 0 0 1 1 1 0 0 0 1 0 0 0 0 0 1 0 0 1 1 0 1 0 0 1
    1 1 0 0 0 1 0 0 1 1 1 0 1 0 0 1 0 0 0 0 0 1 0 1 0 0 1 1 1 0 1 0 0 1 0 0 0 0
    0 0 0 1 0 0 1 1 0 1 0 0 1 1 1 0 0 0 1 0 0 1 1 1 0 1 0 0 1 0 0 0 0 0 1 0 0 1
    1 0 1 0 0 1 1 1 0 0 0 1 0 0 1 1 1 0 1 0 0 1 0 0 0 0 0 1 0 0 1 1 0 1 0 0 1 1
    1 0 0 0 1 0 0 1 0 0 0 0 0 1 0 0 1 1 0 1 0 0 1 1 1 0 0 0 1 0 0 1 1 1 0 1 0 0
    1 0 0 0 0 0 1 0 0 1 1 1 1 0 1 0 0 0 1 0 0 1 1 0 1 0 0 1 1 1 0 0 0 1 0 0 1 1
    1 0 1 0 0 1 0 0 0 0 0 1 0 0 1 1 1 0 0 0 1 0 0 1 1 1 0 1 0 0 1 0 0 0 0 0 1 0
    0 1 1 0 1 0 0 1 1 1 0 0 0 1 0 0 0 1 0 0 1 1 0 1 0 0 1 1 1 0 0 0 1 0 0 1 1 1
    0 1 0 0 1 0 0 0 0 0 1 0 0 1 1 0 0 0 1 1 1 1 0 1 0 0 0 1 0 0 1 1 0 1 0 0 1 1
    1 0 0 0 1 0 0 0 0 0 1 0 0 1 1 0 1 0 0 1 1 1 0 0 0 1 0 0 1 1 1 0 1 0 0 1 0 0
    0 0 0 1 0 1 0 0 1 1 1 0 1 0 0 1 0 0 0 0 0 0 0 1 0 0 1 1 0 1 0 0 1 1 1 0 0 0
    1 0 0 1 1 1 0 1 0 0 1 0 0 0 0 0 1 0 0 1 1 0 1 0 0 1 1 1 0 0 0 1 0 0 1 1 1 0
    1 0 0 1 0 0 0 0 0 1 0 0 1 1 0 1 0 0 1 1 1 0 0 0 1 0 0 1 0 0 0 0 0 1 0 0 1 1
    0 1 0 0 1 1 1 0 0 0 1 0 0 1 1 1 0 1 0 0 1 0 0 0 0 0 1 0 0 1 1 1 1 0 1 0 0 0
    1 0 0 1 1 0 1 0 0 1 1 1 0 0 0 1 0 0 1 1 1 0 1 0 0 1 0 0 0 0 0 1 0 0 1 1 1 0
    0 0 1 0 0 1 1 1 0 1 0 0 1 0 0 0 0 0 1 0 0 1 1 0 1 0 0 1 1 1 0 0 0 1 0
  </div>
)

export default ZeroesAndOnes
