import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { FormattedMessage, useIntl } from 'react-intl'
import cx from 'classnames'
import { generateRoute } from '../i18n/helpers'
import { fetchUser } from '../actions/auth'

const UpscaleCounter = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const remainingUpscales = parseInt(
    useSelector((state) => state.user.userRemainingUpscales)
  )
  const purchasedCredits = useSelector(
    (state) => state.user.userPurchasedCredits
  )
  const alertQuantity = 3

  useEffect(() => {
    let interval

    if (purchasedCredits) {
      interval = setInterval(() => {
        dispatch(fetchUser())
      }, 2000)
    }

    return () => clearInterval(interval)
  }, [purchasedCredits])

  return (
    <div
      className="button-group"
      onClick={() =>
        dispatch(push(generateRoute(intl.locale, 'routes.pricing')))
      }
      role="presentation"
    >
      <div
        className={cx({
          'button-group__button': true,
          'button-group__button--semi-bold-text': true,
          'button-group__button--alert':
            remainingUpscales <= alertQuantity && !purchasedCredits
        })}
      >
        <div className="icon icon--upscales m-r-5" />
        <span className={cx({ 'm-r-5': purchasedCredits })}>
          {remainingUpscales}
        </span>
        {purchasedCredits && <div className="button-group__bubble" />}
      </div>
      <div
        className={cx({
          'button-group__button': true,
          'button-group__button--alert': remainingUpscales <= alertQuantity
        })}
      >
        <FormattedMessage id="header.buy_more" />
      </div>
    </div>
  )
}

export default UpscaleCounter
