import React, { useState } from 'react'
import cx from 'classnames'

const Faq = ({ items }) => {
  const [faqItems, setFaqItems] = useState(items)

  const toggleItem = (item) =>
    faqItems.map((faqItem) => {
      if (faqItem.id === item.id) {
        return { ...faqItem, open: !faqItem.open }
      }

      return faqItem
    })

  return (
    <div className="faq__container">
      {faqItems.map((i) => (
        <div className="faq__item-container" key={`faq-item-${i.id}`}>
          <div
            className={cx({
              'faq__icon-container': true,
              'faq__icon-container--open': i.open
            })}
            onClick={() => setFaqItems(toggleItem(i))}
          >
            <div className="faq__icon" />
          </div>
          <div
            className="faq__question"
            onClick={() => setFaqItems(toggleItem(i))}
          >
            {i.question}
          </div>
          <div
            className={cx({
              faq__answer: true,
              'faq__answer--open': i.open
            })}
          >
            {i.answer}
          </div>
        </div>
      ))}
    </div>
  )
}

export default Faq
