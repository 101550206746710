import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import cx from 'classnames'
import axios from 'axios'
import { useSelector } from 'react-redux'
import Stripe from './icons/Stripe'
import Ok from './icons/Ok'
import Dropdown from './Dropdown'
import { validateEmail } from '../helpers'

const CanWeDoBetter = () => {
  const intl = useIntl()
  const [email, setEmail] = useState('')
  const [useCase, setUseCase] = useState(null)
  const [useCaseDescription, setUseCaseDescription] = useState('')
  const [step, setStep] = useState('step-one')
  const userAuthenticated = useSelector((state) => state.user.userAuthenticated)
  const emailValid = email === '' || validateEmail(email)

  const submitFeedback = () => {
    axios
      .post('/api/v1/user_feedbacks', {
        user_feedback: {
          email,
          use_case: useCase,
          use_case_description: useCaseDescription
        }
      })
      .then(() => {
        setStep('step-two')
      })
  }

  return (
    <div className="user-feedback">
      <Stripe className="file-report__stripe" />
      {step === 'step-one' && (
        <div>
          <div className="user-feedback__heading">
            <FormattedMessage id="user_feedback.can_we_do_better" />
          </div>
          <div className="user-feedback__description">
            <FormattedMessage id="user_feedback.tell_us_more" />
          </div>
          <Dropdown
            defaultText={
              <FormattedMessage id="user_feedback.choose_primary_use" />
            }
            value={useCase}
            onChange={(option) => setUseCase(option.value)}
            options={[
              {
                id: 1,
                value: 'Personal photography',
                name: <FormattedMessage id="user_feedback.use_case.personal" />
              },
              {
                id: 2,
                value: 'Business and product photos',
                name: <FormattedMessage id="user_feedback.use_case.business" />
              },
              {
                id: 3,
                value: 'Print graphics & design',
                name: <FormattedMessage id="user_feedback.use_case.print" />
              },
              {
                id: 4,
                value: 'Webdesign',
                name: <FormattedMessage id="user_feedback.use_case.webdesign" />
              },
              {
                id: 5,
                value: 'Other — describe',
                name: <FormattedMessage id="user_feedback.use_case.other" />
              }
            ]}
            style={{ marginBottom: 40, minWidth: 410 }}
          />
          {useCase === 'Other — describe' && (
            <div className="file-report__input-container">
              <textarea
                className="file-report__input"
                value={useCaseDescription}
                onChange={(event) => setUseCaseDescription(event.target.value)}
                placeholder={intl.formatMessage({
                  id: 'user_feedback.describe_your_use'
                })}
              />
            </div>
          )}
          {useCase && !userAuthenticated && (
            <>
              <div className="user-feedback__description">
                <FormattedMessage id="user_feedback.get_upcoming_features" />
              </div>
              <div className="file-report__input-container">
                {/* eslint-disable-next-line */}
                <label className="file-report__input-label" htmlFor="email">
                  <FormattedMessage id="report.email" />
                </label>
                <input
                  className="file-report__input"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  placeholder={intl.formatMessage({
                    id: 'report.enter_your_email'
                  })}
                  id="email"
                />
              </div>
            </>
          )}
          <div className="file-report__get-help">
            <div
              className={cx({
                button: true,
                'button--primary': true,
                'button--large': true,
                'button--disabled': !useCase || !emailValid
              })}
              onClick={() => {
                if (useCase && emailValid) {
                  submitFeedback()
                }
              }}
              role="presentation"
            >
              <FormattedMessage id="user_feedback.help_us_improve" />
            </div>
          </div>
        </div>
      )}
      {step === 'step-two' && (
        <div>
          <Ok className="file-report__ok" />
          <div className="file-report__thank-you">
            <FormattedMessage id="report.thank_you_for_support" />
          </div>
          <div className="file-report__get-help">
            <div
              className="button button--primary button--large"
              // onClick={() => pushUrl(generateRoute(intl.locale, 'routes.home'))}
              role="presentation"
            >
              <FormattedMessage id="report.convert_another" />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default CanWeDoBetter
