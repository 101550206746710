import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'
import cx from 'classnames'
import SocialFacebook from './icons/SocialFacebook'
import SocialLinkedin from './icons/SocialLinkedin'
import SocialInstagram from './icons/SocialInstagram'
import Download from './icons/Download'
import { startImageDownload } from '../actions/download'
import { generateRoute } from '../i18n/helpers'
import { getFileType, getRootUrl } from '../helpers'
import ModelType from './ModelType'
import Checkbox from './Checkbox'

const UpscalesListItem = ({
  deleteUpscaleRequest,
  push,
  toggleUpscaleRequestSelection,
  upscaleRequest
}) => {
  const intl = useIntl()
  const [shouldDelete, setShouldDelete] = useState(false)
  const [deleteUpscaleRequestTimer, setDeleteUpscaleRequestTimer] =
    useState(null)
  const dispatch = useDispatch()
  const startDownload = (upscaleRequest) => {
    dispatch(startImageDownload(upscaleRequest))
  }

  const undoDeleteUpscaleRequest = () => {
    clearTimeout(deleteUpscaleRequestTimer)
    setDeleteUpscaleRequestTimer(null)
  }

  useEffect(() => {
    if (shouldDelete) {
      deleteUpscaleRequest(upscaleRequest)
    }
  }, [shouldDelete])

  return (
    <div className="upscales-list__row">
      <div className="upscales-list__column">
        <div style={{ marginTop: -11 }}>
          <Checkbox
            name={`upscale-list-item-${upscaleRequest.id}`}
            checked={upscaleRequest.selected}
            onChange={toggleUpscaleRequestSelection}
          />
        </div>
      </div>
      <div className="upscales-list__column">
        <img
          src={upscaleRequest.thumbnail_url}
          className="upscales-list__image"
          onClick={() =>
            push(
              generateRoute(intl.locale, 'routes.preview', {
                requestId: upscaleRequest.id
              })
            )
          }
        />
      </div>
      <div
        className={cx({
          'upscales-list__column': true,
          'upscales-list__column--alert': upscaleRequest.expires_soon
        })}
      >
        <div
          className="pricing-plans__scale pricing-plans__scale--active"
          style={{ marginBottom: 5 }}
        >
          {upscaleRequest.scale}x
        </div>
        <div className="bold">
          <ModelType modelName={upscaleRequest.model_type} />
        </div>
        <div>
          <FormattedMessage
            id="account_history.time_ago"
            values={{ time: upscaleRequest.date }}
          />
        </div>
      </div>
      <div className="upscales-list__column">
        <span className="uppercase">
          {getFileType(upscaleRequest.original_image)}
        </span>{' '}
        • {upscaleRequest.original_image_meta.width}x
        {upscaleRequest.original_image_meta.height} px
        <br />{' '}
        <span className="uppercase">
          {getFileType(upscaleRequest.upscaled_image)}
        </span>{' '}
        • {upscaleRequest.upscaled_image_meta.width}x
        {upscaleRequest.upscaled_image_meta.height} px
      </div>
      <div className="upscales-list__column">
        <div className="d-flex align-items-center">
          <div
            className="button button--secondary button--large"
            onClick={() => {
              startDownload(upscaleRequest)
            }}
            role="presentation"
          >
            <Download style={{ marginRight: 5 }} />
            <FormattedMessage id="image_preview.download_image" />
          </div>
          <div
            className="button button--primary button--large m-l-10"
            onClick={() => {
              push(
                generateRoute(intl.locale, 'routes.export', {
                  requestId: upscaleRequest.id
                })
              )
            }}
            role="presentation"
          >
            <SocialFacebook />
            <SocialLinkedin />
            <SocialInstagram />
          </div>
        </div>
      </div>
      <div className="upscales-list__column">
        {!deleteUpscaleRequestTimer && (
          <div
            className="upscales-list__trash"
            onClick={() => {
              const timer = setTimeout(() => setShouldDelete(true), 5000)

              setDeleteUpscaleRequestTimer(timer)
            }}
          />
        )}
        {deleteUpscaleRequestTimer && (
          <div className="upscales-list__just-deleted">
            <FormattedMessage id="account_history.just_deleted" /> •{' '}
            <span onClick={undoDeleteUpscaleRequest}>
              <FormattedMessage id="account_history.just_deleted_undo" />
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

const UpscalesList = ({
  deleteUpscaleRequest,
  toggleUpscaleRequestSelection,
  upscales
}) => {
  const dispatch = useDispatch()

  return (
    <div className="upscales-list">
      <div className="upscales-list__content">
        <div className="upscales-list__row">
          <div className="upscales-list__column upscales-list__column--checkbox"></div>
          <div className="upscales-list__column upscales-list__column--heading">
            <FormattedMessage id="account_history.preview" />
          </div>
          <div className="upscales-list__column upscales-list__column--heading">
            <FormattedMessage id="account_history.upscaled" />
          </div>
          <div className="upscales-list__column upscales-list__column--heading">
            <FormattedMessage id="account_history.input_and_output" />
          </div>
          <div className="upscales-list__column upscales-list__column--buttons" />
          <div className="upscales-list__column" />
        </div>

        {upscales.map((upscaleRequest) => (
          <UpscalesListItem
            deleteUpscaleRequest={deleteUpscaleRequest}
            key={upscaleRequest.id}
            push={(url) => dispatch(push(url))}
            upscaleRequest={upscaleRequest}
            toggleUpscaleRequestSelection={() =>
              toggleUpscaleRequestSelection(upscaleRequest)
            }
          />
        ))}
      </div>
    </div>
  )
}

export default UpscalesList
