import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import queryString from 'query-string'
import Header from '../components/Header'
import Features from '../components/Features'
import Footer from '../components/Footer'
import UploadBox from '../components/UploadBox'
import YouDeserveATreat from '../components/YouDeserveATreat'

const PromoView = ({ location }) => {
  const queryParams = queryString.parse(location.search)
  const stockPhotoSecrets = !!queryParams.stockphotosecrets
  const serviceName = queryParams.service_name

  return (
    <div className="oval-glow">
      <Header />

      <div className="container">
        <div className="row">
          <div className="col-md-1" />
          <div className="col-md-10">
            {stockPhotoSecrets && (
              <img
                src="/images/stockphotosecrets-logo.webp"
                srcSet="/images/stockphotosecrets-logo.webp 1x, /images/stockphotosecrets-logo@2x.webp 2x"
                className="sharper-bigger__image"
                alt="Image Upscale"
              />
            )}
            <div className="sharper-bigger">
              <FormattedMessage id="promo.sharper_bigger" />
            </div>
            <div className="sharper-bigger__text">
              <FormattedMessage id="promo.introducing_upscaler" />
            </div>
            <YouDeserveATreat location={location} serviceName={serviceName} />
            <UploadBox />
          </div>
        </div>
        <Features />
      </div>

      <Footer />
    </div>
  )
}

export default PromoView
