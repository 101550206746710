import React from 'react'
import PropTypes from 'prop-types'
import { IntlProvider } from 'react-intl'
import { Route } from 'react-router-dom'
import { AppLanguage } from '../../consts/languages'

export const LocalizedRouter = ({
  children,
  RouterComponent,
  appStrings,
  defaultLanguage,
  history,
  path
}) => {
  const routerProps = typeof window !== 'undefined'
    ? { history }
    : { location: path, context: {} }

  return (
    <RouterComponent {...routerProps}>
      <Route path="/:lang([a-z]{2})">
        {({ match }) => {
          /**
           * Get current language
           * Set default locale to en if base path is used without a language
           */
          const params = match ? match.params : {}
          const { lang = defaultLanguage || AppLanguage.English } = params

          /**
           * If language is not in route path, redirect to language root
           */
          // const { pathname } = location
          // if (!pathname.includes(`/${lang}`)) {
          //   return <Redirect to={`/${lang}`} />
          // }

          /**
           * Return Intl provider with default language set
           */
          return (
            <IntlProvider locale={lang} messages={appStrings[lang]}>
              {children}
            </IntlProvider>
          )
        }}
      </Route>
    </RouterComponent>
  )
}

LocalizedRouter.propTypes = {
  appStrings: PropTypes.shape({}).isRequired,
  children: PropTypes.node.isRequired,
  defaultLanguage: PropTypes.string.isRequired,
  history: PropTypes.shape({}).isRequired,
  path: PropTypes.string.isRequired,
  RouterComponent: PropTypes.elementType.isRequired
}
