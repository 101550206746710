import React from 'react'

const BlinkArrow = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="92"
      height="31"
      viewBox="0 0 92 31"
      {...props}
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeLinecap="round"
        strokeWidth="1"
      >
        <g stroke="#00B557" strokeWidth="1.5" transform="translate(-1484 -331)">
          <g transform="matrix(-1 0 0 1 1575 332)">
            <path d="M.51 29.252C13.99 21.37 28.505 15.545 44.06 11.78c15.553-3.766 30.07-5.824 43.548-6.176"></path>
            <path
              strokeLinejoin="round"
              d="M75.827 0a30.27 30.27 0 017.257 2.168 28.303 28.303 0 016.459 3.983c-2.443.642-4.596 1.446-6.459 2.413A39.367 39.367 0 0077.637 12"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default BlinkArrow
