import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import { generateRoute } from '../i18n/helpers'
import { push } from 'connected-react-router'
import PropTypes from 'prop-types'
import Header from '../components/Header'
import Features from '../components/Features'
import Footer from '../components/Footer'
import UploadBox from '../components/UploadBox'
import Faq from '../components/Faq'

const HomeView = () => {
  const userAuthenticated = useSelector((state) => state.user.userAuthenticated)
  const dispatch = useDispatch()
  const pushUrl = (url) => dispatch(push(url))
  const intl = useIntl()

  return (
    <div className="oval-glow">
      <Header />

      <div className="container">
        <div className="row">
          <div className="col-md-1" />
          <div className="col-md-10">
            <UploadBox />
            {userAuthenticated && (
              <>
                <h2 className="article__title center">
                  <FormattedMessage id="faq.title" />
                </h2>
                <Faq
                  items={[
                    {
                      id: 1,
                      question: <FormattedMessage id="faq.question_1" />,
                      answer: <FormattedMessage id="faq.answer_1" />,
                      open: false
                    },
                    {
                      id: 2,
                      question: <FormattedMessage id="faq.question_2" />,
                      answer: <FormattedMessage id="faq.answer_2" />,
                      open: false
                    },
                    {
                      id: 3,
                      question: <FormattedMessage id="faq.question_3" />,
                      answer: <FormattedMessage id="faq.answer_3" />,
                      open: false
                    },
                    {
                      id: 4,
                      question: <FormattedMessage id="faq.question_4" />,
                      answer: <FormattedMessage id="faq.answer_4" />,
                      open: false
                    },
                    {
                      id: 5,
                      question: <FormattedMessage id="faq.question_5" />,
                      answer: <FormattedMessage id="faq.answer_5" />,
                      open: false
                    },
                    {
                      id: 6,
                      question: <FormattedMessage id="faq.question_6" />,
                      answer: <FormattedMessage id="faq.answer_6" />,
                      open: false
                    },
                    {
                      id: 7,
                      question: <FormattedMessage id="faq.question_7" />,
                      answer: <FormattedMessage id="faq.answer_7" />,
                      open: false
                    },
                    {
                      id: 8,
                      question: <FormattedMessage id="faq.question_8" />,
                      answer: <FormattedMessage id="faq.answer_8" />,
                      open: false
                    },
                    {
                      id: 9,
                      question: <FormattedMessage id="faq.question_9" />,
                      answer: <FormattedMessage id="faq.answer_9" />,
                      open: false
                    },
                    {
                      id: 10,
                      question: <FormattedMessage id="faq.question_10" />,
                      answer: <FormattedMessage id="faq.answer_10" />,
                      open: false
                    },
                    {
                      id: 11,
                      question: <FormattedMessage id="faq.question_11" />,
                      answer: <FormattedMessage id="faq.answer_11" />,
                      open: false
                    }
                  ]}
                />
                <div className="center">
                  <div
                    className="button button--secondary button--large article__contact-us"
                    onClick={() =>
                      pushUrl(generateRoute(intl.locale, 'routes.contact'))
                    }
                    role="presentation"
                  >
                    <FormattedMessage id="faq.get_more_help" />
                  </div>
                </div>
              </>
            )}
            <Features />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default HomeView
