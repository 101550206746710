import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import Shutterstock from './icons/Shutterstock'
import ExternalLink from './icons/ExternalLink'

const ShutterstockPromoMobile = ({ className }) => {
  const intl = useIntl()
  const [isHover, setHover] = useState(false)

  return (
    <>
      <div
        className="mobile-menu__free-images"
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({
            id: 'exclusive_deal.free_images'
          })
        }}
      ></div>
      <div className="mobile-menu__exclusive-deal">
        <FormattedMessage id="exclusive_deal.deal" />
      </div>
      <Shutterstock
        color="#25282C"
        className="mobile-menu__shutterstock-logo"
      />
      <a
        className="button button--secondary"
        href="https://shutterstock.7eer.net/c/2691128/861128/1305?subId1=sp&subId2=upscaler&subId3=black-popup&u=https%3A%2F%2Fwww.shutterstock.com%2Fdiscover%2F10-free-stock-images"
        target="_blank"
        rel="noreferrer"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <ExternalLink color={isHover ? '#fff' : '#00a8ff'} />
        <FormattedMessage id="exclusive_deal.redeem_now" />
      </a>
    </>
  )
}

ShutterstockPromoMobile.propTypes = {
  className: PropTypes.string
}

ShutterstockPromoMobile.defaultProps = {
  className: 'banner'
}

export default ShutterstockPromoMobile
