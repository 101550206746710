import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import Header from '../components/Header'
import RadioButton from '../components/RadioButton'
import Footer from '../components/Footer'
import Message from '../components/Message'
import WarningSign from '../components/icons/WarningSign'
import Buoy from '../components/icons/Buoy'
import { generateRoute } from '../i18n/helpers'

const UpgradeView = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const pushUrl = (url) => dispatch(push(url))
  const [plan, setPlan] = useState('20')
  const planOptions = [
    {
      id: 4,
      text: (
        <span className="regular">
          <FormattedMessage id="upgrade.get.standard" />
        </span>
      ),
      disabled: false,
      value: '20'
    },
    {
      id: 5,
      text: (
        <span className="regular">
          <FormattedMessage id="upgrade.get.premium" />
        </span>
      ),
      disabled: false,
      value: '100'
    },
    {
      id: 6,
      text: (
        <span className="regular">
          <FormattedMessage id="upgrade.get.free" />
        </span>
      ),
      disabled: false,
      value: '3'
    }
  ]

  const getButtonText = () => {
    switch (plan) {
      case '20':
        return (
          <FormattedMessage id="upgrade.buy_n_upscales" values={{ n: 20 }} />
        )
      case '100':
        return (
          <FormattedMessage id="upgrade.buy_n_upscales" values={{ n: 100 }} />
        )
      default:
        return <FormattedMessage id="upgrade.go_to_single_upload" />
    }
  }

  const getButtonDestination = () => {
    switch (plan) {
      case '20':
        return generateRoute(intl.locale, 'routes.checkout', {
          productId: 647401
        })
      case '100':
        return generateRoute(intl.locale, 'routes.checkout', {
          productId: 647403
        })
      default:
        return generateRoute(intl.locale, 'routes.home')
    }
  }

  return (
    <div className="alert-glow">
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-md-1" />
          <div className="col-md-10">
            <Message
              failure
              center
              text={<FormattedMessage id="upgrade.bulk_upload_is_premium" />}
              icon={<WarningSign />}
            />

            <div className="file-report">
              <div>
                <div className="file-report__let-us-help-you">
                  <Buoy />
                  <FormattedMessage id="upgrade.grab_premium_upscales_below" />
                </div>

                <div className="file-report__wrong-format">
                  <FormattedMessage id="upgrade.you_need_to_purchase_premium_credits_text" />
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: 5
                  }}
                >
                  <div>
                    <RadioButton
                      name="upscale-resolution-radio"
                      options={planOptions}
                      selectedOption={plan}
                      onChange={(event) => {
                        setPlan(event.target.value)
                      }}
                    />
                  </div>
                </div>

                <div className="file-report__get-help">
                  <div
                    className="button button--primary button--large"
                    onClick={() => pushUrl(getButtonDestination())}
                    role="presentation"
                  >
                    {getButtonText()}
                  </div>
                </div>

                <div
                  className="file-report__upscale-another"
                  onClick={() =>
                    pushUrl(generateRoute(intl.locale, 'routes.home'))
                  }
                  role="presentation"
                >
                  <FormattedMessage id="upgrade.cancel_upscaling" />
                </div>
                <img
                  src="/images/help-boy.png"
                  srcSet="/images/help-boy.png 1x, /images/help-boy-2x.png 2x"
                  className="file-report__help-boy"
                  alt="Help Boy"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default UpgradeView
