import React from 'react'

const WarningSign = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="19"
    viewBox="0 0 21 19"
    {...props}
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fillRule="nonzero" transform="translate(-846 -258)">
        <g transform="translate(845 257)">
          <path fill="#C51162" d="M12 14L11 14 11 8 12 8z"></path>
          <path fill="#C51162" d="M12 16L11 16 11 15 12 15z"></path>
          <path
            stroke="#C51162"
            d="M2.593 19.5h17.814a1 1 0 00.867-1.498L12.367 2.51a1 1 0 00-1.734 0L1.726 18a1 1 0 00.867 1.499z"
          ></path>
        </g>
      </g>
    </g>
  </svg>
)

export default WarningSign
